import React, { useEffect, useState } from "react";
import masterService from "../../services/masterService";
import Button from "../../commonComponent/button/Button";
import Checkbox from "../../commonComponent/checkboxInput/Checkbox";
import MaterialDropdown from "../../commonComponent/dropdown/MaterialDropdown";
import Input from "../../commonComponent/input/Input";
import MaterialContainer from "../../commonComponent/materialContainer/MaterialContainer";
import RadioInput from "../../commonComponent/RadioInput/RadioInput";
import { useNavigate } from "react-router-dom";
import LayoutSteps from "../LayoutSteps";
import formsService from "../../services/formsService";
import CheckboxAdvance from "../../commonComponent/CheckboxAdvance/CheckboxAdvance";
import { useDispatch, useSelector } from "react-redux";
import { setStepsData } from "../../../store/yr22/stepSlice";

const defaultQuestions = {
  Q01: {
    question: "Did your work in 2022 ?",
    value: false,
    forms:'T4'
  },
  Q02: {
    question: "Did you receive Government Pension ?",
    value: false,
    forms:'T4'
  },
  Q03: {
    question:
      "Did you receive income from investments, shares, property or claiming reserves ?",
    value: false,
    forms:'T5'
  },
  Q04: {
    question: "Did you sell shares or property ?",
    value: false,
    forms:'T5'
  },
  Q05: {
    question: "Did you receive self employment or rental income ?",
    value: false,
    forms:'T4A'
  },
  Q06: {
    question:
      "Did you withdraw funds from a retirement account, receive pension, or annuity?",
    value: false,
    forms:'T4A'
  },
  Q07: {
    question:
      "Did you have any income outside of Canada (for example: work, investment, pension or any other type of income)?",
    value: false,
    forms:'T4A'
  },
  // Q08: {
  //   question: "Did you receive any other type of income ?",
  //   value: false,
  // },
};

function Qna() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [payload, setPayload] = useState(defaultQuestions);
  const [submitError, setSubmitError] = useState("");
  const dispatch = useDispatch()
  const stepsData =useSelector((state)=>state.step.stepsData)
  const questionKeys = Object.keys(defaultQuestions);

  console.log('payload qna',payload)


  useEffect(() => {
    formsService
      .getDetails({ form: "Qna" })
      .then(async (res) => {
        const savedQna=res?.data?.response?.form_data
              console.log('saved qna ',savedQna)
        if('Q01' in savedQna){
          console.log('payload update to')
          setPayload(savedQna)
        }

        // let savedAns=[]

        // let defaultOptionsClone=defaultQuestions;

        // questionKeys.map((itm)=>{

        //   if(itm in savedQna){
        //    defaultOptionsClone[itm].value=savedQna[itm].value

        //   //  console.log('changesing',defaultOptionsClone[itm].value,savedQna[itm].value)
        //   }
          // console.log('defaultOptionsClone',defaultOptionsClone)
          
          // setPayload(defaultOptionsClone)
          
        // })

        // console.log('qna payload is',res?.data?.response?.form_data)

        // setTaxpayerInfo(res?.data?.response?.form_data);
      })
      .catch((error) => {
        console.log("errors ", error);
      });
  }, []);


  useEffect(() => {
    let formsToDisp=[]
    questionKeys.map((itm)=>{
      if(payload[itm].value===true){
        formsToDisp.push(payload[itm].forms)
      }
      
    })
    console.log('form to disp ',formsToDisp)
    let newStepData={
      ...stepsData,
      forms:formsToDisp
    }
    dispatch(setStepsData(newStepData))
  }, [payload]);




  
  // console.log('quesfd',questionKeys)

  

  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: {
        ...payload[name],
        value: value,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formsService
      .update({
        form: "Qna",
        form_data: {
          ...payload,
        },
      })
      .then((res) => {
        //---alert after successful submission
        // alert(res?.data?.message);
        //---redirect to details page of current submissions

        if(stepsData.forms.length==0){
          navigate("/user/return-YR22/step/confirm-blank");
        }else if(stepsData.forms.includes('T4')){
          navigate("/user/return-YR22/step/F_T4");
        }else if(stepsData.forms.includes('T5')){
          navigate("/user/return-YR22/step/F_T5");
        }else{

        }
        
      })
      .catch((error) => {
        console.log("error", error);
        setErrors(error?.response?.data?.errors);
        setSubmitError(error?.response?.data?.message);
      });
  };

  return (
    <LayoutSteps
      title="Q&A"
      actionBack={{ action: () => navigate("/user/return-YR22/step/status") }}
      actionNext={{ action: handleSubmit }}
    >
      <div className="container" style={{ minWidth: 1000 }}>
        <div
          className="form-div flex flex-column gap-1"
          style={{ minWidth: "800px" }}
        >
          {questionKeys?.map((itm) => {
            return (
              <CheckboxAdvance
                title={payload[itm].question}
                value={payload[itm].value}
                name={itm}
                handleChange={(value) => {
                  handleChange(itm, value);
                }}
              />
            );

            // return <Checkbox lable={payload[itm].question} value={payload[itm].value} name={itm} handleChange={(name, value) => { handleChange(name, value)}}/>
          })}

          <div className="form-btn-group flex-centered gap-1">
            <div className="form-group-div flex flex-centered error-div">
              {submitError}
            </div>
            {/* <Button varient='contained' name='saveAdd'  title='Save & Add New'/> */}
          </div>
        </div>
      </div>
    </LayoutSteps>
  );
}

export default Qna;
