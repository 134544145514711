import { NavLink, Outlet } from "react-router-dom";

const Tnavs=()=>{
    return <ul style={{width:'100%',background:'lightgrey',padding:'8px'}}>
        <li><NavLink to='federal-tax'>Federal Tax</NavLink></li>
    </ul>
}

const TestingLayout=()=>{
    return <div style={{width:'100%'}}>
    <div><Tnavs/></div>
    <div style={{padding:'15px',background:'white'}}>
    <Outlet/>
    </div>
    </div>
}

export default TestingLayout;