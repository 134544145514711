import { getRequest } from '../../services/axios/';

/**
 * Api call
 */
class masterService {

    getForms = async (urlParam={},payload) =>{
       return await getRequest('user/return-YR22/masters/forms',urlParam);
        }

    getProvinces = async (urlParam={},payload) =>{
        return await getRequest('user/return-YR22/masters/provinces',urlParam);
            }

    getEmployeeCodes = async (urlParam={},payload) =>{
        return await getRequest('user/return-YR22/masters/employment-codes',urlParam);
            }

    getMaritalStatus = async (urlParam={},payload) =>{
        return await getRequest('user/return-YR22/masters/marital-status',urlParam);
            }
    getResidency = async (urlParam={},payload) =>{
        return await getRequest('user/return-YR22/masters/residency-status',urlParam);
            }
    getCountry = async (urlParam={},payload) =>{
        return await getRequest('user/return-YR22/masters/countries',urlParam);
            }

}

const instance = new masterService();

export default instance;