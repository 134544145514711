import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import formsService from '../../../services/formsService'
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate,useParams } from "react-router-dom";



const F_T5003_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();


        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const {action}=useParams();
        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_T5003'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])
        const auth=useSelector((state)=>state.auth);
        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            console.log('payload is',payload)
            formsService.update({form: 'F_T5003', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                alert(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T5003/view')
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
        }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group flex flex-column '>
                            <HeadingA title="T5003" subtitle='Statement of Tax Shelter Information' />
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                        <Input error={errors?.box_01} box="01" lable="Tax shelter identification number" type='text' name='box_01' value={payload?.box_01} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
                                    <Input error={errors?.box_02} box="02" lable="Investor's identification number" type='text' name='box_02' value={payload?.box_02} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_03} box="03" lable="Number of units acquired" type='text' name='box_03' value={payload?.box_03} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_04} box="04" lable="Cost per unit" type='text' name='box_04' value={payload?.box_04} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_05} box="05" lable="Total cost of units" type='text' name='box_05' value={payload?.box_05} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_06} box="06" lable="Limited-recourse amounts" type='text' name='box_06' value={payload?.box_06} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_07} box="07" lable="At-risk adjustment" type='text' name='box_07' value={payload?.box_07} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_08} box="08" lable="Other indirect reductions" type='text' name='box_08' value={payload?.box_08} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div '>
                                    
                                    <Input error={errors?.box_09} box="09" lable="Capital cost allowance" type='text' name='box_09' value={payload?.box_09} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_10} box="10" lable="Carrying charges" type='text' name='box_10' value={payload?.box_10} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_11} box="11" lable="Loss or deduction" type='text' name='box_11' value={payload?.box_11} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_12} box="12" lable="Tax shelter's principal business activity" type='text' name='box_12' value={payload?.box_12} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_13} box="13" lable="Eligible amount of gift" type='text' name='box_13' value={payload?.box_13} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_14} box="14" lable="Eligible amount of monetary contribution" type='text' name='box_14' value={payload?.box_14} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T5003_AddNew;