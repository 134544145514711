import { toast } from "react-toast"
const Alert = {
  name: 'amrik singh',
  success: function (msg) {
    alert(msg)
    toast.success(msg)
  },
  error: function (msg) {
    alert(msg)
    toast.error(msg)
  }
}

export default Alert