import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import formsService from '../../../services/formsService'
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";



const F_T4A_P_AddNew=()=>{
    
    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();


        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);

        const {id}=useParams();

        useEffect(() => {

            if(id!==undefined){
                formsService.getDetails({form: 'F_T4A_P'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])


        const handleChange=(name,value)=>{
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        formsService.update({form: 'F_T4A_P', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            alert(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_T4A_P/view/')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
    }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="T4A(P)" subtitle="Statement of Canada Pension Plan Benefits" />
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_20} box="20" lable="Taxable CPP benefits" type='text' name='box_20' value={payload?.box_20} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_21} box="21" lable="Number of months-disability" type='text' name='box_21' value={payload?.box_21} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_23} box="23" lable="Number of months-retirement" type='text' name='box_23' value={payload?.box_23} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_22} box="22" lable="Income tax deducted" type='text' name='box_22' value={payload?.box_22} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_13} box="13" lable="Onset or effective date" type='text' name='box_13'  value={payload?.box_13} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_14} box="14" lable="Retirement benefit" type='text' name='box_14' value={payload?.box_14} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>

                                    <Input error={errors?.box_15} box="15" lable="Survivor benefit" type='text' name='box_15' value={payload?.box_15} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_16} box="16" lable="Disability benefit" type='text' name='box_16' value={payload?.box_16} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_17} box="17" lable="Child benefit" type='text' name='box_17' value={payload?.box_17} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                            </div>
                            
                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>
            
                                    <Input error={errors?.box_18} box="18" lable="Death benefit" type='text' name='box_18' value={payload?.box_18} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_19} box="19" lable="Post-retirement benefit" type='text' name='box_19' value={payload?.box_19} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                            </div>

                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>

                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T4A_P_AddNew;