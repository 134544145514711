import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import Dropdown from "../../../commonComponent/dropdown/Dropdown";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.div_1 ? 
                <div className="w-20 flex-centered">
                    <p>{props.div_1}</p>
                </div>
                :""
                }
                {props.div_2 ? 
                <div style={{minWidth: '5%'}} className=" flex-centered">
                    <p>{props.div_2}</p>
                </div>
                :""
                }
                {props.div_3 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3}</p>
                </div>
                :""
                }
                {props.input1 == 'yes' ? 
                <div  className="flex"  style={inputStyle}>
                    <Input input={props.input1} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.div_3_a ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3_a}</p>
                </div>
                :""
                }
                
                <div  className="flex"  style={inputStyle}>
                    {props.input2 == 'yes' ?
                        <Input input={props.input2} type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        :""
                    }
                </div>
                
                {props.div_4 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_4}</p>
                </div>
                :""
                }
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}
const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        justifyContent: window.innerWidth < 500 ? 'flex-start' : 'flex-end',

    }

    const boxStyle = {
        background: 'black',
        color: 'white',
        padding: '0.2em 0.5em',
    }

    const inputResponsive = {
        minWidth: '20%'
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.box ? <p style={boxStyle}>{props.box}</p> : ''}
                {props?.name &&
                <div style={checkBoxStyle} className="flex">
                
                    <Checkbox name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                
                </div>}
                
                {props.subTitle2 ? <p style={subTitleStyle}>{props.subTitle2}</p> : ''}
                {props.box2 ? <p style={boxStyle}>{props.box2}</p> : ''}
                {props.input == 'yes' ?
                    <div style={inputResponsive}>
                        <Input input={props.input} type={props.type} name={props.Iname} value={props?.Ivalue} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        
                        </div>
                        :""
                        
                    }
            </div>
            <hr />
        </div>
    )
    
}
const Form_UI_c=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        maxWidth: '20%',
    }

    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.input == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <Input input={props.input} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.input1 == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <p>{props.txt1}</p>
                    <Input input={props.input1} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.input2 == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <p>{props.txt2}</p>
                    <Input input={props.input2} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                
                <div style={checkBoxStyle} className="flex-centered flex-column gap-2">
                    <p>{props.txt3}</p>
                    <div className="flex gap-2">
                        <Checkbox name={props.name} value={props?.value_1} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        <Checkbox name={props.name} value={props?.value_2} hint='' lable={props.lable_2} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const F_Sch_5_AddNew=()=>{

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_RRSP'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)


    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_RRSP_WS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_RRSP_WS/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_RRSP_WS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_RRSP_WS/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="Schedule 5" subtitle="Amounts for Spouse or Common-Law Partner and Dependants" />
                            <p>Complete this schedule and attach it to your return to claim an amount on line 30300, 30400, 30425 or 30450 of your return. For more information, see lines 30300 and 30400 in the Federal Income Tax and Benefit Guide and lines 30425 and 30450 on this form.</p>
                            
                            <br />
                            
                            <div className='form-group-div flex-column'>
                                <MaterialContainer heading="Line 30300 - Spouse or common-law partner amount">
                                
                                    <p>Did your marital status change to other than married or common-law in 2022?</p>
                                    <Form_UI_b name="SCLPA_001" Iname="SCLPA_001_a" subTitle="If yes, tick this box" box="55220" subTitle2="and enter the date of the change" box2=">" input="yes" type="date" value={payload?.SCLPA_001} Ivalue={payload?.SCLPA_001_a} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="SCLPA_002" subTitle="Your basic personal amount (line 30000 of your return" input2="yes" value={payload?.SCLPA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b Iname="SCLPA_003" subTitle="If you are entitled to the Canada caregiver amount for your spouse or common-law partner, enter $2,350 (see the 'Canada caregiver amount' in Step 5 in the guide and line 30425 below)." box2="51090" input="yes" value={payload?.SCLPA_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="SCLPA_004" subTitle="Add line 1 and 2" input2="yes" value={payload?.SCLPA_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="SCLPA_005" subTitle="Spouse's or common-law partner's net income from page 1 of your return" input2="yes" value={payload?.SCLPA_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="SCLPA_006" subTitle="Line 3 minus line 4 (if negative, enter '0')" input2="yes" value={payload?.SCLPA_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="SCLPA_007" subTitle="Enter this amount on line 30300 of your Schedule 1" input2="yes" value={payload?.SCLPA_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>


                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 30400 - Amount for an eligible dependant">

                                    <p>Did your marital status change to married or common-law in 2022 ?</p>
                                    <Form_UI_b name="AED_001" Iname="AED_001_a" subTitle="If yes, tick this box" box="55290" subTitle2="and enter the date of the change" box2=">" input="yes" type="date" value={payload?.AED_002} Ivalue={payload?.AED_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p><b>Provide the requested information and complete the following calculation for this department.</b></p>
                                    <Form_UI_c name="AED_002" name2="AED_002_a" subTitle="Address " 
                                        input1="yes" 
                                        input2="yes"
                                        input3="yes"
                                        txt1="Year of Birth"
                                        txt2="Relationship to you"
                                        txt3="is this dependant physically or mentally infirm?"
                                        lable_1="Yes"
                                        lable_2="No"
                                        value={payload?.AED_002} Ivalue={payload?.AED_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="AED_003" subTitle="Your basic personal amount (line 30000 of your return" input2="yes" value={payload?.SCLPA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="AED_004" subTitle="If you are entitled to the Canada caregiver amount for your dependant (other than your infirm child under 18 years of age), enter $2350 (see page 44 in the guide, read the note below, and see line 30425 above)" input2="yes" value={payload?.SCLPA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="AED_005" subTitle="Add lines 1 and 2" input2="yes" value={payload?.SCLPA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="AED_006" subTitle="Dependant's net income (line 23600 of his or her return)" input2="yes" value={payload?.SCLPA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="AED_007" subTitle="Line 3 minus line 4 (if negative, enter '0') Enter this amount on line 30400 of your Schedule 1" input2="yes" value={payload?.SCLPA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <p><b>Note </b> If the dependant is your or your spouse's or common-law partner's infirm child under 18 years of age, you must claim the Canada caregiver amount on line 30500, and not on line 51100</p>
                                    

                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 30425 - Canada caregiver amount for spouse or common-law partner, or your eligible dependant age 18 or older">
                                    
                                    <p>Complete this calculation only if you entered $2350 on line 51090 or line 51100 of this schedule for a person whose net income is between $7525 and $25195</p>
                                    <Form_UI_a Iname="CCAS_001" subTitle="Base Amount" input2="yes" value={payload?.CCAS_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="CCAS_002" subTitle="Net income of this person (line 23600 of his or her return)" input2="yes" value={payload?.CCAS_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="CCAS_003" subTitle="Line 1 minus line 2 (if negative, enter '0')" input2="yes" value={payload?.CCAS_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="CCAS_004" subTitle="If you claimed this person on line 30300 or 30400 of Schedule 1, enter the amount you claimed" input2="yes" value={payload?.CCAS_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Allowable amount for this person: line 3 minus line 4 (if negative, enter '0')</p>
                                    <Form_UI_a Iname="CCAS_005" subTitle="Enter this amount on line 30425 of your Schedule 1." input2="yes" value={payload?.CCAS_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>


                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 30450 - Canada caregiver amount for other infirm dependants age 18 or older">
                                    
                                <p>(attach a separate sheet of paper if you need mor space)</p>

                                <p><b>Provide the requested information and complete the following calculation for each dependant.</b></p>
                                
                                <Form_UI_a Iname="AED_001" subTitle="First and last name" input2="yes" value={payload?.AED_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                <Form_UI_c name="AED_002" name2="AED_002_a" subTitle="Address " 
                                        input="yes"
                                        input1="yes" 
                                        input2="yes"
                                        input3="yes"
                                        txt1="Year of Birth"
                                        txt2="Relationship to you"
                                        lable_1="Yes"
                                        lable_2="No"
                                        
                                        value={payload?.AED_002} Ivalue={payload?.AED_002_a} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                <Form_UI_a Iname="AED_003" subTitle="Base amount" input2="yes" value={payload?.AED_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                <Form_UI_a Iname="AED_004" subTitle="Infirm dependant's net income (line 23600 of his or her return)" input2="yes" value={payload?.AED_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                <Form_UI_a Iname="AED_005" subTitle="Allowable amount for this dependant line 1 minus line 2 (if negative, enter '0') (maximum $7525)" input2="yes" value={payload?.AED_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                <p>Enter, on line 30450 of your Shchedule 1, the total amount you are claiming for all dependant</p>
                                <Form_UI_a Iname="AED_006" subTitle="Enter the total number of dependants for whom you are claiming an amount at this line" box2="51120" input2="yes" value={payload?.AED_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    
                                </MaterialContainer>
                                <br />
                                <br />
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_Sch_5_AddNew;