import { deleteRequest, getRequest, postRequest, putRequest } from '../../services/axios/';

/**
 * Api call
 */
class testingService {

    testFederalTax = async (payload) => {
        return await postRequest('user/return-YR22/testing/federal-tax', payload);
    }

}

const instance = new testingService();

export default instance;