import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLogin } from "../../store/auth/authSlice";
import { NavLink, useNavigate } from "react-router-dom";
import Input from "../../bt-components/Input";
import Button from "../../bt-components/Buttons";
const Login = () => {
    const [postData, setPostData] = useState({})
    const navigate = useNavigate();
    const [submitError, setSubmitError] = useState('');


    const dispatch = useDispatch()
    // const { loginData, loadingStatus, token } = useSelector((state) => state.login)

    async function login() {
        dispatch(getLogin(postData,navigate)).then((resp)=>{
            if (resp?.status === 200) {
                navigate('/user/return-YR22/start')
            } else {
                setSubmitError(resp?.response?.data?.message)
                alert(resp?.response?.data?.message)
            }
        })
        
    }


    const onChangeHandler = (name,value) => {
        setPostData({ ...postData, [name]: value })
    }
    return <div className="login">
        <h3>Welcome back</h3>
        <h4>Welcome back! Please enter your details</h4>

        <form>
            <div className="f-item">
                <label>Email*</label>
                
                <Input
                    id="outlined-basic"
                    placeholder="example@mail.com"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    name="email"
                    handleChange={onChangeHandler}
                    disableAnimation={true}
                    InputLabelProps={{ shrink: false }}
                />
            </div>
            <div className="f-item">
                <label>Password*</label>
                <Input
                    id="outlined-basic"
                    placeholder=""
                    name="password"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    type="password"
                    handleChange={onChangeHandler}
                    disableAnimation={true}
                    InputLabelProps={{ shrink: false }}
                />
            </div>
            <div className="check-n-link">
            <div className="check-with-label">
            {/* <input type="checkbox"/><label> Remember me for 30 days</label> */}
            </div>
                <div><NavLink className='text-link color-dark font-bold' to='forget-password'>Forget Password</NavLink></div>
            </div>
            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
            <div className="f-item actions">
                
                <Button 
                title='Sign In'
                fullWidth 
                className="bg-dark" 
                variant="contained" 
                onClick={login}/>
                 </div>
            <div style={{textAlign:'center'}}>Don't have an account ? <NavLink className='text-link color-dark font-bold' to='sign-up'>Sign Up</NavLink></div>
        </form>

        {/* <div><input type='text' onChange={(e) => setUsername(e.target.value)} placeholder='username' /></div>
        <div><input type='password' onChange={(e) => setPassword(e.target.value)} placeholder='password' /></div>
        <div><button sx={{ color: 'red' }} onClick={login}>Login</button></div> */}
    </div>

}
export default Login;