import React from 'react'
import './headerAfLog.css'
import ProfilePic from '../../../../assets/images/user.png'
import Button from '../../../../bt-components/Buttons'
import { useDispatch } from 'react-redux'
import { getLogout } from '../../../../store/auth/authSlice'
import { useNavigate } from 'react-router-dom'

function HeaderAfLog() {
  const  dispatch =useDispatch();
  const navigate=useNavigate()
  return (
    <>
        <div className='header'>
            <div className='inner-div flex-between'>
                <div className='logo'>
                    <h2>TallyTax</h2>
                </div>
                
                <div className='profile'>
                    <div className='profile-img-div'>
                        <img src={ProfilePic} alt='ME'/>
                    </div>
                    <Button onClick={()=>dispatch(getLogout(navigate))} size="small" title='Logout'/>
                </div>
                
            </div>
        </div>
    </>
  )
}

export default HeaderAfLog