import React from 'react'

function About3() {
  return (
    <div className='about3Section flex flex-centered w-100 gap-2'
    style={{
        padding: '5em 0em'
    }}
    >
        <div className='w-80 flex gap-3'>
            <div className='flex flex-column gap-1 w-100'>
                <h2 className='h2-txt'>About Us</h2>
                <p>Tally Tax is  100% Canadian  owned and operated company . We consist the team of tax preparer & developer, who are working to develop tax software , which will make tax filing process easy and error free.  Over the last few years technology has changed a lot. Also after COVID-19 pandemic , people habits of working or doing work has changed. Lot of people are doing purchase online , even people are looking to file their taxes online. </p>
            </div>

            <div className='w-100 flex flex-column gap-1'>
                <h2 className='h2-txt'>Our Goal</h2>
                <p>Tally Tax’s  Goal is to help Canadians with preparation of tax filing by offering user friendly software, so that they can file their taxes “Stress Free”</p>
            </div>
        </div>
        
    </div>
  )
}

export default About3