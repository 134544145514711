import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import formsService from '../../../services/formsService'
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";


const F_T4RIF_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {id}=useParams();

        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);
        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        useEffect(() => {
            if(id!==undefined){
                formsService.getDetails({form: 'F_T4RIF', id: id})
            .then( async(res) => {
                console.log('rrees',res)
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
        }, [])
        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            if(id===undefined){
                formsService.addNew({form: 'F_T4RIF', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4RIF/view/'+res?.data?.response?.new_id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }else{
                formsService.update({form: 'F_T4RIF', form_data: payload},id)
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4RIF/view/'+id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }
            
        }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="T4RIF" subtitle='Statement of Income from a Registered Retirement Income Fund' />
                            
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_16} box="16" value={payload?.box_16} lable="Taxable amounts" type='text' name='box_16' hint='' handleChange={(name,value) => {handleChange(name,value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_35} box="35" value={payload?.box_35 } lable="Transfers on breakdown of marriage or common-law part" type='text' name='box_35' hint="" handleChange={(name,value) => {handleChange(name,value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_37} box="37" value={payload?.box_37} lable="Advanced Life Deferred Annuity purchase" type='text' name='box_37' hint="" handleChange={(name,value) => {handleChange(name,value)}}/>
                                </div>
                                
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_22} box="22" value={payload?.box_22} lable="Other income or deductions" type='text' name='box_22' hint='' handleChange={(name,value) => {handleChange(name,value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_24} box="24" value={payload?.box_24}  lable="Excess amount" type='text' name='box_24' hint='' handleChange={(name,value) => {handleChange(name,value)}}/>
                                </div>
                                
                                <div className='input-div'>
            
                                    <Input error={errors?.box_28} box="28" value={payload?.box_28} lable="Income tax deducted" type='text' name='box_28' hint='' handleChange={(name,value) => {handleChange(name,value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                            <div className='F_T4RIF input-div flex-centered'>
                                    <HeadingA title='' subtitle="Amounts deemed received by the annuitant" />
                                    {/* <br /> */}
                                    <div className='flex gap-1 w-100'>
                                        <div className="flex flex-column w-100">
            
                                            <Input error={errors?.box_18} box="18" value={payload?.box_18} lable="Deceased" type='text' name='box_18' hint='' handleChange={(name,value) => {handleChange(name,value)}}/>
                                        </div>
                                        <div className="flex flex-column w-100">
            
                                            <Input error={errors?.box_20} box="20" value={payload?.box_20} lable="Deregistration" type='text' name='box_20' hint='' handleChange={(name,value) => {handleChange(name,value)}}/>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T4RIF_AddNew;