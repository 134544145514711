import React, { useEffect, useState } from 'react'
import masterService from '../../../services/masterService'
import Button from '../../../commonComponent/button/Button';
import Input from '../../../commonComponent/input/Input';
import './style.css';
import { AllFormsList } from '../AllFormsList';
import { NavLink } from 'react-router-dom';
import Search from '../../../commonComponent/input/Search';


function FormsListBlank() {

  const [formsList,setFormsList]=useState([]);
  
  const [formsListFilterd,setFormsListFiltered]=useState([]);

  

  useEffect(() => {
    setFormsListFiltered(AllFormsList)

  }, [])
    const handleSearch=(search)=>{

        if(search!==''){
          setFormsListFiltered(AllFormsList.filter((item)=>{ return (item.name.toLowerCase().includes(search.toLowerCase()) || item.descriptive_name.toLowerCase().includes(search.toLowerCase())) }))
        }else{
          setFormsListFiltered(AllFormsList)

        }
        
    }

  return (
    <>
      <div className='form-section flex flex-column gap-1'>
        <div className='form-header flex gap-1'>
          {/* <input type='text' /> */}
          <div className='form-search'>
            <Search type='text' hint='Seach Forms' name='search' handleChange={(search)=>handleSearch(search)}/>
          </div>
          
          {/* <Button title='Select New Form'/> */}
        </div>
        
        <ul className='form-cards flex flex-centered gap-1'>
            {formsListFilterd.map((form)=> <li className='form-card-div flex flex-column' key={form.code}>
                <NavLink to={form.to} className='flex flex-column flex-centered '>
                  <div className='heading flex flex-centered'>
                    <h2>{form.name}</h2>
                  </div>
                  <div className='discription flex flex-centered'>
                    <p>{form.descriptive_name}</p>
                  </div>
                </NavLink>
              </li>)}
        </ul>
      </div>
      
    </>
  )
}

export default FormsListBlank