import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import formsService from '../../../services/formsService'
import DumyFormView from "../../DumyFormView";

const View=()=>{
    const params=useParams();
    const [details,setDetails]= useState({})
    useEffect(() => {
    
        formsService.getDetails({form: 'F_T4A_RCA'})
        .then( async(res) => {
            setDetails(res?.data?.response?.form_data);
        })
        .catch((error) => {
            console.log('errors ',error)
        });
    }, [])

    console.log('details ara',details)
    
    

    return <DumyFormView details={details}/>
}
export default View