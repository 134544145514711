import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLogin } from "../../store/auth/authSlice";
import { NavLink, useNavigate } from "react-router-dom";
import Input from "../../bt-components/Input";
import Button from "../../bt-components/Buttons";
const SignUp = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();


    const dispatch = useDispatch()
    // const { loginData, loadingStatus, token } = useSelector((state) => state.login)

    // async function login() {
    //     await dispatch(doLogin({ username: username, password: password }))
    //     navigate('/dashboard')
    // }
    return <div className="login">
        <h3>Create New Account</h3>
        <h4>Register! Please enter your details</h4>

        <form>
            <div className="f-item">
                <label>Email*</label>
                <Input
                    id="outlined-basic"
                    placeholder="example@mail.com"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    disableAnimation={true}
                    InputLabelProps={{ shrink: false }}
                />
            </div>
            <div className="f-item">
                <label>Password*</label>
                <Input
                    id="outlined-basic"
                    placeholder=""
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    disableAnimation={true}
                    InputLabelProps={{ shrink: false }}
                />
            </div>
            <div className="f-item">
                <label>Confirm Password*</label>
                <Input
                    id="outlined-basic"
                    placeholder=""
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    disableAnimation={true}
                    InputLabelProps={{ shrink: false }}
                />
            </div>

            <div className="f-item actions">
                <Button fullWidth className="bg-dark" variant="contained" title="Sign Up"/>
                </div>
            <div style={{textAlign:'center'}}>Already have an account ? <NavLink className='text-link color-dark font-bold' to='/login'>Sign In</NavLink></div>
        </form>

        {/* <div><input type='text' onChange={(e) => setUsername(e.target.value)} placeholder='username' /></div>
        <div><input type='password' onChange={(e) => setPassword(e.target.value)} placeholder='password' /></div>
        <div><button sx={{ color: 'red' }} onClick={login}>Login</button></div> */}
    </div>

}
export default SignUp;