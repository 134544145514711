import React from 'react'
import './input.css'

function Search(props) {

  return (
    <>
      <div className='input-div'>
        {/* <label className='input-lable'>{props.lable}</label> */}
        <div className='flex'>
          
          <input 
            className="input search-input" 
            type={props.type} 
            placeholder={props.hint} 
            name={props.name} 
            value={props.value} 
            onChange={(e) => props.handleChange(e?.target?.value)}/>            
        </div>

        <span style={{color:'red'}}>{props.error}</span>

      </div>
    </>
  )
}

Search.defaultProps = {
  type:"text",
  required: false,
}

export default Search