import { useState } from "react"
import Input from "../../../bt-components/Input"
import Button from "../../../bt-components/Buttons";
import testingService from "../../../YR22/services/testing"
const TestFederalTax=()=>{
    const [amount,setAmount]=useState(0);
    const [details,setDetails]=useState({});
    const [error,setError]=useState('')
    console.log('amount',amount)
    const handleSubmit=()=>{
        setError('')
        testingService.testFederalTax({taxble_amount:amount})
        .then((res)=>{
            setDetails(res?.data?.response)
            console.log('resp',res)
        })
        .catch((err)=>{
            setError(err?.response?.data?.message)
            console.log('err',err)
        })
    }

    return <>
            <div style={{width:'300px'}}>
                <Input error={error} hint="Enter taxble income" handleChange={(name,value)=>setAmount(value)} />
                <br/>
                <Button onClick={handleSubmit} title="Check"/>
                </div>
                <br/>
                <table className="table">
                    <tr>
                        <td>Column</td><td>{details?.column}</td>
                    </tr>
                    <tr>
                        <td>line 67</td><td>{details?.line_67}</td>
                    </tr>
                    <tr>
                        <td>line 68</td><td>{details?.line_68}</td>
                    </tr>
                    <tr>
                        <td>line 69</td><td>{details?.line_69}</td>
                    </tr>
                    <tr>
                        <td>line 70</td><td>{details?.line_70}</td>
                    </tr>
                    <tr>
                        <td>line 71</td><td>{details?.line_71}</td>
                    </tr>
                    <tr>
                        <td bold>line 72</td><td>{details?.line_72}</td>
                    </tr>
                    <tr>
                        <td>line 73</td><td>{details?.line_73}</td>
                    </tr>

                </table>
                
    </>
}

export default TestFederalTax