import React from 'react'
import './materialContainer.css'

function MaterialContainer(props) {
  return (
    <div className='material-container-main'>
        <div className='material-container-div'>
            <h3 className='sub-Heading'>{props.heading}</h3>
            <div className='material-container-body flex flex-column gap-1'>
                {props.children}
            </div>
        </div>
    </div>
  )
}

export default MaterialContainer