import React, { useState } from 'react'
import './input.css'

function InputAmount(props) {

  const [isFocused, setIsFocused] = useState(false);
  const [inputData, setInputData] = useState();


  function checkOnFocus(number) {
    if(isFocused){
      toFormatter(number)
    }
  }

  function toFormatter(number) {
    // const formatter = new Intl.NumberFormat("sv-SE", {
      const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      // currency: "SEK"
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(number);
  }
  
  return (
    <>
      <div className='input-div'>
        <label className='input-lable'>{props.lable}</label>
        <div className='input-container flex'>
          <span className='input-box-span flex-centered' 
            style={
              {
                background : isFocused ? 'var(--sec-color)': 'var(--border)',
                color: isFocused ? 'var(--txt-color-light)': 'var(--txt-color-dark)',
                display: !props.box ? 'none' :'flex'
              }
            }>{props.box}</span>
            {isFocused ?
              <input 
                style={{
                  textAlign: 'right'
                }}
                onBlur={()=> setIsFocused(false)} 
                className="input " 
                type='number'
                placeholder={props.hint} 
                name={props.name} 
                value={(props.value)}
                disabled={props.disabled}
                onChange={(e) => props.handleChange(props.name, e?.target?.value)}/>            
              :
              <input 
                style={{
                  textAlign: 'right'
                }}
                onFocus={() => setIsFocused(true)} 
                className="input " 
                type=""
                placeholder={props.hint} 
                name={props.name} 
                value={props.value != "" && props.value != null ? toFormatter(props.value): props.value || ''}
                disabled={props.disabled} 
                onChange={(e) => props.handleChange(props.name, e?.target?.value)}/>
            }
          </div>

        <span className='error-txt' style={{color:'red', fontSize: '0.8em' }}>{props.error}</span>
        
      </div>
    </>
  )
}

InputAmount.defaultProps = {
  type:"text",
  required: false,
}

export default InputAmount