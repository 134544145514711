import React, { useEffect, useState } from "react";
import Button from "../../commonComponent/button/Button";
import "./personalInfoLayout.css";
import masterService from "../../services/masterService";
import formsService from "../../services/formsService";
import Alert from "../../commonComponent/Alerts";
import { toast } from "react-toast";
import MaterialInput from "../../commonComponent/input/MaterialInput";
import MaterialDropdown from "../../commonComponent/dropdown/MaterialDropdown";
import MaterialContainer from "../../commonComponent/materialContainer/MaterialContainer";
import { useNavigate, useParams } from "react-router-dom";
import LayoutSteps from "../LayoutSteps";
import { useDispatch, useSelector } from "react-redux";
import { setStepsData } from "../../../store/yr22/stepSlice";

function TaxPayer() {
  const [payload, setPayload] = useState({});
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [provincesList, setProvincesList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitError, setSubmitError] = useState("");
  // const [adFields, setAdFields] = useState([])
  const [defaultOptions, setDefaultOptions] = useState([]);

  const stepsData =useSelector((state)=>state.step.stepsData)
  
  const navigate = useNavigate();
  const dispatch =useDispatch()
  //---id will be there only in case of updation

  useEffect(() => {
    //----Fetch marital status
    masterService
      .getMaritalStatus()
      .then((res) => {
        console.log(res);
        setMaritalStatusList(res.data.response.list);
      })
      .catch((error) => {
        console.log(error.message);
      });

    //----fetch Provinces
    // masterService
    //   .getProvinces()
    //   .then((res) => {
    //     setProvincesList(res.data.response.list);
    //   })
    //   .catch((error) => {
    //     console.log(error.message);
    //   });

    masterService
      .getCountry()
      .then((res) => {
        setCountryList(res.data.response.list);
        // console.log("Mycountries", res.data.response.list)
      })
      .catch((error) => {
        setCountryList(error.message);
      });

      
  }, []);

    useEffect(()=>{
      console.log("changing province",payload?.country)
      if(payload?.country && payload?.country !== ""){
        const thisCountry = countryList.filter((country) => country.id == payload?.country)
        // if(thisCountry.length==1){
        //   setProvincesList(thisCountry[0].provinces || []);
        // }
        
        setProvincesList(thisCountry[0]?.provinces || []);
        
      }else{
        setProvincesList([])
      }
      
    },[payload?.country])

    console.log("check province",provincesList)


  useEffect(() => {
    formsService
      .getAdditionalFields({ form: "PersonalinfoTaxpayer" })
      .then(async (res) => {
        if (res?.data?.response?.list) {
          setDefaultOptions(
            Object.keys(res?.data?.response?.list).map((itm) => {
              return { name: itm };
            })
          );
        }
      })
      .catch((error) => {
        console.log("errors ", error);
      });
  }, []);

  useEffect(() => {
    formsService
      .getDetails({ form: "PersonalinfoTaxpayer" })
      .then(async (res) => {
        setPayload(res?.data?.response?.form_data);
      })
      .catch((error) => {
        console.log("errors ", error);
      });
  }, []);

  

  const handleSubmit = (e) => {
    e.preventDefault();

    formsService
      .update(
        {
          form: "PersonalinfoTaxpayer",
          form_data: {
            ...payload,
            // ...additionalFieldsFinal
          },
        }
      )
      .then((res) => {
        //---alert after successful submission
        // Alert.success(res?.data?.message);
        //---redirect to details page of current submissions
        if(stepsData.maritalStatus==1 || stepsData.maritalStatus==2){
          navigate('/user/return-YR22/step/spouse')
        }else{
          navigate('/user/return-YR22/step/status')
        }
        
      })
      .catch((error) => {
        setErrors(error?.response?.data?.errors);
        setSubmitError(error?.response?.data?.message);
      });
  };

  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
    console.log("ccheck console  ",name, value);
  };


  const handleChangeSin=(value)=>{
    if (value.match(/^([0-9]{0,9})$/)) {
      handleChange('sin', value);
    }else{
      handleChange('sin', payload?.sin);
    }
  }

  return (
    <>
      <LayoutSteps
        title="Taxpayer Details"
        actionBack={{action:()=>navigate("/user/return-YR22/start")}}
        actionNext={{action:handleSubmit}}
      >
        <form>
          <div className="container" style={{ minWidth: "1000px" }}>
            <div className="form-div flex flex-column gap-1">
              <MaterialContainer>
                <div className="inner-group flex-between">
                  <MaterialInput
                    box="SIN"
                    type="text"
                    hint="SIN"
                    name="sin"
                    value={payload?.sin}
                    handleChange={(name, value) => {handleChangeSin(value); }}
                    error={errors?.sin}
                  />
                  <MaterialDropdown
                    lable="Marital Status"
                    visible={
                      payload?.maritalStatus == "0" ||
                      payload?.maritalStatus == null
                        ? true
                        : false
                    }
                    selected={payload?.maritalStatus}
                    options={{
                      list: maritalStatusList,
                      name: "name",
                      value: "id",
                    }}
                    type="text"
                    hint="Marital Status"
                    name="maritalStatus"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                      dispatch(setStepsData({
                        ...stepsData,
                        maritalStatus:value
                      }))
                    }}
                    error={errors?.maritalStatus}
                    value={payload?.maritalStatus}
                  />
                </div>
                <div className="inner-group flex-between">
                  <MaterialInput
                    box="First Name"
                    type="text"
                    hint="First Name"
                    name="fname"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    error={errors?.fname}
                    value={payload?.fname}
                  />
                  <MaterialInput
                    box="Last Name"
                    type="text"
                    hint="Last Name"
                    name="lname"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    error={errors?.lname}
                    value={payload?.lname}
                  />
                  <MaterialInput
                    box="Date of Birth"
                    type="date"
                    hint="Date of Birth"
                    name="dob"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    error={errors?.dob}
                    value={payload?.dob}
                  />
                </div>
              </MaterialContainer>
              {/* form group 2 */}
              <MaterialContainer>
                <div className="inner-group flex-between">
                  <MaterialInput
                    box="Street No."
                    type="text"
                    hint="Street No."
                    name="street_number"
                    value={payload?.street_number}
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    error={errors?.street_number}
                  />
                  <MaterialInput
                    box="Street"
                    type="text"
                    hint="Street"
                    name="street"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.street}
                    error={errors?.street}
                  />
                </div>
                <div className="inner-group flex-between">
                  <MaterialInput
                    box="Apt #"
                    type="text"
                    hint="Apt #"
                    name="apt"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.apt}
                    error={errors?.apt}
                  />
                  <MaterialInput
                    box="Box #"
                    type="text"
                    hint="Box #"
                    name="box"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.box}
                    error={errors?.box}
                  />
                  <MaterialInput
                    box="RR #"
                    type="text"
                    hint="RR #"
                    name="rr"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.rr}
                    error={errors?.rr}
                  />
                </div>
                <div className="inner-group flex-between">
                  <MaterialInput
                    box="City"
                    type="text"
                    hint="City"
                    name="city"
                    value={payload?.city}
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    error={errors?.city}
                  />
                  <MaterialInput
                    box="Postal Code"
                    type="text"
                    hint="Postal Code"
                    name="postalcode"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.postalcode}
                    error={errors?.postalcode}
                  />
                </div>
                <div className="inner-group flex-between">
                  <MaterialDropdown
                    lable="Province"
                    type="text"
                    hint="Province"
                    name="province"
                    visible={
                      payload?.province == "0" || payload?.province == null
                        ? true
                        : false
                    }
                    disabled={provincesList?.length === 0}

                    options={{ list: provincesList, name: "name", value: "id" }}
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.province}
                    error={errors?.province}
                  />
                  <MaterialInput
                    box="Zip Code"
                    type="text"
                    hint="Zip Code"
                    name="zipcode"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.zipcode}
                    error={errors?.zipcode}
                  />
                </div>
                <div className="inner-group flex-between">
                  <MaterialDropdown
                    lable="Country"
                    type="text"
                    
                    hint="Country"
                    name="country"
                    visible={
                      payload?.country == "0" || payload?.country == null
                        ? true
                        : false
                    }
                    options={{ list: countryList, name: "name", value: "id" }}
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.country}
                    error={errors?.country}
                  />
                  <MaterialInput
                    box="Email"
                    type="text"
                    hint="Email"
                    name="email"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.email} 
                    error={errors?.email}
                  />
                </div>
                <div className="inner-group flex-between">
                  <MaterialInput
                    box="Phone"
                    type="telephone"
                    hint="Phone"
                    name="phone"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.phone} 
                    error={errors?.phone}
                  />
                </div>
                <div className="inner-group flex-between">
                  <MaterialInput
                    box="Work Phone"
                    type="text"
                    hint="Work Phone"
                    name="workphone"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.workphone} 
                    error={errors?.workphone}
                  />
                  <MaterialInput
                    box="Ext"
                    type="text"
                    hint="Ext"
                    name="ext"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.ext} 
                    error={errors?.ext}
                  />
                </div>
                <div className="inner-group flex-between">
                  <MaterialInput
                    box="Care Of"
                    type="text"
                    hint="Care Of"
                    name="careof"
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.careof} 
                    error={errors?.careof}
                  />
                </div>
              </MaterialContainer>
              <div className="form-btn-group flex-centered gap-1">
                <div className="form-group-div flex flex-centered error-div">
                  {submitError}
                </div>
                {/* <Button varient='contained' name='saveBack' myClass='btn-sec' title='submit'/> */}
                {/* <Button varient='contained' name='saveAdd'  title='Save & Add New'/> */}
              </div>
            </div>
          </div>
        </form>
      </LayoutSteps>
    </>
  );
}

export default TaxPayer;
