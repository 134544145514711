import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import "../inner-forms.css";
import masterService from "../../../services/masterService";
import formsService from "../../../services/formsService";
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
// import { Toast } from "react-toastify/dist/components";
import Alert from "../../../commonComponent/Alerts";
import { toast } from "react-toast";
import AdditionalField from "../../../commonComponent/AdditionalFields";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import LayoutSteps from "../../LayoutSteps";
import { Trash } from "../../../commonComponent/icons";
import InputAmount from "../../../commonComponent/input/InputAmount";

const F_T4 = () => {
  const [provincesList, setProvincesList] = useState([]);
  const [employeeCodesList, setEmployeeCodesList] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitError, setSubmitError] = useState("");
  const [adFields, setAdFields] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [payload, setPayload] = useState({});
  const [formList, setFormList] = useState([]);
  const [activeForm,setActiveForm]=useState('new')

  const [formListKeys, setFormListKeys] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {

    //----Fetch provinces
    masterService.getProvinces()
        .then((res) => {
            setProvincesList(res.data.response.list);
        })
        .catch((error) => {
            console.log(error.message)
        });


    //---fetch employee codes
    masterService.getEmployeeCodes()
        .then((res) => {
            setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log(error.message)
        });
}, [])

console.log('provinces ',provincesList)
console.log('statssss',employeeCodesList)

  const handleChange = (name, value) => {
    setSubmitError("");
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const additionalFieldsFinal = {}
    adFields.map((itm) => {
        additionalFieldsFinal[itm.name] = itm.value
    })

    if (activeForm === 'new') {
        formsService.addNew({
            form: 'F_T4', form_data: {
                ...payload,
                ...additionalFieldsFinal
            }
        })
            .then((res) => {
                //---alert after successful submission
                // Alert.success(res?.data?.message)
                window.location.reload()
                //---redirect to details page of current submissions
                // navigate('/user/return-YR22/forms/F_T4/view/' + res?.data?.response?.new_id)
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
    } else {
        
        formsService.update({
            form: 'F_T4', form_data: {
                ...payload,
                ...additionalFieldsFinal
            }
        }, activeForm)
            .then((res) => {
                //---alert after successful submission
                toast.success('amri')
                // Alert.success(res?.data?.message)
                window.location.reload()
                //---redirect to details page of current submissions
                // navigate('/user/return-YR22/forms/F_T4/view/' + id)
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
    }

}

  const [filledFormsList, setFilledFormsList] = useState([]);

  useEffect(() => {
    formsService.getFilledForms().then(async (res) => {
      const result = res?.data?.response?.list;
      let detailsKeys = Object.keys(result);
      const list = detailsKeys.map((item) => result[item]);

      const extractForm = list.filter((itm) => itm.code == "F_T4");
      if (extractForm.length == 1 && extractForm[0].list) {
        setFormList(extractForm[0].list);
        setFormListKeys(Object.keys(extractForm[0].list));
      } else {
        setFormList([]);
        setFormListKeys([]);
      }
    });
  }, []);


  const handleDelete = (id) => {
    formsService.deleteForm({ form: 'F_T4', id: id })
      .then(async (res) => {

        if (res?.status == 200) {
          window.location.reload()
        }
      })

      .catch((error) => {
        console.log('error is >', error.response.data.message)
      });
  };

  const handleFormSwitch=(id)=>{
    if(id=='NEW'){
        setPayload({})
        setActiveForm(id)
    }else{
        setPayload(
            formList[id]
        )
        setActiveForm(id)
    }
  }

  function toFormatter(number) {
    // const formatter = new Intl.NumberFormat("sv-SE", {
      const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      // currency: "SEK"
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(number);
  }


  return (
    <LayoutSteps
      title="T4 | Statement of Remuneration Paid"
      actionBack={{ action: () => navigate("/user/return-YR22/step/qna") }}
      actionNext={{ action: () => navigate("/user/return-YR22/step/F_T5") }}
    >
      <ul className="multi-form-tabs-list">
        {formListKeys.map((itm, index) => {
            console.log(activeForm,itm,activeForm==itm)
          return (
            <li key={itm} className={activeForm==itm?'active':''}>
              
              <div className="label" onClick={()=>handleFormSwitch(itm)} style={{marginRight:8}}>{index + 1}</div>
                <div className="icon" onClick={() => handleDelete(itm)}><Trash color="#6f7173" sty={{fontSize:'10px'}} /></div>
                
            </li>
          );
        })}
        <li className={activeForm=='new'?'active':''}>
              <div className="label" onClick={()=>handleFormSwitch('new')} >
                NEW
              </div>
            </li>
      </ul>

        

      <div className="form-container" style={{ minWidth: 1000 }}>
        <div className="form-inputs">
          <div>
            <div className="form-group">
              {/* <HeadingA title='T4' subtitle='Statement of Remuneration Paid' /> */}
              <div className="form-div-name form-group-div flex gap-1">
                <div className="w-50 flex flex-column gap-1">
                  <div className="input-div">
                    <Input
                      value={payload?.employer_name || ""}
                      error={errors?.employer_name}
                      lable="Employer's Name"
                      type="text"
                      hint=""
                      name="employer_name"
                      handleChange={(name, value) => {
                        handleChange(name, value);
                      }}
                    />
                    <div className="flex-centered gap-1">
                      <div className="">
                        <Checkbox name="current_emp" value={payload?.current_emp} />
                      </div>
                      <p className="w-100" style={{}}>
                        check if current employer
                      </p>
                    </div>
                  </div>
                  <div className="input-div">
                    <Dropdown
                      error={errors?.box_10}
                      lable="Province of employment"
                      selected={payload?.box_10}
                      name="box_10"
                      box="10"
                      options={{
                        list: provincesList,
                        value: "code",
                        name: "code",
                      }}
                      handleChange={(name, value) => {
                        handleChange(name, value);
                      }}
                    />
                  </div>
                  <div className="input-div">
                    <Dropdown
                      error={errors?.box_29}
                      lable="Employment code"
                      selected={payload?.box_29}
                      name="box_29"
                      box="29"
                      options={{
                        list: employeeCodesList,
                        value: "id",
                        name: "id",
                      }}
                      handleChange={(name, value) => {
                        handleChange(name, value);
                      }}
                    />
                  </div>
                  <div className="flex flex-between">
                    <div className="flex-centered flex-column gap-1">
                      <p>Exempt</p>
                      <p>
                        <b>28</b>
                      </p>
                    </div>
                    <div className="flex-centered flex-column gap-1">
                      <p>CPP/QPP</p>
                      <div className="">
                        <Checkbox name="cpp_qpp" value={payload?.cpp_qpp} />
                      </div>
                    </div>
                    <div className="flex-centered flex-column gap-1">
                      <p>EI</p>
                      <div className="">
                        <Checkbox name="ei" value={payload?.ei }/>
                      </div>
                    </div>
                    <div className="flex-centered flex-column gap-1">
                      <p>PPIP/QPIP</p>
                      <div className="">
                        <Checkbox name="ppip_qpip" value={payload?.ppip_qpip} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 flex gap-1">
                  <div className="flex flex-column w-100">
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_14}
                        box="14"
                        lable="Employment income"
                        type="text"
                        value={(payload?.box_14)}
                        name="box_14"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_16}
                        lable="Employee's CPP contributions"
                        box="16"
                        type="text"
                        value={(payload?.box_16)}
                        name="box_16"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_17}
                        lable="QPIP Contributions"
                        box="17"
                        type="text"
                        value={payload?.box_17}
                        name="box_17"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_18}
                        box="18"
                        lable="Employee's EI premiums"
                        type="text"
                        value={payload?.box_18}
                        name="box_18"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_20}
                        box="20"
                        lable="RPP contributions"
                        type="text"
                        value={payload?.box_20}
                        name="box_20"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_52}
                        box="52"
                        lable="Pension adjustment"
                        type="text"
                        value={payload?.box_52}
                        name="box_52"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_55}
                        box="55"
                        lable="PPIP premium"
                        type="text"
                        value={payload?.box_55}
                        name="box_55"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-column w-100">
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_22}
                        box="22"
                        lable="Income tax deducted"
                        type="text"
                        value={payload?.box_22}
                        name="box_22"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_24}
                        box="24"
                        lable="EI insurable earnings"
                        type="text"
                        value={payload?.box_24}
                        name="box_24"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_26}
                        box="26"
                        lable="CPP/QPP pensionable earnings"
                        type="text"
                        value={payload?.box_26}
                        name="box_26"
                        hint=""
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_44}
                        box="44"
                        lable="Union dues"
                        type="text"
                        hint=""
                        value={payload?.box_44}
                        name="box_44"
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_46}
                        box="46"
                        lable="Charitable donations"
                        type="text"
                        hint=""
                        value={payload?.box_46}
                        name="box_46"
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                    <div className="input-div">
                      <InputAmount
                        error={errors?.box_56}
                        box="56"
                        lable="PPIP/QPIP insur. earn."
                        type="text"
                        hint=""
                        value={payload?.box_56}
                        name="box_56"
                        handleChange={(name, value) => {
                          handleChange(name, value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group-div flex gap-1"></div>

              <AdditionalField
                defaultOptions={defaultOptions}
                adFields={adFields}
                selectedOptions={[
                  { name: "box_80", value: "80" },
                  { name: "box_81", value: "81" },
                ]}
                setAdFields={setAdFields}
              />

              <div className="form-group-div flex gap-1">{/* </div> */}</div>
              <div className="form-group-div flex flex-centered error-div">
                {submitError}
              </div>
              <div className='form-group-div flex flex-centered btn-div'>
                                <Button onClick={handleSubmit} type='button' title='Add' />
                            </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutSteps>
  );
};

export default F_T4;
