import './checkbox.css'
import React, { useState } from 'react'

function Checkbox(props) {

    const [isChecked, setIsChecked] = useState(false);
    
    const handleClick = () => {
        setIsChecked((prev) => !prev)
    }

        return (
        <>
            <div className='checkbox-div flex gap-1 '>
            
                <div className='checkbox-container flex'>
                    <label htmlFor="" onClick={handleClick} className='checkbox-lable flex gap-1'>
                        <input 
                            className="checkbox-input" 
                            type={props.type} 
                            placeholder={props.hint} 
                            id="checkbox-input"
                            htmlFor="checkbox-input"
                            name={props.name} 
                            disabled={props.disabled} 
                            checked={props?.value}
                            onChange={(e) => props.handleChange(props.name, e?.target?.checked)}/>            
                    
                        {props.lable}
                        
                    </label>
                </div>
            
                <span className='error-txt' style={{color:'red', fontSize: '0.8em' }}>{props.error}</span>
            
            </div>
        </>
    )
}

Checkbox.defaultProps = {
    type: 'checkbox',
}

export default Checkbox