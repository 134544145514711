import SelectWithInput from './../SelectWithInput'
import { useEffect,useState } from 'react';
const AdditionalField = (props) => {

    const [allOptions, setAllOptions] = useState(props.defaultOptions);
 
     const handleChangeAf = (data, index) => {
         const newData = [...props.adFields];
         newData[index] = { name: data?.name, value: data?.value }
         props.setAdFields(newData)
     }
 
 
     const handleRemoveAf = (name) => {
         props.setAdFields((current) =>
             current.filter((itm) => itm.name !== name)
         );
     }
 
     useEffect(() => {
         let selectedKeys = props.adFields.map((itm) => itm.name)
         setAllOptions(props.defaultOptions.map((itm) => {
             if (selectedKeys.includes(itm.name)) {
                 return { ...itm, disabled: true }
             } else {
                 return itm
             }
 
         }))
 
         if (props.adFields.find((itm) => itm.name == '') === undefined) {
             const newData = [...props.adFields];
             newData.push({ name: '', value: '' })
             props.setAdFields(newData)
         }
     }, [props.adFields,props.defaultOptions])
 
     //---check if there is no blank selection left add a new selectionOption
 
 
 
 
     return (
         <>
            <label>{props.label}</label>
             {props.adFields.map((itm, index) => {
                 return <div className='input-div flex flex-wrap' key={itm.name} style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                     <SelectWithInput
                         handleChange={(data) => handleChangeAf(data, index)}
                         value={itm}
                         options={{ list: allOptions, value: 'name', name: 'name' }}
                     />
                     <button type="button" style={{ width: '20px', height: '20px', marginLeft: '3px' }} disabled={(itm.name == '') ? true : false} onClick={() => handleRemoveAf(itm.name)} title="Remove">x</button>
                 </div>
             })}
 
         </>
     )
 }

 AdditionalField.defaultProps={
    label:'Other Information'
 }
 export default AdditionalField;