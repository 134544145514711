import React from 'react'
import Button from '../button/Button'
import './popup.css'


function Popup(props) {
  return(props.trigger) ? (
    <>
        <div className='popup-container flex'>
            <div className='popup-inner-div'>
                <div className='popup-header flex '>
                    <Button varient='text' onClick={() => props.setTrigger(false)} title='&#9587;'/>
                </div>
                <div className='popup-body'>
                    <div >{props.children}</div>
                </div>
                {/* <div className='popup-footer'>
                    <h1>lw;irhgorbguiwerfbn</h1>
                </div> */}
            </div>
        </div>
    </>
  ): "";
}

export default Popup