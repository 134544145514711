import React from 'react'
import './button.css'
function Button(props) {
  let myClass='btn'
  if(props.myClass!==''){
    myClass=myClass+' '+props.myClass;
  }
if(props)

  return (
    <>
        <button onClick={props.onClick} type="button" varient={props.varient} style={props.sty} name={props.name} className={myClass}>{props.title}</button>
    </>
  )
}
Button.defaultProps = {
  varient: "contained",
  title: "Title",
  name: "",
  noHoverEffect:false,
  sty:{},
  myClass:''
}
export default Button