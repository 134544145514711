import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import formsService from '../../../services/formsService'
import HeadingA from "../../components/HeadingA";
import { useNavigate,useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";


const F_T2202_AddNew=()=>{

    const [provincesList,setProvincesList] = useState([]);
    const [employeeCodesList,setEmployeeCodesList] = useState([]);

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {id}=useParams();
    useEffect(() => {
    
        masterService.getProvinces()
            .then( (res) => {
                setProvincesList(res.data.response.list);
            })
            .catch((error) => {
                console.log(error.message)
            });
    }, [])

    useEffect(() => {
    
        masterService.getEmployeeCodes()
            .then( (res) => {
                setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log(error.message)
            });
    }, [])


    const [payload,setPayload]=useState({})
    const dispatch=useDispatch();
    const auth=useSelector((state)=>state.auth);

    useEffect(() => {
        if(id!==undefined){
            formsService.getDetails({form: 'F_T5', id: id})
        .then( async(res) => {
            console.log('rrees',res)
            setPayload(res?.data?.response?.form_data);
        })
        .catch((error) => {
            console.log('errors ',error)
        });
        }
    }, [])
    const handleChange=(name,value)=>{
        console.log('handle change',name,value)
        setSubmitError('')
        setPayload({
            ...payload,
            [name]:value,
        })
        setErrors({
            ...errors,
            [name]:''
        })
    }

    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(id===undefined){
            formsService.addNew({form: 'F_T5', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_T5/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_T5', form_data: payload},id)
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_T5/view/'+id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="T2202" subtitle='Tuition and Enrolment Certificate' />
                            
                            <div className='form-group-div flex-centered gap-1 '>
                                <div className='input-div'>
            
                                    <Dropdown error={errors?.box_11} box="11" lable="School type" options={{list:provincesList,value:'code',name:'code'}} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Dropdown error={errors?.box_12} box="12" lable="Flying school or club" options={{list:provincesList,value:'code',name:'code'}} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_14} box="14" lable="Student number" type='text' name='box_14' hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_15} box="15" lable="Filer Account Number" type='text' name='box_15' hint='' handleChange={(name, value) => { handleChange(name, value)}} />
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_13} box="13" lable="Name of program or course" type='text' name='box_13' hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                            </div>

                            <div className='form-group-table flex flex-column'>
                                <div className="table-div flex flex-column">
                                    <div className='input-div gap-1 table-card' >
                                        <div className="flex gap-1 table-date">
                                            <Input error={errors?.box_19} box="19" lable="From YY/MM" type='month' name='box_19' hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input error={errors?.box_20} box="20" lable="To YY/MM" type='month' name='box_20' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                        </div>
                                        <div className="flex gap-1 w-100 table-input">
                                            <Input error={errors?.box_21} box="21" lable="Number of months part-time" type='text' name='box_21' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input error={errors?.box_22} box="22" lable="Number of months full-time" type='text' name='box_22' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input error={errors?.box_23} box="23" lable="Eligible tuition fees, part-time and full-time" type='text' name='box_23' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                        </div>
                                    </div>
                                    <div className='input-div gap-1 table-card' >
                                        <div className="flex gap-1 table-date">
                                            <Input error={errors?.box_19} box="19" lable="From YY/MM" type='month' name='box_19' hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input error={errors?.box_20} box="20" lable="To YY/MM" type='month' name='box_20' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                        </div>
                                        <div className="flex gap-1 w-100 table-input">
                                            <Input error={errors?.box_21} box="21" lable="Number of months part-time" type='text' name='box_21' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input error={errors?.box_22} box="22" lable="Number of months full-time" type='text' name='box_22' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input error={errors?.box_23} box="23" lable="Eligible tuition fees, part-time and full-time" type='text' name='box_23' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                        </div>
                                    </div> 

                                    <div className='input-div flex gap-1'>
                                        <div className="table-txt flex-centered">
                                        <HeadingA className="w-100 table-txt-ttl" title=" " subtitle='Total' />
                                            {/* <p> <span className=""></span></p> */}
                                        </div>
                                        <div className="flex gap-1 table-input">
                                            <Input error={errors?.box_24} box="24" type='text' name='box_24' hint="Total" handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input error={errors?.box_25} box="25" type='text' name='box_25' hint="Total" handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input error={errors?.box_26} box="26" type='text' name='box_26' hint="Total" handleChange={(name, value) => { handleChange(name, value)}}/>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>

                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>

                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T2202_AddNew;