import React, { useEffect, useState } from 'react'
import Button from '../../commonComponent/button/Button'
import './personalInfoLayout.css'
import MaterialInput from '../../commonComponent/input/MaterialInput'
import masterService from '../../services/masterService'
import Input from '../../commonComponent/input/Input'
import Checkbox from '../../commonComponent/checkboxInput/Checkbox'
import MaterialDropdown from '../../commonComponent/dropdown/MaterialDropdown'
import MaterialContainer from '../../commonComponent/materialContainer/MaterialContainer'
import LayoutSteps from '../LayoutSteps'
import { useNavigate } from 'react-router-dom'
import formsService from "../../services/formsService";
import Alert from "../../commonComponent/Alerts";
function Spouse() {
  const navigate=useNavigate()
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [provincesList, setProvincesList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitError, setSubmitError] = useState("");
  const [payload,setPayload]=useState({})


  useEffect(() => {

    //----Fetch marital status
    masterService.getMaritalStatus()
        .then((res) => {
            setMaritalStatusList(res.data.response.list);
        })
        .catch((error) => {
            console.log(error.message)
        });


    //---fetch Provinces
    // masterService.getProvinces()
    //         .then((res) => {
    //             setProvincesList(res.data.response.list);
    //         })
    //         .catch((error) => {
    //             console.log(error.message)
    //         });

            masterService.getCountry()
            .then((res) => {
              setCountryList(res.data.response.list);
            })
            .catch((error) => {
              setCountryList(error.message);
            });
}, [])

useEffect(()=>{
  console.log("changing province",payload?.country)
  if(payload?.country && payload?.country !== ""){
    const thisCountry = countryList.filter((country) => country.id == payload?.country)
    setProvincesList(thisCountry[0]?.provinces || []);
    
  }else{
    setProvincesList([])
  }
  
},[payload?.country])

console.log("check province",provincesList)


useEffect(() => {
  formsService
    .getDetails({ form: "PersonalinfoSpouse" })
    .then(async (res) => {
      setPayload(res?.data?.response?.form_data);
    })
    .catch((error) => {
      console.log("errors ", error);
    });
}, []);

console.log('payload',payload)


  

  const handleSubmit = (e) => {
    e.preventDefault();

    formsService
      .update(
        {
          form: "PersonalinfoSpouse",
          form_data: {
            ...payload,
            // ...additionalFieldsFinal
          },
        }
      )
      .then((res) => {
        //---alert after successful submission
        // Alert.success(res?.data?.message);
        //---redirect to details page of current submissions
        navigate('/user/return-YR22/step/status')
      })
      .catch((error) => {
        setErrors(error?.response?.data?.errors);
        setSubmitError(error?.response?.data?.message);
      });
  };
  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
    console.log(name, value);
  };

  const handleChangeSin=(value)=>{
    if (value.match(/^([0-9]{0,9})$/)) {
      handleChange('sin', value);
    }else{
      handleChange('sin', payload?.sin);
    }
  }
  return (

    <LayoutSteps
        title="Spouse Details"
        actionBack={{action:()=>navigate("/user/return-YR22/step/tax-payer")}}
        actionNext={{action:handleSubmit}}
      >
    <div className='container' style={{ minWidth: "1000px" }}>
        <div className='form-div flex flex-column gap-1'>
          <MaterialContainer>
            <div className='inner-group flex-between'>
              <MaterialInput 
                box="SIN" 
                type='text' 
                hint='SIN' 
                name='sin' 
                value={payload?.sin} 
                handleChange={(name, value) => {handleChangeSin(value); }}
                error={errors?.sin}
                />

              <MaterialDropdown 
                lable="Marital Status" 
                name='maritalStatus' 
                value={payload?.maritalStatus}
                visible={payload?.maritalStatus == "0" || payload?.maritalStatus == null ? true : false}
                options={{list: maritalStatusList, name:'name',value:'id'}}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.maritalStatus}
                />

            </div>
            <div className='inner-group flex-between'>
              <MaterialInput 
                box="First Name" 
                type='text' 
                hint='First Name' 
                name='fname' 
                value={payload?.fname} 
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.fname}
                />
              
              <MaterialInput 
                box="Last Name" 
                type='text' 
                hint='Last Name' 
                name='lname' 
                value={payload?.lname}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.lname}
                />
            </div>
            <MaterialInput 
              box="Date of Birth" 
              type='date' 
              hint='Date of Birth' 
              name='dob' 
              value={payload?.dob}
              handleChange={(name, value) => { handleChange(name, value)}}
              error={errors?.dob}
              />
            
          </MaterialContainer>

          <MaterialContainer>
            <div className='inner-group flex-between'>
              <Checkbox 
                lable="Check if same address as taxpayer, or enter:" 
                name="same_address_taxpayer"
                value={payload?.same_address_taxpayer} 
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.same_address_taxpayer}
                />
              
              <Checkbox 
                lable="Link spouse return" 
                name="link_spouse_return" 
                value={payload?.link_spouse_return}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.link_spouse_return}
                />
            </div>
            
          </MaterialContainer>
              {/* form group 2 */}
          <MaterialContainer >
            <div className='inner-group flex-between'>
              <MaterialInput 
                box="Street No" 
                type='text' 
                hint='Street No' 
                name='address' 
                value={payload?.address}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.street_no}
                />
              
              <MaterialInput 
                box="Street" 
                type='text' 
                hint='Street' 
                name='street' 
                value={payload?.street}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.street}/>
            </div>
            <div className='inner-group flex-between'>
              <MaterialInput 
                box="Apt #" 
                type='text' 
                hint='Apt #' 
                name='apt' 
                value={payload?.apt}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.apt}
                />

              
              <MaterialInput 
                box="Box #" 
                type='text' 
                hint='Box #' 
                name='box' 
                value={payload?.box}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.box}
                />
              
              <MaterialInput 
                box="RR #" 
                type='text' 
                hint='RR #' 
                name='rr' 
                value={payload?.rr}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.rr}
                />
            </div>
            <div className='inner-group flex-between'>
              <MaterialInput 
                box="City" 
                type='text' 
                hint='City' 
                name='city' 
                value={payload?.city}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.city}
                />
              
              <MaterialInput 
                box="Postal Code" 
                type='text' 
                hint='Postal Code' 
                name='postalcode' 
                value={payload?.postalcode}
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
            <div className='inner-group flex-between'>
            <MaterialDropdown
                    lable="Province"
                    type="text"
                    hint="Province"
                    name="province"
                    visible={
                      payload?.province == "0" || payload?.province == null
                        ? true
                        : false
                    }
                    disabled={provincesList?.length === 0}

                    options={{ list: provincesList, name: "name", value: "id" }}
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.province}
                    error={errors?.province}
                  />

              
              <MaterialInput 
                box="Zip Code" 
                type='text' 
                hint='Zip Code' 
                name='zipcode'
                value={payload?.zipcode} 
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.zipcode}
                />
            </div>
            <div className='inner-group flex-between'>
              <MaterialDropdown
                    lable="Country"
                    type="text"
                    
                    hint="Country"
                    name="country"
                    visible={
                      payload?.country == "0" || payload?.country == null
                        ? true
                        : false
                    }
                    options={{ list: countryList, name: "name", value: "id" }}
                    handleChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    value={payload?.country}
                    error={errors?.country}
                  />
              
              <MaterialInput 
                box="Phone" 
                type='telephone' 
                hint='Phone' 
                name='phone' 
                value={payload?.phone}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.phone}
                />
            </div>
            <div className='inner-group flex-between'>
              <MaterialInput 
                box="Care Of" 
                type='text' 
                hint='Care Of' 
                name='careof' 
                value={payload?.careof}
                handleChange={(name, value) => { handleChange(name, value)}}
                error={errors?.careof}/>
            </div>
          </MaterialContainer>
          <div className='form-btn-group flex-centered gap-1'>
          <div className="form-group-div flex flex-centered error-div">
                  {submitError}
                </div>
            {/* <Button varient='contained' name='saveBack' myClass='btn-sec' title='Save'/> */}
            {/* <Button varient='contained' name='saveAdd'  title='Save & Add New'/> */}
          </div>
        </div>
      </div>
      </LayoutSteps>
  )
}

export default Spouse