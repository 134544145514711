import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import Dropdown from "../../../commonComponent/dropdown/Dropdown";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.div_1 ? 
                <div className="w-20 flex-centered">
                    <p>{props.div_1}</p>
                </div>
                :""
                }
                {props.div_2 ? 
                <div style={{minWidth: '5%'}} className=" flex-centered">
                    <p>{props.div_2}</p>
                </div>
                :""
                }
                <div className="flex " style={inputStyle}>
                    <Input type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}
const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        justifyContent: window.innerWidth < 500 ? 'flex-start' : 'flex-end',

    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div style={checkBoxStyle} className="flex">
                    <Checkbox name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}
const Form_UI_c=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        maxWidth: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div style={checkBoxStyle} className="flex gap-2">
                    <Checkbox name={props.name} value={props?.value_1} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    <Checkbox name={props.name} value={props?.value_2} hint='' lable={props.lable_2} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const Form_UI_d=(props)=> {

    const divResponsive = {
        minWidth : window.innerWidth < 1200 ? '20%': '100%',
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            
            
            <div className="w-100 flex flex-between gap-1">
                <div style={divResponsive} className=" flex flex-centered">
                    <div>
                        <Checkbox name={props.name_1} value={props?.value_1} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                <div style={divResponsive} className=" flex flex-centered">
                    <div>
                        <Checkbox name={props.name_2} value={props?.value_2} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                <div className="w-100 flex flex-centered">
                    <div className="w-100">
                        <Input type='text' name={props.name_3} n_value={props?.value_3} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                <div className="w-100 flex flex-centered">
                    <div className="w-100">
                        <Input type='text' name={props.name_4} r_value={props?.value_4} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                <div style={{minWidth: '5%', maxWidth: '5%'}} className="w-20 flex flex-centered">
                    <div>
                        <Checkbox name={props.name_5} value={props?.value_5} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const F_RRSP_WS_AddNew=()=>{

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_RRSP'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)

    // const  handleSubmit=(e)=>{
    //     e.preventDefault();
        
    //     console.log('payload is',payload)
    //     formsService.addNew({form: 'F_T4A_OAS', form_data: payload})
    //     .then( (res) => {
    //         //---alert after successful submission
    //         alert(res?.data?.message)
    //         //---redirect to details page of current submissions
    //         navigate('/user/return-YR22/forms/F_T4A_OAS/view/'+res?.data?.response?.new_id)
    //         // setEmployeeCodesList(res.data.response.list);
    //     })
    //     .catch((error) => {
    //         console.log('errors ',error?.response?.data)
    //         setErrors(error?.response?.data?.errors)
    //         setSubmitError(error?.response?.data?.message)
    //     });
    // }



    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_RRSP_WS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_RRSP_WS/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_RRSP_WS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_RRSP_WS/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="RRSP Worksheet" subtitle="" />
                            <br />
                            <div className='form-group-div flex-column'>
                                <MaterialContainer heading="RRSP Contributions">
                                    <p>If you turned 72 in 2022, you are not allowed RRSP or PRPP deduction. Your RRSP and PRPP contributions will be ignored in this case. RRSP contributions (Contributions from March 2, 2022 to March 1, 2023). Note: Saskatchewan Pension Plan contributions are considered to be RRSP contributions.</p>
                                    
                                    <div className="w-100 flex flex-centered">
                                        <div style={{minWidth: window.innerWidth < 1200 ? '41%' :'50%', textAlign: 'center'}}>
                                            <p>Contributions you made from</p>
                                            <div className="flex ">
                                                <div className="w-100">
                                                    <p>March 2, 2022 to Dec 31, 2022</p>
                                                </div>
                                                <div className="w-100">
                                                <p>Jan 1, 2023 to March 1, 2023</p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div style={{minWidth: window.innerWidth < 1200 ? '27%' :'22%', textAlign: 'center'}}>
                                            <p>Institution (for RRSP contributions)</p>
                                        </div>
                                        <div style={{minWidth: window.innerWidth < 1200 ? '27%' :'23%', textAlign: 'center'}}>
                                            <p>Amount</p>
                                        </div>
                                        <div style={{minWidth: window.innerWidth < 1200 ? '8%' :'5%', textAlign: 'center'}}>
                                            <p>Spousal</p>
                                        </div>
                                    </div>
                                    <Form_UI_d 
                                        name_1="RRSP_con_A_001" 
                                        name_2="RRSP_con_A_002" 
                                        name_3="RRSP_con_A_003" 
                                        name_4="RRSP_con_A_004" 
                                        name_5="RRSP_con_A_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_d 
                                        name_1="RRSP_con_B_001" 
                                        name_2="RRSP_con_B_002" 
                                        name_3="RRSP_con_B_003" 
                                        name_4="RRSP_con_B_004" 
                                        name_5="RRSP_con_B_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_d 
                                        name_1="RRSP_con_C_001" 
                                        name_2="RRSP_con_C_002" 
                                        name_3="RRSP_con_C_003" 
                                        name_4="RRSP_con_C_004" 
                                        name_5="RRSP_con_C_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_d 
                                        name_1="RRSP_con_D_001" 
                                        name_2="RRSP_con_D_002" 
                                        name_3="RRSP_con_D_003" 
                                        name_4="RRSP_con_D_004" 
                                        name_5="RRSP_con_D_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_d 
                                        name_1="RRSP_con_E_001" 
                                        name_2="RRSP_con_E_002" 
                                        name_3="RRSP_con_E_003" 
                                        name_4="RRSP_con_E_004" 
                                        name_5="RRSP_con_E_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_d 
                                        name_1="RRSP_con_F_001" 
                                        name_2="RRSP_con_F_002" 
                                        name_3="RRSP_con_F_003" 
                                        name_4="RRSP_con_F_004" 
                                        name_5="RRSP_con_F_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_d 
                                        name_1="RRSP_con_G_001" 
                                        name_2="RRSP_con_G_002" 
                                        name_3="RRSP_con_G_003" 
                                        name_4="RRSP_con_G_004" 
                                        name_5="RRSP_con_G_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_d 
                                        name_1="RRSP_con_H_001" 
                                        name_2="RRSP_con_H_002" 
                                        name_3="RRSP_con_H_003" 
                                        name_4="RRSP_con_H_004" 
                                        name_5="RRSP_con_H_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_d 
                                        name_1="RRSP_con_I_001" 
                                        name_2="RRSP_con_I_002" 
                                        name_3="RRSP_con_I_003" 
                                        name_4="RRSP_con_I_004" 
                                        name_5="RRSP_con_I_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_d 
                                        name_1="RRSP_con_J_001" 
                                        name_2="RRSP_con_J_002" 
                                        name_3="RRSP_con_J_003" 
                                        name_4="RRSP_con_J_004" 
                                        name_5="RRSP_con_J_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_d 
                                        name_1="RRSP_con_K_001" 
                                        name_2="RRSP_con_K_002" 
                                        name_3="RRSP_con_K_003" 
                                        name_4="RRSP_con_K_004" 
                                        name_5="RRSP_con_K_005" 
                                        
                                        subTitle="Employment income reported on T4As, T4PS, research grants" 
                                        value_1="checked"
                                        value_2="checked"
                                        value_3={payload?.RRSP_con_A_003}
                                        value_4={payload?.RRSP_con_A_004}
                                        value_5="checked"

                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <div style={{maxWidth: window.innerWidth < 1200 ? '93.5%' :'94.4%'}}>
                                        
                                        <Form_UI_a name="RRSP_con_PRPP_001" subTitle="Current year PRPP contributions" value={payload?.RRSP_con_PRPP_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        
                                        <Form_UI_a name="RRSP_con_total" subTitle="" div_1="Total: " value={payload?.RRSP_con_total} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="RRSP_con_001" subTitle="Unused RRSP contributions available to carry forward to 2022 (line B)" value={payload?.RRSP_con_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="RRSP_con_002" subTitle="Current year contributions to your own plan (Age under 72)" value={payload?.RRSP_con_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="RRSP_con_003" subTitle="Current year contributions to your spouse's plans (spouse's age under 72)" value={payload?.RRSP_con_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="RRSP_con_004" subTitle="Amounts transferred to your RRSP, PRPP, SPP" value={payload?.RRSP_con_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    </div>

                                    
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Deduction">
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Additional Information">
                                    
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Exempt income">
                                    
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_RRSP_WS_AddNew;