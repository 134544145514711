
import { useEffect, useState } from 'react'
import Button from '../../bt-components/Buttons'
import returnService from './../../YR22/services/returnServices'
import formsService from './../../YR22/services/formsService';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const UserHome = () => {

    const [returnStage, setReturnStage] = useState(null);

    const auth = useSelector((state) => state.auth)
    const navigate=useNavigate()
    useEffect(() => {
        // window.location.reload()
        formsService.getFilledForms()
            .then(async (res) => {
                setReturnStage('VIEW')
            })

            .catch((error) => {
                if (error?.response?.data?.error_code == 'RETURN_NOT_EXISTS') {
                    setReturnStage('START')
                }
            });
    })

    
const handleStart=()=>{
    

    returnService.startReturn()
    .then((resp)=>{
        if(resp?.status=='200'){
            navigate('return-YR22')
        }
        
    })
    .catch((err)=>{
        console.log('err at ',err)
    })
    
}

const handleRefresh=()=>{
    window.location.reload()
}

    return <>
        <div style={{ display: 'flex', justifyContent: 'center', 'alignContent': 'center', height: '100vh', alignItems: 'center' }}>
            {(()=>{
                if(returnStage=='VIEW'){
                    return <Button onClick={()=>{navigate('return-YR22')}} title='Go To Return' />
                }else if(returnStage=='START'){
                    return <Button onClick={handleStart} title='START' />
                }else{
                    
                    return <Button onClick={handleRefresh} title='Refresh' />
                }
            })()}
        </div>
    </>
}

export default UserHome