import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import HeadingA from "../../components/HeadingA";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../commonComponent/popup/Popup";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import AdditionalField from "../../../commonComponent/AdditionalFields";



const F_T3_AddNew = () => {

    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState('');
    const navigate = useNavigate();
    const [defaultOptions, setDefaultOptions] = useState([])
    const [adFields, setAdFields] = useState([])

    const [payload, setPayload] = useState({})
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const { id } = useParams();


    useEffect(() => {

        formsService.getAdditionalFields({ form: 'F_T3' })
            .then(async (res) => {
                if (res?.data?.response?.list) {
                    setDefaultOptions(Object.keys(res?.data?.response?.list).map((itm) => { return { name: itm } }));
                }
            })
            .catch((error) => {
                console.log('errors ', error)
            });
    }, [])


    useEffect(() => {
        if (id !== undefined) {
            formsService.getDetails({ form: 'F_T3', id: id })
                .then(async (res) => {
                    let savedData = res?.data?.response?.form_data

                    const exAdfields = []
                    const payloadRaw = {}
                    const defaultOptionsArray = defaultOptions.map((itm) => itm.name)
                    Object.keys(savedData).forEach((itm, index) => {
                        if (defaultOptionsArray.includes(itm)) {
                            exAdfields.push({ name: itm, value: savedData[itm] })
                        } else {
                            payloadRaw[itm] = savedData[itm];
                        }
                    })
                    setAdFields(exAdfields)
                    setPayload(payloadRaw)
                })
                .catch((error) => {
                    console.log('errors ', error)
                });
        }
    }, [defaultOptions])

    const handleChange = (name, value) => {
        console.log('handle change', name, value)
        setSubmitError('')
        setPayload({
            ...payload,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]: ''
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const additionalFieldsFinal = {}
        adFields.map((itm) => {
            additionalFieldsFinal[itm.name] = itm.value
        })

        if (id === undefined) {
            formsService.addNew({
                form: 'F_T3', form_data: {
                    ...payload,
                    ...additionalFieldsFinal
                }
            })
                .then((res) => {
                    //---alert after successful submission
                    Alert.success(res?.data?.message)
                    //---redirect to details page of current submissions
                    navigate('/user/return-YR22/forms/F_T3/view/' + res?.data?.response?.new_id)
                    // setEmployeeCodesList(res.data.response.list);
                })
                .catch((error) => {
                    console.log('errors ', error?.response?.data)
                    setErrors(error?.response?.data?.errors)
                    setSubmitError(error?.response?.data?.message)
                });
        } else {
            formsService.update({
                form: 'F_T3', form_data: {
                    ...payload,
                    ...additionalFieldsFinal
                }
            }, id)
                .then((res) => {
                    //---alert after successful submission
                    Alert.success(res?.data?.message)
                    //---redirect to details page of current submissions
                    navigate('/user/return-YR22/forms/F_T3/view/' + id)
                    // setEmployeeCodesList(res.data.response.list);
                })
                .catch((error) => {
                    console.log('errors ', error?.response?.data)
                    setErrors(error?.response?.data?.errors)
                    setSubmitError(error?.response?.data?.message)
                });
        }

    }




    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form onSubmit={handleSubmit} method='post'>
                        <div className='form-group'>
                            <HeadingA title='T3' subtitle='Statement of Trust Income Allocations and Designations' />
                            <div className='form-group-div flex flex-centered gap-1'>
                                <div className='input-div'>

                                    <Input error={errors?.issuer} lable="Issuer" type='text' name='issuer' value={payload?.issuer} hint='' handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                            </div>
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>

                                    <Input error={errors?.box_49} box="49" lable="Actual amount of eligible dividends" type='text' name='box_49' value={payload?.box_49} hint="" handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                                <div className='input-div'>

                                    <Input error={errors?.box_50} box="50" disabled lable="Taxable amount of eligible dividends" type='text' name='box_50' value={payload?.box_50} hint='' required handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                                <div className='input-div'>

                                    <Input error={errors?.box_51} box="51" disabled lable="Dividend tax credit for eligible dividends" type='text' name='box_51' value={payload?.box_51} hint='' handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                            </div>
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>

                                    <Input error={errors?.box_21} box="21" lable="Capital gains" type='text' name='box_21' value={payload?.box_21} hint='' handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                                <div className='input-div'>

                                    <Input error={errors?.box_26} box="26" lable="Other income" type='text' name='box_26' value={payload?.box_26} hint="" handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                                <div className='input-div'>

                                    <Input error={errors?.box_30} box="30" lable="Capital gains eligible for deduction" type='text' name='box_30' value={payload?.box_30} hint='' handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                            </div>
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>

                                    <Input error={errors?.box_23} box="23" lable="Actual amount of dividends other than eligible dividends" type='text' name='box_23' value={payload?.box_23} hint='' handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                                <div className='input-div'>

                                    <Input error={errors?.box_32} box="32" disabled lable="Taxable amount of dividends other than eligible dividends" type='text' name='box_32' value={payload?.box_32} hint="" handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>
                                <div className='input-div'>

                                    <Input error={errors?.box_39} box="39" disabled lable="Dividend tax credit for dividends other than eligible dividends" type='text' name='box_39' value={payload?.box_39} hint="" handleChange={(name, value) => { handleChange(name, value) }} />
                                </div>

                            </div>
                            <AdditionalField defaultOptions={defaultOptions} adFields={adFields} selectedOptions={[{ name: 'box_80', value: '80' }, { name: 'box_81', value: '81' }]} setAdFields={setAdFields} />

                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit' />
                            </div>

                        </div>
                    </form>
                </div>
            </div>




            {/* <Popup trigger={popupButton} setTrigger={setPopupButton}>
                                <div className="">
                                    <h1>{message}</h1>
                                </div>
                            </Popup> */}
        </>
    </>
}

export default F_T3_AddNew;