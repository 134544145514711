import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import "../inner-forms.css";
import masterService from "../../../services/masterService";
import formsService from "../../../services/formsService";
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
// import { Toast } from "react-toastify/dist/components";
import Alert from "../../../commonComponent/Alerts";
import { toast } from "react-toast";
import AdditionalField from "../../../commonComponent/AdditionalFields";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import LayoutSteps from "../../LayoutSteps";
import { Trash } from "../../../commonComponent/icons";
import InputAmount from "../../../commonComponent/input/InputAmount";

const F_T5 = () => {
  const [provincesList, setProvincesList] = useState([]);
  const [employeeCodesList, setEmployeeCodesList] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitError, setSubmitError] = useState("");
  const [adFields, setAdFields] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [payload, setPayload] = useState({});
  const [formList, setFormList] = useState([]);
  const [activeForm,setActiveForm]=useState('new')

  const [formListKeys, setFormListKeys] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {

    //----Fetch provinces
    masterService.getProvinces()
        .then((res) => {
            setProvincesList(res.data.response.list);
        })
        .catch((error) => {
            console.log(error.message)
        });


    //---fetch employee codes
    masterService.getEmployeeCodes()
        .then((res) => {
            setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log(error.message)
        });
}, [])

console.log('provinces ',provincesList)
console.log('statssss',employeeCodesList)

  const handleChange = (name, value) => {
    setSubmitError("");
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const additionalFieldsFinal = {}
    adFields.map((itm) => {
        additionalFieldsFinal[itm.name] = itm.value
    })

    if (activeForm === 'new') {
        formsService.addNew({
            form: 'F_T5', form_data: {
                ...payload,
                ...additionalFieldsFinal
            }
        })
            .then((res) => {
                //---alert after successful submission
                // Alert.success(res?.data?.message)
                window.location.reload()
                //---redirect to details page of current submissions
                // navigate('/user/return-YR22/forms/F_T4/view/' + res?.data?.response?.new_id)
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
    } else {
        
        formsService.update({
            form: 'F_T5', form_data: {
                ...payload,
                ...additionalFieldsFinal
            }
        }, activeForm)
            .then((res) => {
                //---alert after successful submission
                toast.success('amri')
                window.location.reload()
                //---redirect to details page of current submissions
                // navigate('/user/return-YR22/forms/F_T4/view/' + id)
            })
            .catch((error) => {
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
    }

}

  const [filledFormsList, setFilledFormsList] = useState([]);

  useEffect(() => {
    formsService.getFilledForms().then(async (res) => {
      const result = res?.data?.response?.list;
      let detailsKeys = Object.keys(result);
      const list = detailsKeys.map((item) => result[item]);

      const extractForm = list.filter((itm) => itm.code == "F_T5");
      if (extractForm.length == 1 && extractForm[0].list) {
        setFormList(extractForm[0].list);
        setFormListKeys(Object.keys(extractForm[0].list));
      } else {
        setFormList([]);
        setFormListKeys([]);
      }
    });
  }, []);


  const handleDelete = (id) => {
    formsService.deleteForm({ form: 'F_T5', id: id })
      .then(async (res) => {

        if (res?.status == 200) {
          window.location.reload()
        }
      })

      .catch((error) => {
        console.log('error is >', error.response.data.message)
      });
  };

  const handleFormSwitch=(id)=>{
    if(id=='NEW'){
        setPayload({})
        setActiveForm(id)
    }else{
        setPayload(
            formList[id]
        )
        setActiveForm(id)
    }
  }

  return <LayoutSteps
    title="T5 | Statement of Investment"
    actionBack={{action:()=>navigate("/user/return-YR22/step/F_T4")}}
    actionNext={{action:()=>navigate("/user/return-YR22/step/F_T1")}}
  >

<ul className="multi-form-tabs-list">
        {formListKeys.map((itm, index) => {
            console.log(activeForm,itm,activeForm==itm)
          return (
            <li key={itm} className={activeForm==itm?'active':''}>
              
              <div className="label" onClick={()=>handleFormSwitch(itm)} style={{marginRight:8}}>{index + 1}</div>
                <div className="icon" onClick={() => handleDelete(itm)}><Trash color="#6f7173" sty={{fontSize:'10px'}} /></div>
                
            </li>
          );
        })}
        <li className={activeForm=='new'?'active':''}>
              <div className="label" onClick={()=>handleFormSwitch('new')} >
                NEW
              </div>
            </li>
      </ul>
            <div className='form-container'>
                
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group flex flex-column '>
                            {/* <HeadingA title="T5" subtitle='Statement of Investment' /> */}
                            
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <InputAmount 
                                      error={errors?.box_24} 
                                      box="24" 
                                      lable="Actual amount of eligible dividends" 
                                      type='text' 
                                      name='box_24' 
                                      value={payload?.box_24} 
                                      hint='' 
                                      handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <InputAmount 
                                      error={errors?.box_25} 
                                      box="25" 
                                      disabled 
                                      lable="Taxable amount of eligible dividends" 
                                      type='text' 
                                      name='box_25' 
                                      value={payload?.box_25} 
                                      hint='' 
                                      handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                <div className='input-div'>
            
                                    <InputAmount 
                                      error={errors?.box_26} 
                                      box="26" 
                                      disabled 
                                      lable="Dividend tax credit for eligible dividends" 
                                      type='text' 
                                      name='box_26' 
                                      value={payload?.box_26} 
                                      hint='' 
                                      handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <InputAmount
                                      error={errors?.box_10} 
                                      box="10"  
                                      lable="Actual amount of dividends other than eligible dividends" 
                                      type='text' 
                                      name='box_10' 
                                      value={payload?.box_10} 
                                      hint='' 
                                      handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <InputAmount 
                                      error={errors?.box_11} 
                                      box="11" 
                                      disabled 
                                      lable="Taxable amount of dividends other than eligible dividends" 
                                      type='text' 
                                      name='box_11' 
                                      value={payload?.box_11} 
                                      hint="" 
                                      handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <InputAmount 
                                      error={errors?.box_12} 
                                      box="12" 
                                      disabled 
                                      lable="Dividend tax credit for dividends other than eligible dividends" 
                                      type='text' 
                                      name='box_12' 
                                      value={payload?.box_12} 
                                      hint="" 
                                      handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <InputAmount 
                                      error={errors?.box_13} 
                                      box="13" 
                                      lable="Interest from Canadian sources" 
                                      type='text' 
                                      name='box_13' 
                                      value={payload?.box_13} 
                                      hint='' 
                                      handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <InputAmount 
                                      error={errors?.box_18} 
                                      box="18" 
                                      lable="Capital gains dividends" 
                                      type='text' 
                                      name='box_18' 
                                      value={payload?.box_18} 
                                      hint='' 
                                      handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                
                            </div>
                            <AdditionalField defaultOptions={defaultOptions} adFields={adFields} selectedOptions={[{ name: 'box_80', value: '80' }, { name: 'box_81', value: '81' }]} setAdFields={setAdFields} />
                            
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='Add' onClick={handleSubmit}/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
    </LayoutSteps>
};

export default F_T5;
