export const AllFormsList = [
    {
        code: "F_T4",
        to: "F_T4/add-new",
        name: "T4",
        descriptive_name: "Statement of Remuneration Paid"
    },
    {
        code: "F_T3",
        to: "F_T3/add-new",
        name: "T3",
        descriptive_name: "Statement of Trust Income Allocations and Designations"
    },
    {
        code: "F_T4A_OAS",
        to: "F_T4A_OAS/add-new",
        name: "T4A(OAS)",
        descriptive_name: "Statement of Old Age Security"
    },
    {
        code: "F_T2202",
        to: "F_T2202/add-new",
        name: "T2202",
        descriptive_name: "Tuition and Enrolment Certificate"
    },
    {
        code: "F_T4A_P",
        to: "F_T4A_P/add-new",
        name: "T4A(P)",
        descriptive_name: "Statement of Canada Pension Plan Benefits"
    },
    {
        code: "F_T4A_RCA",
        to: "F_T4A_RCA/add-new",
        name: "T4A(RCA)",
        descriptive_name: "Statement of Distributions from a Retirement Compensation Arrangement (RCA)"
    },
    {
        code: "F_T4E",
        to: "F_T4E/add-new",
        name: "T4E",
        descriptive_name: "Statement of Employment Insurance and Other Benefits"
    },
    {
        code: "F_T4RIF",
        to: "F_T4RIF/add-new",
        name: "T4RIF",
        descriptive_name: "Statement of Income from a Registered Retirement Income Fund"
    },
    {
        code: "F_T4RSP",
        to: "F_T4RSP/add-new",
        name: "T4RSP",
        descriptive_name: "Statement of RRSP Income"
    },
    {
        code: "F_T4PS",
        to: "F_T4PS/add-new",
        name: "T4PS",
        descriptive_name: "Statement of Employees Profit Sharing Plan Allocations and Payments"
    },
    {
        code: "F_T5",
        to: "F_T5/add-new",
        name: "T5",
        descriptive_name: "Statement of Investment Income"
    },
    {
        code: "F_T5013",
        to: "F_T5013/add-new",
        name: "T5013",
        descriptive_name: "Statement of Partnership Income"
    },
    {
        code: "F_T5003",
        to: "F_T5003/add-new",
        name: "T5003",
        descriptive_name: "Statement of Tax Shelter Information"
    },
    {
        code: "F_T5008",
        to: "F_T5008/add-new",
        name: "T5008",
        descriptive_name: "Statement of Securities Transactions"
    },
    {
        code: "F_T5007",
        to: "F_T5007/add-new",
        name: "T5007",
        descriptive_name: "Statement of Benefits"
    },
    {
        code: "F_T4A",
        to: "F_T4A/add-new",
        name: "T4A",
        descriptive_name: "Statement of Pension, Retirement Annuity and Other Income"
    },
    {
        code: "F_IWS",
        to: "F_IWS/add-new",
        name: "Income Worksheet",
        descriptive_name: ""
    },
    {
        code: "F_EFW",
        to: "F_EFW/add-new",
        name: "Electronic Filing Worksheet",
        descriptive_name: ""
    },
    {
        code: "F_FWC",
        to: "F_FWC/add-new",
        name: "Family Workchart",
        descriptive_name: ""
    },
    {
        code: "F_RRSP_WS",
        to: "F_RRSP_WS/add-new",
        name: "RRSP Worksheet",
        descriptive_name: ""
    },
    {
        code: "F_Fed_WS",
        to: "F_Fed_WS/add-new",
        name: "Federal Worksheet",
        descriptive_name: ""
    },
    {
        code: "F_Sch_2",
        to: "F_Sch_2/add-new",
        name: "Schedule 2",
        descriptive_name: "Federal Amounts Transferred From Your Spouse or common-law partner"
    },
    {
        code: "F_Sch_5",
        to: "F_Sch_5/add-new",
        name: "Schedule 5",
        descriptive_name: "Amounts for spouse or Common-Law Partner and Dependants"
    },
    {
        code: "F_TSI",
        to: "F_TSI/add-new",
        name: "Taxpayer spouse information",
        descriptive_name: ""
    },
    {
        code: "F_T5006",
        to: "F_T5006/add-new",
        name: "T5006",
        descriptive_name: "Income Tax and Benefit Return"
    },

]