import React from 'react';
import HeaderAfLog from '../../YR22/pages/components/header/HeaderAfLog';
import Banner from '../components/banner/Banner';
import About from '../components/about/About';
import Banner_2 from '../components/banner/Banner_2';
import SoftInfo from '../components/softInfo/SoftInfo';
import Support from '../components/support/Support';
import About2 from '../components/about/About2';
import ContactUs from '../components/contactUs/ContactUs';
import FAQ from '../components/FAQ/FAQ';
import Footer from '../components/footer/Footer';
import HowItWorks from '../components/howItWorks/HowItWorks';
import About3 from '../components/about/About3';

const Home=()=>{
    return (
        <>
            <div className="home-container flex-centered flex-column">
                
                <Banner_2 />
                {/* <Support /> */}
                <hr style={{width: '80%'}}/>
                <About3 />
                {/* <hr style={{width: '80%'}}/> */}

                {/* <HowItWorks /> */}
                {/* <hr style={{width: '80%'}}/> */}
                {/* <SoftInfo /> */}
                {/* <About2 /> */}
                {/* <ContactUs /> */}
                {/* <FAQ /> */}
                {/* <Footer /> */}
            </div>
        </>
    )
}
export default Home;