import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import formsService from '../../services/formsService'
// import DumyFormView from "../../DumyFormView";
import './preview.css'

const Preview=()=>{
    const params=useParams();
    const [details,setDetails]= useState({})

    // console.log("Params :",params);
    useEffect(() => {
    
        formsService.getPreview({})
        .then( async(res) => {
            // console.log('res',res)
            setDetails(res?.data?.response?.details);
            // console.log(details)
        })
        .catch((error) => {
            console.log('errors ',error)
        });
    }, [])
    // console.log('details are',details);
    let detailsKeys = Object.keys(details);
    // console.log('detailsKeys are',detailsKeys);
    const lists= detailsKeys.map((itm)=>{
    return <li className="prv-itm" key={itm}>
        <div>{details[itm]?.name}</div>
        <div>{itm}</div>
        <div>{details[itm]?.value}</div>
    </li>
    }
    )
    return <>
      <div className="w-100" style={{background:'white',padding:'20px',lineHeight:'2em'}}>
          <ul>
              {lists}
          </ul>
      </div>
  </>

}

export default Preview