import { createSlice } from "@reduxjs/toolkit";
// import AuthService from "../../services/authService";
// import { loadingStatus } from "../global/globalSlice";
// import { toast } from "react-toastify";
// import { userInfoSuccess } from "../user/userSlice"
import authService from '../../services/auth/authService'
import axios from "axios";

export const getLogin = (param, navigate) => async (dispatch) => {
    // dispatch(loadingStatus(true));

    return authService.getLogin(param).then(async (res) => {
        console.log('resp at slice',res)
        dispatch(authSuccess(res?.data.response));
        localStorage.setItem("token", res?.data?.response?.token)
        navigate("/user/return-YR22/start")
        return res;
    })
        .catch((error) => {

            dispatch(authSuccess(null));
            localStorage.removeItem("token")
            return error
        });
};

export const getLogout = (navigate) => async (dispatch) => {
    // dispatch(loadingStatus(true));

    return authService.getLogout().then(async (res) => {
        
        dispatch(authSuccess(null));
        localStorage.removeItem("token")
        navigate("/")
        return res;
    })
        .catch((error) => {

            dispatch(authSuccess(null));
            localStorage.removeItem("token")
            return error
        });
};


const initialState = {
    loading: false,
    auth: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        authSuccess: (state, action) => {
            state.success = true;
            state.auth = action.payload;

        },
        authError: (state, action) => {
            state.success = false;
            state.auth = null;
        }
    },
    extraReducers: {},
});

export const {
    authSuccess,
    authError
} = authSlice.actions;

export default authSlice.reducer;
