import React, { Children } from 'react'
import './svgTxtCard.css'

function SvgTxtCard(props) {

    const divResponsive = {
        background: props.background,
        padding:  "3em 5em",
        boxShadow: props.boxShadow,
        borderRadius: "2em",
        border: props.border,
        flexDirection: props.flexDirection,
    }

  return (
        <div className="svgTxtCard-div w-100 flex gap-1 flex-centered" style={divResponsive}>
            <div className='w-100 svgTxtCard-txt-div flex flex-column gap-2'>
                { props.heading ? <h2 className='h2-txt' style={{textAlign: props.h2Align}}>{props.heading}</h2> : ""}
                <ul>
                    { props.point1 || props.point2 || props.point3 ?
                    <>
                        <li><p className='p-txt'>{props.point1}</p></li>
                        <li><p className='p-txt'>{props.point2}</p></li>
                        <li><p className='p-txt'>{props.point3}</p></li>
                    </>
                    :''
                }
                </ul>
            </div>
            <div className='w-100 svgTxtCard-svg-div flex flex-column gap-1'>
                {props.children}
            </div>
        </div>
  )
}

SvgTxtCard.propsDefault = {
    flexDirection: 'row',
    
    background: "var(--txt-color-light)",
}

export default SvgTxtCard