const DumyFormView=({details})=>{
    // console.log('details',details);
    let detailsKeys = Object.keys(details);
    const lists= detailsKeys.map((item)=><li key={item}><i>{item}</i>: <span style={{fontSize:'1.2em',fontWeight:'bold'}}>{details[item]}</span></li>)
    // console.log('lists ',lists)
    return <>
        <div className="w-100" style={{background:'white',padding:'20px',lineHeight:'2em'}}>
            <ul>
                {lists}
            </ul>
        </div>
    </>
}

export default DumyFormView;