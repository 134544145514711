import React from 'react'
import Button from '../../commonComponent/button/Button'
import Card from '../../commonComponent/card/Card'
import "./banner.css"
import img from '../../../assets/images/banner2_001.png'
import Dlr_img from '../../../assets/images/dlr_coin.png'
import { ReactComponent as SupportIcon1 } from '../../../assets/svg/faceId.svg'
import { ReactComponent as SupportIcon2 } from '../../../assets/svg/touchId.svg'
import { ReactComponent as SupportIcon3 } from '../../../assets/svg/encrypted.svg'


const Banner_2 = () => {

    return (
        <>
        <div className='banner2-section w-100 flex-centered flex-column'>
            
                <div className='w-80 flex-centered banner2-outer-div flex-column'>
                    <div className='banner2-back-blur w-100'></div>
                    <div className="w-100 flex-centered banner2-div gap-1">
                        <div className='banner2-txt-div w-100 flex-centered flex-column gap-2'>
                            <div className='banner2-back-blur-txt w-100'></div>
                            <div className='flex flex-column gap-1'>
                                <h2 className='h2-txt'>Streamline Your Tax Return Process.</h2>
                                <p className='p-txt'>Unlock financial freedom with precision and ease Empower your tax journey with our intuitive TallyTax</p>
                            </div>
                            <div className='w-100'>
                                <Button title="File my return" />
                            </div>
                        </div>
                        <div className='banner2-img-div flex w-100 flex-centered'>
                            <div className="banner2-abs-div">
                                <img src={img} />
                            </div>
                            <div className="banner2-abs-coin">
                                <img src={Dlr_img} />
                            </div>
                        </div>
                    </div>
                    <div className='banner2-bottom-content flex flex-between'>
                        <div className='flex-centered gap-1 '><SupportIcon1 style={{width: '3em', height: '3em', fill:'skyblue'}}/> <p>Tax Enchantment Spectrum</p></div>
                        <div className='flex-centered gap-1'><SupportIcon3 style={{width: '3em', height: '3em', fill:'skyblue'}}/><p>Effortless Filing Assurance</p></div>
                        <div className='flex-centered gap-1'><SupportIcon2 style={{width: '3em', height: '3em', fill:'skyblue'}}/><p>Tax Mastery Network</p></div>
                    </div>
                </div>
                
                
        </div>
        <div className='banner2'>
            <div className="banner2-color"></div>
            <div className="banner2-color1"></div>
        </div>
        </>
    )
}

export default Banner_2