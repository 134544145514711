import './forms.css'
import React, { useEffect } from 'react';
import Button from '../../commonComponent/button/Button';
import { useState } from 'react';
import Popup from '../../commonComponent/popup/Popup';
import masterService from '../../services/masterService'
import FormsListBlank from './FormsListBlank';
import Search from '../../commonComponent/input/Search';
import formsService from '../../services/formsService';
import { NavLink } from 'react-router-dom';

import { Edit, Trash } from '../../commonComponent/icons'
const Forms = () => {
  const [popupButton, setPopupButton] = useState(false);

  const [filledFormsList, setFilledFormsList] = useState([]);
  const [formsListFilterd, setFormsListFiltered] = useState([]);
  const [listError, setListError] = useState('')
  const [startReturnButton,setStartReturnButton]=useState(false)

  console.log('filledFormsList', filledFormsList)
  useEffect(() => {

    formsService.getFilledForms()
      .then(async (res) => {
        const result = res?.data?.response?.list
        let detailsKeys = Object.keys(result);
        const list = detailsKeys.map((item) => result[item])



        setFilledFormsList(list);
        setFormsListFiltered(list);
      })

      .catch((error) => {
        setListError(error.response.data.message)
        if(error.response.data.message=='No return data found'){
          alert(error.response.data.message)
          
        }
        
      });

  }, [])

  const handleSearch = (search) => {
    if (search !== '') {
      setFormsListFiltered(filledFormsList.filter((item) => { return (item.name.toLowerCase().includes(search.toLowerCase()) || item.descriptive_name.toLowerCase().includes(search.toLowerCase())) }))
    } else {
      setFormsListFiltered(filledFormsList)
    }

  }
  // console.log('filledFormsList',filledFormsList)
  const handleDelete = (e) => {


    e.preventDefault()
    let form = e.currentTarget.getAttribute("data-form");
    let form_id = e.currentTarget.getAttribute("data-id");
    formsService.deleteForm({ form: form, id: form_id })
      .then(async (res) => {

        if (res?.status == 200) {
          alert(res.data.message);
          window.location.reload()

        }
      })

      .catch((error) => {
        console.log('error is >', error.response.data.message)
      });

  }

  return <>

  {(startReturnButton)?<Button myClass='btn-form' onClick={() => {setPopupButton(true)}}
            title="Add Form"
          />:''}
    <div className="forms-container w-100">

      <div className='form-section flex flex-column gap-3 w-100'>
        <div className='form-header flex-centered gap-1'>
          <Search type='search' hint='Seach Forms' name='search' handleChange={(search) => handleSearch(search)} />
          <Button myClass='btn-form' onClick={() => {
            setPopupButton(true)
          }
          }
            title="Add Form"
          />
        </div>


        <ul className='form-cards flex flex-centered gap-1 w-100'>
          {listError == "" ? formsListFilterd.map((form) => {

            if (form.is_multi) {
              let detailsKeys = Object.keys(form.list);
              return detailsKeys?.map((item) => {
                return <li className='form-card-div flex flex-column' style={{ position: 'relative' }} key={form.item}>
                  <NavLink to={'/user/return-YR22/forms/' + form.code + '/view/' + item} className='flex flex-column flex-centered gap-1'>
                    <NavLink to={'/user/return-YR22/forms/' + form.code + '/edit/' + item}><span style={{ position: 'absolute', right: '30px', top: '5px', fontWeight: 'bolder' }} data-form={form.code} data-id={item} title='Edit form'>
                      <Edit color='grey' />
                    </span>
                    </NavLink>
                    <span style={{ position: 'absolute', right: '2px', top: '5px' }} data-form={form.code} data-id={item} onClick={handleDelete} title='Delete form'>
                      <Trash color='grey' />
                    </span>
                    <div className='heading flex flex-centered' >
                      <h2>{form.name}</h2>
                    </div>
                    <div className='discription flex flex-centered'>
                      <p>{form.descriptive_name}</p>
                    </div>
                    <p>( {form.list[item].employer_name} )</p>
                  </NavLink>
                </li>
              })
            } else {
              return <li className='form-card-div flex flex-column' style={{ position: 'relative' }} key={form}>
                <NavLink to={'/user/return-YR22/forms/' + form.code + '/view'} className='flex flex-column flex-centered gap-1'>
                  <NavLink to={'/user/return-YR22/forms/' + form.code + '/edit/form-data'}><span style={{ position: 'absolute', right: '30px', top: '5px' }} data-form={form.code} title='Edit form'>
                    <Edit color='grey' />
                  </span>
                  </NavLink>
                  <span style={{ position: 'absolute', right: '2px', top: '5px' }} data-form={form.code} onClick={handleDelete} title='Delete form'>
                    <Trash color='grey' />
                  </span>
                  <div className='heading flex flex-centered' >

                    <h2>{form.name}</h2>
                  </div>
                  <div className='discription flex flex-centered'>
                    <p>{form.descriptive_name}</p>

                  </div>
                </NavLink>
              </li>
            }

          })
            : <p style={{ color: 'red', textAlign: 'center' }}>{listError}</p>
          }
        </ul>

        <Popup trigger={popupButton} setTrigger={setPopupButton}>
          <FormsListBlank />
        </Popup>



      </div>
    </div>





  </>
}

export default Forms;