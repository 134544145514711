import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import Dropdown from "../../../commonComponent/dropdown/Dropdown";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    const boxStyle = {
        background: 'black',
        color: 'white',
        padding: '0.2em 0.5em',
    }

    const dropDownStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.div_1 ? 
                <div className="w-20 flex-centered">
                    <p>{props.div_1}</p>
                </div>
                :""
                }
                {props.div_2 ? 
                <div style={{minWidth: '5%'}} className=" flex-centered">
                    <p>{props.div_2}</p>
                </div>
                :""
                }
                {props.div_3 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3}</p>
                </div>
                :""
                }
                {props.box && <p style={boxStyle}>{props.box}</p>}
                {props.input1 == 'yes' ? 
                <div  className="flex"  style={inputStyle}>
                    <Input input={props.input1} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.box2 && <p style={boxStyle}>{props.box2}</p>}
                {props.div_3_a ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3_a}</p>
                </div>
                :""
                }
                
                <div  className="flex"  style={inputStyle}>
                    {props.input2 == 'yes' ?
                        <Input input={props.input2} type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        :""
                    }
                </div>
                {props.dropdown && (
                    <div style={dropDownStyle} className="flex ">
                        <Dropdown name={props.drop_name} value={props?.drop_value} hint='' lable={props.drop_lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                )}
                {props.div_4 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_4}</p>
                </div>
                :""
                }
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}
const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        justifyContent: window.innerWidth < 500 ? 'flex-start' : 'flex-end',

    }

    const boxStyle = {
        background: 'black',
        color: 'white',
        padding: '0.2em 0.5em',
    }

    const inputResponsive = {
        minWidth: '20%'
    }

    const dropDownStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.box ? <p style={boxStyle}>{props.box}</p> : ''}
                {props?.name &&
                <div style={checkBoxStyle} className="flex">
                
                    <Checkbox name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                
                </div>}
                
                {props.subTitle2 ? <p style={subTitleStyle}>{props.subTitle2}</p> : ''}
                {props.box2 ? <p style={boxStyle}>{props.box2}</p> : ''}
                {props.input == 'yes' ?
                    <div style={inputResponsive}>
                        <Input input={props.input} type={props.type} name={props.Iname} value={props?.Ivalue} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        
                    </div>
                    :""
                }
            </div>
            <hr />
        </div>
    )
    
}
const Form_UI_c=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        maxWidth: '20%',
    }

    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.input == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <Input input={props.input} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.input1 == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <p>{props.txt1}</p>
                    <Input input={props.input1} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.input2 == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <p>{props.txt2}</p>
                    <Input input={props.input2} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                
                <div style={checkBoxStyle} className="flex-centered flex-column gap-2">
                    <p>{props.txt3}</p>
                    <div className="flex gap-2">
                        <Checkbox name={props.name} value={props?.value_1} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        <Checkbox name={props.name} value={props?.value_2} hint='' lable={props.lable_2} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const F_TSI_AddNew=()=>{

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_TSI'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)


    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_TSI', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_TSI/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_TSI', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_TSI/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="Taxpayer's Personal Identification" subtitle="Amounts for Spouse or Common-Law Partner and Dependants" />
                            
                            <br />
                                                        
                            
                            <div className='form-group-div flex-column'>
                                <MaterialContainer heading="Elections Canada (see the elections Canada page in the tax guide for details or visit www.elections.ca)">
                                    
                                    <br/>
                                    <Form_UI_c name="EC_001" subTitle="Are you a Canadian citizen?" lable_1="Yes" lable_2="No" div_3_a="" div_4="" value_1="yes" value_2="no" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_c name="EC_002" title="Answer the following question only if you are a Canadian citizen." subTitle="As a Canadian citizen, do you authorize the Canada Revenue Agency to give your name, address, date of birth, and citizenship to Elections Canada to update the National Register of Electors or if you are aged 14 to 17, to update the Register of Future Electors?" lable_1="Yes" lable_2="No" div_3_a="" div_4="" value_1="yes" value_2="no" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    {/* <br/> */}
                                    <p>Your authorizations is valid until you file your next return. Your information will only be used for purposes permitted under the Canada Elections Act which includes sharing the information with provincial/territorial election agencies, Members of parliment and registered political parties, as well as cadidates at election time.</p>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Foreign Property: Please answer the following question">
                                    
                                    <br/>
                                    <Form_UI_c name="FP_001" subTitle="Did you own or hold foreign property at any time in 2022, with a total cost of more than CAN $100,000?" lable_1="Yes" lable_2="No" div_3_a="" div_4="" value_1="yes" value_2="no" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    {/* <br/> */}
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Language confirmation">
                                    
                                    <br/>
                                    <Form_UI_c name="Lang_Conf_001" subTitle="Please indicate your choice of language for correspondence with CRA" lable_1="English" lable_2="French" div_3_a="" div_4="" value_1="english" value_2="french" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    {/* <br/> */}
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Disabled taxpayer under 18 years of age">
                                    
                                    <br/>
                                    <Form_UI_a name="disabled_taxpayer_001" subTitle="Total child and disability support expenses claimed for you by anyone " input2="yes" div_3_a="" div_4="" value={payload.disabled_taxpayer_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    {/* <br/> */}
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Self employment">
                                    
                                    <br/>
                                    <Form_UI_a name="self_emp_001" subTitle="Province of Self-employment" input2="yes" div_3_a="" div_4="" value={payload.self_emp_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    {/* <br/> */}
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="For Ontario and Nunavut">
                                    
                                    <br/>
                                    <p><b>Consent to share contact information - Organ and tissue donor registry</b></p>
                                    {/* <br/> */}
                                    <Form_UI_c name="ontario_nunavat_001" subTitle="I authorize the CRA to provide my name and email address to Ontario Health so that Ontario Health (Trillium Gift of Life) may contact or send information to me by email about organ and tissue donation" lable_1="Yes" lable_2="No" div_3_a="" div_4="" value_1="yes" value_2="no" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    {/* <br/> */}
                                    <p>You are not consenting to organ or tissue donation when you authorize the CRA to share your contact information with Ontario Health. Your authorization is valid only in the tax year that you file this tax return. Your information will only be collected under the Ontario Gift of Life Act.</p>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Indian Act - Exempt income">
                                    
                                    <br/>
                                    
                                    <Form_UI_b name="indian_act_EI_001" subTitle="Do you have income which is exempt under the indian Act?" lable_1="" lable_2="" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    {/* <br/> */}
                                    <Form_UI_b subTitle="If you check the box, Complete Form T90, Income exempt under the indian Act. Complete this from so that the CRA can calculate your Canada Training Credit Limit  for the 2023 tax year. The information you provide may also be used to calculate your Canada Workerd Benefit for the 2022 tax year, if applicable." box2="T90" />
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Current mailing address">
                                    
                                    <br/>
                                    <Form_UI_a name="current_mailing_add_001" subTitle="The taxpayer's mailing address is .........." input2="yes"  lable_1="" lable_2="" div_3_a="" div_4="" value={payload.current_mailing_add_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="current_mailing_add_002" subTitle="Tax return contains a non-Canadian mailing address. Check to indicate if the taxpayer is Canadian resident, a factual resident, or a Non-resident?" lable="Canadian resident" lable_2="" div_3_a="" div_4="" value="canadian_resident" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="current_mailing_add_003" subTitle="" lable="Factual resident" lable_2="" div_3_a="" div_4="" value="factual_resident" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="current_mailing_add_004" subTitle="" lable="Non-resident" lable_2="" div_3_a="" div_4="" value="non_resident" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <p>You are a factual resident of Canda for income tax purposes if you keep significant residential ties in Canada while living or travelling outside the country. The term factual resident means that, although you left Canada, you are still considered to be a resident of Canada for income tax puposes.</p>
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Seasonal agricultural workers">
                                    
                                    <br/>
                                    <Form_UI_b name="seasonal_agri_001" subTitle="Check, if you are a seasonal agricultural worker" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Non-Resident - information about your residence">
                                    
                                    <br/>
                                    <Form_UI_a name="non_resident_001" subTitle="Enter the name of the country where the worker normally resides. This does not apply if the worker is from a non-treaty country and is in Canada for more that 182 days." dropdown=" " div_3_a="" div_4="" value={payload.non_resident_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Spouse or Common law partner Information">
                                    
                                    <br/>
                                    <Form_UI_a name="" title="Complete the following schedule only if your returns are not Linked" subTitle="Your spouse or partner cannot transfer any unused amounts to you, if you were seperated because of a breakdown in relationship for a period of 90 days commencing in the year." div_3_a="" div_4="" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="Common_law_info_001" title="" subTitle="Spouse's First Name" input2="yes" div_3_a="" div_4="" value={payload.Common_law_info_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="Common_law_info_002" title="" subTitle="Last Name of your spouse" input2="yes" div_3_a="" div_4="" value={payload.Common_law_info_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="Common_law_info_003" title="" subTitle="SIN of your spouse or partner" type="text" input2="yes" div_3_a="" div_4="" value={payload.Common_law_info_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="Common_law_info_004" title="" subTitle="Date your marital status changed in 2022" type="date" input2="yes" div_3_a="" div_4="" value={payload.Common_law_info_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="Common_law_info_005" title="" subTitle="Date of birth of your spouse or partner" type="date" input2="yes" div_3_a="" div_4="" value={payload.Common_law_info_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="Common_law_info_006" title="" subTitle="Province of return of your spouse or partner" dropdown=" " div_3_a="" div_4="" value={payload.Common_law_info_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                    <p><b>Check if any of the following applies to</b></p>
                                    <br />
                                    <Form_UI_b name="check_if_any_applies_to_001" subTitle="If you were married or common law throughout the year but was not living or supported with spouse or common law partner at some time in the year" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="check_if_any_applies_to_002" subTitle="Confirm if you were married or common law at any time in the tax year and were living separated for less than 90 days due to breakdown in a relationship" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="check_if_any_applies_to_003" subTitle="Check if you financially supported non resident spouse in 2022" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="check_if_any_applies_to_004" subTitle="Choose non resident partner's country of residence" dropdown=" " div_3_a="" div_4="" value={payload.check_if_any_applies_to_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <br/>
                                    <Form_UI_b name="check_if_any_applies_to_005" subTitle="Check to claim disability credit from your ex or deceased spouse, only if CRA already has an approval T2201 from" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="check_if_any_applies_to_006" subTitle="Check if is self-employed" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="check_if_any_applies_to_007" subTitle="Check if is infirm" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="check_if_any_applies_to_008" subTitle="Check,if became bacnkrupt in 2022" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                <br/>
                                <br/>
                                <MaterialContainer heading="Spouse's Income ">
                                    
                                    <br/>
                                    <p>Enter your spouse or ex-spouse's net income</p>                                    
                                    <Form_UI_a name="spouse_income_001" title="" subTitle="(If you were seperated or widowed in the year, enter the net income of your spouse for the period you were together)" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_income_002" subTitle="Check, if 's net income is nil or negative" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Enter your spouse's UCCB income</p>
                                    <Form_UI_a name="spouse_income_003" title="" subTitle="(Spouse with lower net income must report UCCB)" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_income_004" title="" subTitle="Enter spouse's splits income form T1206 line 4" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_income_005" title="" subTitle="Spouse's Universal Child Care Benefits repayment" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_income_006" title="" subTitle="Child Care Expenses (Line 21400)" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_income_007" title="" subTitle="Enter their taxable income" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_income_008" subTitle="Check if 's taxable income is zero" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_income_009" title="" subTitle="'s total non refundable tax credits from line 35000 of schedule 1" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_income_010" subTitle="Check if 's federal or provincial adjusted taxable income is zero" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_income_011" title="" subTitle="Enter spouse's federal tax. Line 73 form 's schedule 1" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Enter spouse's provincial tax if spouse's taxable income exceeds $46226</p>
                                    <Form_UI_a name="spouse_income_012" title="" subTitle="Line 8 from 's Provincial 428" input2="yes" div_3_a="" div_4="" value={payload.spouse_income_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                </MaterialContainer>
                                <br/>
                                <br/>
                                <MaterialContainer heading="Spouse Additional Information">
                                    
                                    <br/>
                                    <p>Amounts used in the calculation of credits transferred from's return</p>                                    
                                    <Form_UI_a name="spouse_add_info_001" title="Capital gain Information form 's return" subTitle="Line 12700 from page 3 of 's tax return" input2="yes" div_3_a="" div_4="" value={payload.spouse_add_info_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_add_info_002" title="" subTitle="Line 12700 from page 3 of 's tax return" input2="yes" div_3_a="" div_4="" value={payload.spouse_add_info_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_add_info_003" title="" subTitle="Line 12700 from page 3 of 's tax return" input2="yes" div_3_a="" div_4="" value={payload.spouse_add_info_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <br/>
                                    <Form_UI_a name="spouse_add_info_004" title="Registered Disability Savings Plan" subTitle="Line 12700 from page 3 of 's tax return" input2="yes" div_3_a="" div_4="" value={payload.spouse_add_info_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_add_info_005" title="" subTitle="Line 12700 from page 3 of 's tax return" input2="yes" div_3_a="" div_4="" value={payload.spouse_add_info_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <br/>

                                    <Form_UI_b name="spouse_add_info_006" title="Spousal Amount and Spousal Credit Transfer" subTitle="Check, if you don't want to claim spousal amount" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_add_info_007" subTitle="Check, if you can't claim disability because claimed attendant care instead of disability" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_add_info_008" subTitle="Check, if you don't want to claim federal and provincial creadits from 's return" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_add_info_009" subTitle="Check, if you don't want to claim 's disability amount" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="spouse_add_info_010" title="" subTitle="Disability support or child care expenses paid for spouse under 18" input2="yes" div_3_a="" div_4="" value={payload.spouse_add_info_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <br/>

                                    <Form_UI_b name="spouse_add_info_011" title="Prison Status" subTitle="Check if was in prison for 90 days or more" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_add_info_012" subTitle="Check if was in prison on December 31st and had been for 6 months or more" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <br/>
                                    
                                    <Form_UI_b name="spouse_add_info_013" title="CWB Claim" subTitle="Check if is claiming canada workers benefits on his/her tax return" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br/>
                                <br/>
                                <MaterialContainer heading="Information for Schedule 2">
                                    
                                    <br/>
                                    <Form_UI_a name="info_sch_2_001" title="Complete the following schedule only if your returns are not Linked (Enter amounts form spouse's schedule 1)" subTitle="Line 3000 - Base Personal Amount" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_002" title="" subTitle="Line 30100 - Age Credit" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_003" title="" subTitle="Line 30800 - CPP or QPP Contributions" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_004" title="" subTitle="Line 31000 - Self-employed CPP or QPP contributions" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_005" title="" subTitle="Line 31200 and Line 31217 - Employment insurance premiums" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_006" title="" subTitle="Line 31300 - Adoption expenses" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_007" title="" subTitle="Line 31350 - Digital news subscription expenses" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_008" title="" subTitle="Line 31400 - Pension credit" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_009" title="" subTitle="Line 32300 - Tuition and education amount (Line 11 of Schedule 11)" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_010" title="" subTitle="Line 30450 - Canada caregiver amount for infirm children under 18 years of age (your spouse's line 30500 of schedule 1) (maximum $2350 per child)" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="info_sch_2_011" title="" subTitle="Line 31220 - Volunteer firefighters amount" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="info_sch_2_012" title="" subTitle="Line 31240 - Search and rescue volunteers amount" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="info_sch_2_013" title="" subTitle="Line 31260 - Canada employment credit" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_013} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_014" title="" subTitle="Line 31270 - Home buyer's credit" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_014} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Premiums paid (amount from box 55 on all T4 slips)</p>
                                    <Form_UI_a name="info_sch_2_015" title="" subTitle="Line 31205 - Provincial Parental Insurance Plan (PPIP)" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_015} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_016" title="" subTitle="Line 31210 - PPIP premiums payable on employment income" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_016} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_017" title="" subTitle="Line 31215 - PPIP premiums payable on self-employment income" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_017} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_018" title="" subTitle="Line 31285 - Home accessibility expenses" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_018} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="info_sch_2_019" title="" subTitle="Amount from RC269, Field 51200" input2="yes" div_3_a="" div_4="" value={payload.info_sch_2_019} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Tuition and Education amounts transferred from your Spouse/Partner">
                                    
                                    <br/>
                                    <Form_UI_a name="tui_edu_amount_trans_001" title="" subTitle="Amount designated for transfer from's T2202 or T2202A" input2="yes" div_3_a="" div_4="" value={payload.tui_edu_amount_trans_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="tui_edu_amount_trans_002" title="" subTitle="Tuition and education amounts from your spouse or common law partner (Provincial Schedule 11)" input2="yes" div_3_a="" div_4="" value={payload.tui_edu_amount_trans_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Enter the following lines form your spouse or common law partner's Form 428 of the following provinces">
                                    
                                    <br/>
                                    <Form_UI_a name="commonlaw_partner_form_428_001" title="" subTitle="Line 58040 - Basic personal amount" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_002" title="" subTitle="Line 58080 - Age Credit" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_003" title="" subTitle="Line 58320 - Child care amount -NL" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_004" title="" subTitle="Line 58330 - Adoption expenses (BC, AB, MB, ON, NL & YT)" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_005" title="" subTitle="Line 58357 - Home buyer's amount (Saskatchewan)" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_006" title="" subTitle="Line 58325 - Fitness amount (MB)" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_007" title="" subTitle="Line 58440 - Disability amount" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_008" title="" subTitle="Line 58560 - Tuition and education amounts" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_009" title="" subTitle="Line 58326 - Children's arts credit (MB and YT only)" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_010" title="" subTitle="Line 58210 - Amount for children born in 2004 or later (SK only)" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_011" title="" subTitle="Line 58220 - Senior supplementary amount (SK Only)" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="commonlaw_partner_form_428_012" title="" subTitle="Line 58340 - Home Renovation Expenses (SK Only)" input2="yes" div_3_a="" div_4="" value={payload.commonlaw_partner_form_428_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Spouse is newcomer to Canada">
                                    
                                    <br/>
                                    <Form_UI_a name="spouse_newcomer_001" title="" subTitle="Spouse's date of arrival" input2="yes" div_3_a="" div_4="" value={payload.spouse_newcomer_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_newcomer_002" title="" subTitle="Check if Spouse became immgrant in 2022" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_newcomer_003" title="" subTitle="Number of days in Canada" input2="yes" div_3_a="" div_4="" value={payload.spouse_newcomer_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="spouse_newcomer_004" title="Spouse's Income for the part of the year she/he was not resident of Canada" subTitle="Canadian source income (Field 52920)" input2="yes" div_3_a="" div_4="" value={payload.spouse_newcomer_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_newcomer_005" title="" subTitle="Check if field 52920 is zero" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_newcomer_006" title="" subTitle="Foreign Income (Field 52930)" input2="yes" div_3_a="" div_4="" value={payload.spouse_newcomer_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_newcomer_007" title="" subTitle="Check, if field 52930 is zero" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_newcomer_008" title="" subTitle="Spouse can claim the full amount of non refundable credits" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="spouse_newcomer_009" title="" subTitle="The non refundable credits are prorated based on number of days in Canada" div_3_a="" div_4="" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="spouse_newcomer_010" title="" subTitle="Spouse's proration factor for non refundable credits" input2="yes" div_3_a="" div_4="" value={payload.spouse_newcomer_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_TSI_AddNew;