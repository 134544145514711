import { getRequest,postRequest } from '../../services/axios/';

/**
 * Api call
 */
class authService {

    getLogin = async (payload) =>{
       return await postRequest('login',payload);
        }
    
        getLogout = async (payload) =>{
            return await postRequest('logout',payload);
             }
    getProvinces = async (urlParam={},payload) =>{
        return await getRequest('user/return-YR22/masters/provinces',urlParam);
            }

    getEmployeeCodes = async (urlParam={},payload) =>{
        return await getRequest('user/return-YR22/masters/employment-codes',urlParam);
            }

}

const instance = new authService();

export default instance;