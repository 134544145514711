import React from 'react'
import './input.css'

function Input(props) {
  return (
    <>
        <input 
        className="input-login " 
        type={props.type} 
        placeholder={props.hint} 
        name={props.name} 
        // onChange={props.onChange} 
        onChange={(e) => props.handleChange(props.name, e?.target?.value)}
        value={props.value}/>
    </>
  )
}

Input.defaultProps = {
  type:"text",

}

export default Input