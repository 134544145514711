import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div className="flex " style={inputStyle}>
                    <Input type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}

const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100">
            {props?.title ? <div className="title"><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <Input type='text' name={props.title_name} value={props?.title_value} hint='' handleChange={(name, value) => {props.title_handleChange(name, value)}}/>
                <div className="flex " style={inputStyle}>
                    <Input type='text' name={props.name} value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
                </div>    
                
            </div>
            <hr />
        </div>
    )
    
}

const Form_UI_c=(props)=> {

    const titleStyle = {
        width : '100%',
    }

    const subTitleStyle = {
        width: '100%',
    }

    

    return (
        <div className="form-input-ui-div flex-column w-100">
            {window.innerWidth < 800 ?  
            <div className="w-100 flex flex-column gap-1">
                <MaterialContainer >
                    <div className=" flex flex-column">
                        <p>First Name</p>
                        <Input type='text' name={props.first_name} fn_value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
                    </div>
                    <div className="flex flex-column">
                        <p>Name</p>
                        <Input type='text' name={props.name} n_value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
                    </div>
                    <div className="flex flex-column">
                        <p>Received</p>
                        <Input type='text' name={props.received} r_value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>

                    </div>
                    <div className="flex flex-column">
                        <p>Taxable</p>
                        <Input type='text' name={props.taxable} t_value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>

                    </div>
                </MaterialContainer>
            </div>
            :
            <div className="w-100 flex flex-between gap-1">
                <Input type='text' name={props.first_name} fn_value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
                <Input type='text' name={props.name} n_value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
                <Input type='text' name={props.received} r_value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
                <Input type='text' name={props.taxable} t_value={props?.value} hint='' handleChange={(name, value) => {props.handleChange(name, value)}}/>
            </div>
            }
            
        </div>
    )
    
}

const F_IWS_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_IWS'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)

    // const  handleSubmit=(e)=>{
    //     e.preventDefault();
        
    //     console.log('payload is',payload)
    //     formsService.addNew({form: 'F_T4A_OAS', form_data: payload})
    //     .then( (res) => {
    //         //---alert after successful submission
    //         alert(res?.data?.message)
    //         //---redirect to details page of current submissions
    //         navigate('/user/return-YR22/forms/F_T4A_OAS/view/'+res?.data?.response?.new_id)
    //         // setEmployeeCodesList(res.data.response.list);
    //     })
    //     .catch((error) => {
    //         console.log('errors ',error?.response?.data)
    //         setErrors(error?.response?.data?.errors)
    //         setSubmitError(error?.response?.data?.message)
    //     });
    // }



    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_IWS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_IWS/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_IWS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_IWS/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="Income Worksheet" subtitle="" />
                            <br />
                            <div className='form-group-div flex-column'>
                                <MaterialContainer heading="Other employment income">
                                    {window.innerWidth < 340 ? <> <br /></> : ""}
                                    
                                    <Form_UI_a name="OEI_001" subTitle="Employment income reported on T4As, T4PS, research grants" value={payload?.OEI_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_002" subTitle="Royal income form work or inventions" value={payload?.OEI_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_003" subTitle="Foreign Employment Income" value={payload?.OEI_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_004" subTitle="Tips and gratuities" value={payload?.OEI_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_005" subTitle="Prior year's GST or QST rebate claimed" value={payload?.OEI_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_006" subTitle="Recapture of CCA (T777)" value={payload?.OEI_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_007" subTitle="Wage loss plan benefits recieved, as reported on T4, T4As" value={payload?.OEI_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_008" subTitle="Other Wage loss plan benefits recevied" value={payload?.OEI_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_009" subTitle="Less: Contribution made to the plan" value={payload?.OEI_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_010" subTitle="Net wage loss benefits " value={payload?.OEI_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_011" subTitle="Wage loss replacement contribution which are included on box 14 of your T4 slips" value={payload?.OEI_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b title_name="OEI_012_a" name="OEI_012_b" title_value={payload?.OEI_012_a} value={payload?.OEI_012_b} hint='' 
                                        handleChange={(name, value) => {handleChange(name, value)}}
                                        title_handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OEI_total" subTitle="Line 10400: Other employment income - total" value={payload?.OEI_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 11500 Other pensions or superannuation">
                                    {window.innerWidth < 340 ? <> <br /><br/></> : ""}
                                    
                                    <Form_UI_a name="OPS_001" subTitle="Pension Income from supplementaries: T3, T5, T4A, T4RIF, NR4" value={payload?.OPS_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OPS_002" subTitle="Income transferred from your spouse according to his or her T2205" value={payload?.OPS_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OPS_003" subTitle="Foreign Pension Income" value={payload?.OPS_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OPS_004" subTitle="Revenue from United States individual retitrement account (IRA)" value={payload?.OPS_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b title_name="OPS_005_a" input_name="OPS_005_b" title_value={payload?.OPS_005_a} value={payload?.OPS_005_b} hint='' 
                                        handleChange={(name, value) => {handleChange(name, value)}}
                                        title_handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OPS_total" subTitle="Line 11500: Other pension and superannuation income - total" value={payload?.OPS_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 12800 Support payments received">
                                    {window.innerWidth < 550 ? <> <br /></> : ""}
                                    
                                    {window.innerWidth < 800 ? "" :
                                    <div className="flex w-100 gap-1">
                                        <p className="w-100 " >First Name</p>
                                        <p className="w-100 " >Name</p>
                                        <p className="w-100 " >Received</p>
                                        <p className="w-100 " >Taxable</p>
                                    </div>
                                    }
                                    <Form_UI_c 
                                        first_name="SPR_A_001"
                                        name="SPR_A_002"
                                        received="SPR_A_003"
                                        taxable="SPR_A_004"
                                        
                                        fn_value={payload?.SPR_A_001} 
                                        n_value={payload?.SPR_A_002} 
                                        r_value={payload?.SPR_A_003} 
                                        t_value={payload?.SPR_A_004} 
                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_c 
                                        first_name="SPR_B_001"
                                        name="SPR_B_002"
                                        received="SPR_B_003"
                                        taxable="SPR_B_004"  
                                    
                                        fn_value={payload?.SPR_B_001} 
                                        n_value={payload?.SPR_B_002} 
                                        r_value={payload?.SPR_B_003} 
                                        t_value={payload?.SPR_B_004} 
                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_c
                                        first_name="SPR_C_001"
                                        name="SPR_C_002"
                                        received="SPR_C_003"
                                        taxable="SPR_C_004"  
                                        
                                        fn_value={payload?.SPR_C_001} 
                                        n_value={payload?.SPR_C_002} 
                                        r_value={payload?.SPR_C_003} 
                                        t_value={payload?.SPR_C_004}
                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_c 
                                        first_name="SPR_D_001"
                                        name="SPR_D_002"
                                        received="SPR_D_003"
                                        taxable="SPR_D_004"  
                                        
                                        fn_value={payload?.SPR_D_001} 
                                        n_value={payload?.SPR_D_002} 
                                        r_value={payload?.SPR_D_003} 
                                        t_value={payload?.SPR_D_004}
                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_c 
                                        first_name="SPR_E_001"
                                        name="SPR_E_002"
                                        received="SPR_E_003"
                                        taxable="SPR_E_004"  
                                        
                                        fn_value={payload?.SPR_E_001} 
                                        n_value={payload?.SPR_E_002} 
                                        r_value={payload?.SPR_E_003} 
                                        t_value={payload?.SPR_E_004}
                                        hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <div className="flex flex-centered w-100 gap-1">
                                        {window.innerWidth < 800 ? 
                                            <div className="w-100 flex flex-column">
                                                <MaterialContainer heading="Total">
                                                    <div className="flex flex-column">
                                                        <p className="w-100 " >Received Total</p>
                                                        <Input type='text' name="received_total" value={payload?.received_total} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                                    </div>
                                                    <div className="flex flex-column">
                                                        <p className="w-100 " >Taxable Total</p>
                                                        <Input type='text' name="taxable_total" value={payload?.taxable_total} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                                    </div>
                                                </MaterialContainer>
                                                
                                            </div>
                                        : <>
                                            <p className="w-100 " ></p>
                                            <p className="w-100 flex-centered" >Total</p>
                                            <Input type='text' name="received_total" value={payload?.received_total} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                            <Input type='text' name="taxable_total" value={payload?.taxable_total} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                        </>
                                        }
                                    </div>
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 13000 Other Income">
                                    {window.innerWidth < 340 ? <> <br /></> : ""}
                                    
                                    <Form_UI_a name="OI_001" subTitle="Other income from T3, T4PS, T5, NR4, T4A, T4ARCA, T4RIF and T1204" value={payload?.OI_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_002" subTitle="Eligible and Non-eligible retirement allowances from T4 (Boxes 66 and 67)" value={payload?.OI_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_003" subTitle="Income transferred from your spouse according to his or her T2205" value={payload?.OI_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_004" subTitle="Income from foreign sources" value={payload?.OI_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_005" subTitle="Other property income to be reported at line 11 of T936, CNIL" value={payload?.OI_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_006" subTitle="Recovery of exploration and development expenses  (see line 12 of T936)" value={payload?.OI_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_007" subTitle="Form  AGR-1 Box 18, if positive" value={payload?.OI_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b input_title_name="OI_008_a" input_name="OI_008_b" title_value={payload?.OI_008_a} value={payload?.OI_008_b} hint='' 
                                        handleChange={(name, value) => {handleChange(name, value)}}
                                        title_handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_009" subTitle="T4A(P) Box 18" value={payload?.OI_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_010" subTitle="T4A Box 197 Canada Emergency Response Benefit CERB" value={payload?.OI_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_011" subTitle="T4A Box 198 Canada Emergency Student Benefit CESB" value={payload?.OI_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_012" subTitle="T4A Box 199 CESB for elegible students with disabilities or those with children or other dependents" value={payload?.OI_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_013" subTitle="T4A Box 200 Provincial/Territorial COVID-19 financial assistance payments" value={payload?.OI_013} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_014" subTitle="T4A Box 202 Canada Recovery Benefit CRB" value={payload?.OI_014} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_015" subTitle="T4A Box 203 Canada Recovery Sickness Benefit CRSB" value={payload?.OI_015} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_016" subTitle="T4A Box 204 Canada Recovery Caregiving Benefit CRCB" value={payload?.OI_016} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_017" subTitle="T4A Box 205 one time paymnets for older seniors" value={payload?.OI_017} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_018" subTitle="T4A Box 211 Canada Worker Lockdown Benefit (CWLB)" value={payload?.OI_018} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_019" subTitle="All exempt benefits which were calculated based on tax-exempt employment or self-employment income" value={payload?.OI_019} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_020" subTitle="Prior year's partner GST rebate (GST-370)" value={payload?.OI_020} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_021" subTitle="Prior year's partner QST rebate (VD-358)" value={payload?.OI_021} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_022" subTitle="Prior year's Yukon Buisness carbon price Rebate (YT479)" value={payload?.OI_022} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="OI_total" subTitle="Line 13000: Other income - total" value={payload?.OI_total} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Taxable Scholarship income and research grants">
                                    {window.innerWidth < 550 ? <> <br /></> : ""}
                                    {window.innerWidth < 340 ? <> <br /><br /></> : ""}

                                    <Form_UI_a name="TSIRG_001" subTitle="Scholarship, bursaries, prizes etc. (See T4A)" value={payload?.TSIRG_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Eligible death benefits">
                                    {window.innerWidth < 340 ? <> <br /></> : ""}
                                    
                                    <Form_UI_a name="EDB_001" subTitle="Total death received in 2022" value={payload?.EDB_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="EDB_002" subTitle="Maximum death benefits that can be exempted from income" value={payload?.EDB_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="EDB_003" subTitle="Exemption permissible in 2022" value={payload?.EDB_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="EDB_004" subTitle="Unexempted death benefits to be reported as income at line 13000" value={payload?.EDB_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Business Income">
                                    
                                    <Form_UI_a name="BI_001" subTitle="Buisness Income reported on T3 by member of communal organization" value={payload?.BI_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="BI_002" subTitle="Foreign Buisness Income (T3 and other foreign source buisness income)" value={payload?.BI_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="BI_003" subTitle="Taxes with held on foreign buisness income" value={payload?.BI_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="BI_004" subTitle="Net Buisness income from T2125 or T1139, less tax shelter losses" value={payload?.BI_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="BI_005" subTitle="Net Buisness income from active partnership, T5013s" value={payload?.BI_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="BI_total" subTitle="Line 13500: Net business income - total"  value={payload?.BI_total} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                                                    
                                </MaterialContainer>
                                <br />
                                <br />
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_IWS_AddNew;