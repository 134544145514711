import { NavLink, Outlet } from "react-router-dom";

const FormsOutlet=()=>{
    return <>
    {/* <NavLink to=''>Filled Forms | </NavLink>
    <NavLink to='blank-list'>Blank list | </NavLink>
    <NavLink to='F_T4/add-new'>Add new T4 | </NavLink>
    <NavLink to='F_T3/add-new'>Add new T3 | </NavLink>
    <NavLink to='F_T4A_OAS/add-new'>Add new T4A(OAS) | </NavLink>
    <NavLink to='F_T2202/add-new'>Add new T2202 | </NavLink>
    <NavLink to='F_T4A_P/add-new'>Add new T4A(P) | </NavLink>
    <NavLink to='F_T4A_RCA/add-new'>Add new T4A(RCA) | </NavLink>
    <NavLink to='F_T4E/add-new'>Add new T4E | </NavLink>
    <NavLink to='F_T4RIF/add-new'>Add new T4RIF | </NavLink>
    <NavLink to='F_T4RSP/add-new'>Add new T4RSP | </NavLink>
    <NavLink to='F_T4PS/add-new'>Add new T4PS | </NavLink>
    <NavLink to='F_T5/add-new'>Add new T5 | </NavLink>
    <NavLink to='F_T5013/add-new'>Add new T5013 | </NavLink>
    <NavLink to='F_T5003/add-new'>Add new T5003 | </NavLink>
    <NavLink to='F_T5008/add-new'>Add new T5008 | </NavLink>
    <NavLink to='F_T5007/add-new'>Add new T5007 | </NavLink> */}
    <Outlet/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    </>
  }
  
  export default FormsOutlet;