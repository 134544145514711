import React, { useEffect, useState } from 'react'
import masterService from '../../services/masterService'
import Button from '../../commonComponent/button/Button'
import Checkbox from '../../commonComponent/checkboxInput/Checkbox'
import MaterialDropdown from '../../commonComponent/dropdown/MaterialDropdown'
import Input from '../../commonComponent/input/Input'
import MaterialContainer from '../../commonComponent/materialContainer/MaterialContainer'
import RadioInput from '../../commonComponent/RadioInput/RadioInput'
import { useNavigate } from 'react-router-dom'
import LayoutSteps from '../LayoutSteps'
import formsService from "../../services/formsService";
import Alert from '../../commonComponent/Alerts'
import { useSelector } from 'react-redux'

function Status() {
  const navigate=useNavigate()
  const [provincesList, setProvincesList] = useState([]);
  const [residencyList, setResidencyList] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitError, setSubmitError] = useState("");
  const stepsData = useSelector((state) => state.step.stepsData);
  useEffect(() => {

    //---fetch Provinces
    masterService.getProvinces()
            .then((res) => {
                setProvincesList(res.data.response.list);
            })
            .catch((error) => {
                console.log(error.message)
            });
    masterService.getResidency()
        .then((res) => {
            setResidencyList(res.data.response.list);
        })
        .catch((error) => {
            console.log(error.message)
        });
  }, [])

  const [payload,setPayload]=useState({})

  useEffect(() => {
    formsService
      .getDetails({ form: "PersonalinfoStatus" })
      .then(async (res) => {
        setPayload(res?.data?.response?.form_data);
      })
      .catch((error) => {
        console.log("errors ", error);
      });
  }, []);
  
  console.log('payload',payload)

  const handleSubmit = (e) => {
    e.preventDefault();

    formsService
      .update(
        {
          form: "PersonalinfoStatus",
          form_data: {
            ...payload,
            // ...additionalFieldsFinal
          },
        }
      )
      .then((res) => {
        //---alert after successful submission
        //Alert.success(res?.data?.message);
        //---redirect to details page of current submissions
        navigate('/user/return-YR22/step/qna')
      })
      .catch((error) => {
        setErrors(error?.response?.data?.errors);
        setSubmitError(error?.response?.data?.message);
      });
  };

  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
    console.log(name, value);
  };
  console.log('payload',payload)
  

  return (
    <LayoutSteps
        title="Taxpayer Status"
        actionBack={{action:()=> (stepsData.maritalStatus==1 || stepsData.maritalStatus==2)?navigate("/user/return-YR22/step/spouse"):navigate("/user/return-YR22/step/tax-payer")}}
        actionNext={{action:handleSubmit}}
      >
    <div className='container' style={{minWidth:1000}}>
        <div className='form-div flex flex-column gap-1'>
          <MaterialContainer >
            <div className='inner-group flex flex-column gap-1 w-50'>
              <MaterialDropdown 
                lable="Province of Return" 
                name='province_of_return'
                value={payload?.province_of_return} 
                visible={payload.province_of_return == "0" || payload.province_of_return == null ? true : false}
                options={{list: provincesList, name:'name',value:'id'}}
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
            <div className='inner-group flex flex-column gap-1 w-50'>
            <MaterialDropdown 
              lable="Residency Status" 
              name='residency_status' 
              value={payload?.residency_status} 
              visible={payload.residency_status == "0" || payload.residency_status == null ? true : false}
              options={{list: residencyList, name:'name',value:'id'}}
              handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
          </MaterialContainer>

          <MaterialContainer>
            <div className='inner-group flex-between gap-2 w-100'>
              <Checkbox 
                lable="Spouse is a non-resident" 
                name='spouse_non_resident' 
                value={payload?.spouse_non_resident}
                handleChange={(name, value) => { handleChange(name, value)}}/>
              <div className='flex-centered w-100'>
                <Checkbox 
                  lable="Bankrupt - date" 
                  name='bankrupt' 
                  value={payload?.bankrupt}
                  handleChange={(name, value) => { handleChange(name, value)}}/>
                
                <Input 
                  type="date" 
                  lable="" 
                  name='bankrupt_date' 
                  value={payload?.bankrupt_date}
                  handleChange={(name, value) => { handleChange(name, value)}}/>
              </div>

              <Checkbox 
                lable="Discount Return" 
                name='discount_return' 
                value={payload?.discount_return}
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
          </MaterialContainer>

          <MaterialContainer heading="Disabilities">
            <div className='inner-group flex-between'>
              <Checkbox 
                lable="Taxpayer is disabled" 
                name='taxpayer_disabled' 
                value={payload?.taxpayer_disabled}
                handleChange={(name, value) => { handleChange(name, value)}}/>
              <Checkbox 
                lable="Taxpayer's T2201 approvved by CRA" 
                name='taxpayer_t2201_approved_by_CRA' 
                value={payload?.taxpayer_t2201_approved_by_CRA}
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
            <div className='inner-group flex-between'>
              <Checkbox 
                lable="Spouse is disabled" 
                name='spouse_disabled' 
                value={payload?.spouse_disabled}
                handleChange={(name, value) => { handleChange(name, value)}}/>
              <Checkbox 
                lable="Spouse's T2201 approvved by CRA" 
                name='spouse_t2201_approved_by_CRA' 
                value={payload?.spouse_t2201_approved_by_CRA}
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
          </MaterialContainer>

          <MaterialContainer heading="Quebec Disability">
            <div className='inner-group flex-between'>
              <Checkbox 
                lable="Taxpayer is disabled (For Quebec)" 
                name='taxpayer_disabled_quebec' 
                value={payload?.taxpayer_disabled_quebec}
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
            <div className='inner-group flex-between'>
              <Checkbox 
                lable="Spouse is disabled (For Quebec)" 
                name='spouse_disabled_quebec' 
                value={payload?.spouse_disabled_quebec}
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
          </MaterialContainer>

          <MaterialContainer heading="Only for Quebec residents">
            <div className='flex flex-column gap-1'>
              <p><b>Gender</b></p>
              <div className='flex'>
                <RadioInput 
                  RadioButtons={[
                    {
                      label: "Male",
                      value: payload?.only_for_quebec_resident,
                      name: 'only_for_quebec_resident',
                      handleChange: (name, value) => handleChange(name, value),
                    },
                    {
                      label: "Female",
                      value: payload?.only_for_quebec_resident,
                      name: 'only_for_quebec_resident',
                      handleChange: (name, value) => handleChange(name, value),
                    },
                  ]}
                />
              </div>
            </div>
            
            {/* <div className='inner-group flex '>
              <RadioInput 
                lable="Male" 
                value={payload?.only_for_quebec_resident} 
                name='only_for_quebec_resident' 
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
            <div className='inner-group flex'>
              <RadioInput 
                lable="Female" 
                value={payload?.only_for_quebec_resident} 
                name='only_for_quebec_resident' 
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
             */}
          </MaterialContainer>

          <MaterialContainer >
            <div className='inner-group flex-between'>
              <Checkbox 
                lable="Deceased - date" 
                name='deceased_checkbox' 
                value={payload?.deceased_checkbox}
                handleChange={(name, value) => { handleChange(name, value)}}/>
              
              <Input 
                type="date" 
                lable="" 
                name='deceased_date' 
                value={payload?.deceased_date}
                handleChange={(name, value) => { handleChange(name, value)}}/>
              <Checkbox 
                lable="is this tax return elective" 
                name='is_this_tax_return_elective' 
                value={payload?.is_this_tax_return_elective}
                handleChange={(name, value) => { handleChange(name, value)}}/>
            </div>
          </MaterialContainer>
          
          <div className='form-btn-group flex-centered gap-1'>
            <div className="form-group-div flex flex-centered error-div">
                  {submitError}
                </div>
            {/* <Button varient='contained' name='saveBack' myClass='btn-sec' title='Save'/> */}
            {/* <Button varient='contained' name='saveAdd'  title='Save & Add New'/> */}
          </div>
        </div>
      </div>
      </LayoutSteps>
  )
}

export default Status