import React from 'react';
import './headingA/headingA.css';
const HeadingA=(props)=>{

    return (
    <>
    <div style={headContStyle}>
        
        <div id='heading' style={mystyle} className={props.className}>
            <h1  style={{fontWeight:'bolder',textAlign:'left', fontSize:'1.5em', color:'var(--sec-color)'}}>{props.title} </h1>
            
            {window.innerWidth>790 ? <div style={Diversion}><span>|</span></div>: ""}
            
            {
                props.subtitle!=''?<h3  style={{fontWeight:'normal',textAlign:'center'}}> {props.subtitle}</h3>:''
            }
        
        </div>
        <hr style={hrStyle}/>
    </div>
        
    </>
    )

}

const headContStyle = {
    position : 'relative',
}

const mystyle = {
    color: "black",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    gap: "1em",

    marginTop: "-1.5em",
    // backgroundColor: "var(--border)",
    padding: "0",
    // boxShadow: " 0 0 30px 5px rgba(51, 184, 201, 0.15) inset",
    fontFamily: "poppins",
    
}

const Diversion ={
    fontSize: "3em",
    fontWeight: "100",
    color: "var(--sec-color)",
}

const hrStyle ={
    marginBottom: "1em",
    marginLeft: "-0.5em",
    marginRight: "-0.5em",
}

HeadingA.defaultProps={
    title:'Heading',
    subtitle:''
}
export default HeadingA;