import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import formsService from '../../../services/formsService'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate,useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import AdditionalField from "../../../commonComponent/AdditionalFields";
import LayoutSteps from "../../LayoutSteps";
import InputAmount from "../../../commonComponent/input/InputAmount";


const F_T5_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const [defaultOptions, setDefaultOptions] = useState([])
    const [adFields, setAdFields] = useState([])
    const navigate=useNavigate();

    const {id}=useParams();
        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);



        useEffect(() => {

            formsService.getAdditionalFields({ form: 'F_T5' })
                .then(async (res) => {
                    if (res?.data?.response?.list) {
                        setDefaultOptions(Object.keys(res?.data?.response?.list).map((itm) => { return { name: itm } }));
                    }
                })
                .catch((error) => {
                    console.log('errors ', error)
                });
        }, [])
        useEffect(() => {
            if(id!==undefined){
                formsService.getDetails({form: 'F_T5', id: id})
            .then( async(res) => {
                let savedData = res?.data?.response?.form_data

                const exAdfields = []
                const payloadRaw = {}
                const defaultOptionsArray = defaultOptions.map((itm) => itm.name)
                Object.keys(savedData).forEach((itm, index) => {
                    if (defaultOptionsArray.includes(itm)) {
                        exAdfields.push({ name: itm, value: savedData[itm] })
                    } else {
                        payloadRaw[itm] = savedData[itm];
                    }
                })
                setAdFields(exAdfields)
                setPayload(payloadRaw)
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
        }, [defaultOptions])
        const handleChange=(name,value)=>{
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            

            const additionalFieldsFinal = {}
            adFields.map((itm) => {
                additionalFieldsFinal[itm.name] = itm.value
            })

            if(id===undefined){
                formsService.addNew({form: 'F_T5', form_data: {
                    ...payload,
                    ...additionalFieldsFinal
                }})
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T5/view/'+res?.data?.response?.new_id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }else{
                formsService.update({form: 'F_T5', form_data: {
                    ...payload,
                    ...additionalFieldsFinal
                }},id)
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T5/view/'+id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }
            
        }

    return <LayoutSteps
    title="T5 | Statement of Investment"
    actionBack={{action:()=>navigate("/user/return-YR22/step/F_T4")}}
    actionNext={{action:()=>navigate("/user/return-YR22/step/F_T1")}}
  >
            <div className='form-container'>
                
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group flex flex-column '>
                            {/* <HeadingA title="T5" subtitle='Statement of Investment' /> */}
                            
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <InputAmount 
                                        error={errors?.box_24} 
                                        box="24" 
                                        lable="Actual amount of eligible dividends" 
                                        type='text' 
                                        name='box_24' 
                                        value={payload?.box_24} 
                                        hint='' 
                                        handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <InputAmount 
                                        error={errors?.box_25} 
                                        box="25" 
                                        disabled 
                                        lable="Taxable amount of eligible dividends" 
                                        type='text' 
                                        name='box_25' 
                                        value={payload?.box_25} 
                                        hint='' 
                                        handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                <div className='input-div'>
            
                                    <InputAmount 
                                        error={errors?.box_26} 
                                        box="26" 
                                        disabled 
                                        lable="Dividend tax credit for eligible dividends" 
                                        type='text' 
                                        name='box_26' 
                                        value={payload?.box_26} 
                                        hint='' 
                                        handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <InputAmount 
                                        error={errors?.box_10} 
                                        box="10"  
                                        lable="Actual amount of dividends other than eligible dividends" 
                                        type='text' 
                                        name='box_10' 
                                        value={payload?.box_10} 
                                        hint='' 
                                        handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <InputAmount 
                                        error={errors?.box_11} 
                                        box="11" 
                                        disabled 
                                        lable="Taxable amount of dividends other than eligible dividends" 
                                        type='text' 
                                        name='box_11' 
                                        value={payload?.box_11} 
                                        hint="" 
                                        handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <InputAmount 
                                        error={errors?.box_12} 
                                        box="12" 
                                        disabled 
                                        lable="Dividend tax credit for dividends other than eligible dividends" 
                                        type='text' 
                                        name='box_12' 
                                        value={payload?.box_12} 
                                        hint="" 
                                        handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <InputAmount 
                                        error={errors?.box_13} 
                                        box="13" 
                                        lable="Interest from Canadian sources" 
                                        type='text' 
                                        name='box_13' 
                                        value={payload?.box_13} 
                                        hint='' 
                                        handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <InputAmount 
                                        error={errors?.box_18} 
                                        box="18" 
                                        lable="Capital gains dividends" 
                                        type='text' 
                                        name='box_18' 
                                        value={payload?.box_18} 
                                        hint='' 
                                        handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                
                            </div>
                            <AdditionalField defaultOptions={defaultOptions} adFields={adFields} selectedOptions={[{ name: 'box_80', value: '80' }, { name: 'box_81', value: '81' }]} setAdFields={setAdFields} />
                            
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            {/* <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div> */}
                            
                        </div>
                    </form>
                </div>
            </div>
    </LayoutSteps>
}

export default F_T5_AddNew;