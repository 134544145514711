import { configureStore } from '@reduxjs/toolkit'
import authSlice from './auth/authSlice'
import stepSlice from './yr22/stepSlice'
// import counterReducer from './counter/counterSlice'
// import enquiriesReducer from './enquiries/enquiriesSlice'

export const store = configureStore({
  reducer: {
    auth:authSlice,
    step:stepSlice
  },
})