import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import Dropdown from "../../../commonComponent/dropdown/Dropdown";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.div_1 ? 
                <div className="w-20 flex-centered">
                    <p>{props.div_1}</p>
                </div>
                :""
                }
                {props.div_2 ? 
                <div style={{minWidth: '5%'}} className=" flex-centered">
                    <p>{props.div_2}</p>
                </div>
                :""
                }
                {props.input == 'yes' ? 
                <div  className="flex"  style={inputStyle}>
                    <Input input={props.input} type={props.type} name={props.name_b} value={props?.value2} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.div_3 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3}</p>
                </div>
                :""
                }
                
                <div  className="flex"  style={inputStyle}>
                {props.input1 == 'yes' ? 
                    <Input input={props.input1} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    :""
                }
                </div>
                
                {props.div_3_a ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3_a}</p>
                </div>
                :""
                }
                
                <div  className="flex"  style={inputStyle}>
                    {props.input2 == 'yes' ?
                        <Input input={props.input2} type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        :""
                    }
                </div>
                
                {props.div_4 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_4}</p>
                </div>
                :""
                }
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}
const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        justifyContent: window.innerWidth < 500 ? 'flex-start' : 'flex-end',

    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div style={checkBoxStyle} className="flex">
                    <Checkbox name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const F_Fed_WS_AddNew=()=>{

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_RRSP'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)


    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_RRSP_WS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_RRSP_WS/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_RRSP_WS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_RRSP_WS/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="Federal Worksheet" subtitle="" />
                            <p>Use the following charts to make your claculations according to the line instructions contained in your General Income Tax and Benefit Guide. Keep this worksheet for your records. Do not attach it to the return you send us.</p>
                            
                            <br />
                            
                            <div className='form-group-div flex-column'>
                                <MaterialContainer heading="Line 23500 - Social benefits repayment">

                                    <Form_UI_a name="SBR_001" subTitle="Amount from line 11300 of your return" input2="yes" div_4="1" value={payload?.SBR_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_002" subTitle="Amount from line 14600 of your return" input2="yes" div_3="+" div_4="2" value={payload?.SBR_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_003" subTitle="Add lines 1 and 2" input2="yes" div_3="=" div_4="3" value={payload?.SBR_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_004" subTitle="Overpayment of Old Age Security benefits recovered (box 20 of your T4A(OAS) slip)" input2="yes" div_3="-" div_4="4" value={payload?.SBR_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_005" subTitle="Line 3 minus line 4 (if negative, enter '0')" input2="yes" div_3="=" div_4="5" value={payload?.SBR_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_006" subTitle="Amount from line 23400 of your return" input2="yes" div_4="6" value={payload?.SBR_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <br />
                                    
                                    <Form_UI_a name_a="SBR_007" subTitle="El benefits repayment from line 4 of the chart on your T4E slip (if any)" input1="yes" div_3=" " div_3_a=" " div_4="7" value1={payload?.SBR_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="SBR_008" subTitle="Universal Child Care Benefits (UCCB) (line 11700 of your return)" input1="yes" div_3="+" div_3_a=" " div_4="8" value1={payload?.SBR_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="SBR_009" subTitle="Register disability savings plan (RDSP) income (line 12500 of your return)" input1="yes" div_3="+" div_3_a=" " div_4="9" value1={payload?.SBR_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="SBR_010" name="SBR_010_a" subTitle="Add lines 7, 8 and 9" div_3="=" input1="yes"  div_3_a="-" input2="yes" div_4="10" value1={payload?.SBR_010} value={payload?.SBR_010_a} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_011" subTitle="Line 6 minus line 10" input2="yes" div_3="="  div_4="11" value={payload?.SBR_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="SBR_012" subTitle="Universal Child Care Benefit repayment (line 21300 of your return)" input1="yes" div_3="+" div_3_a=" " div_4="12" value1={payload?.SBR_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="SBR_013" subTitle="RDSP income repayment (included in the amount at line 23200 of your return)" input1="yes" div_3="+" div_3_a=" " div_4="13" value1={payload?.SBR_013} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="SBR_014" name="SBR_14_a" subTitle="Add lines 12 and 13." input1="yes" div_3="=" input2="yes" div_3_a="+" div_4="14" value1={payload?.SBR_014} value={payload?.SBR_014_a} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_015" subTitle="Add lines 11 and 14." input2="yes" div_3="=" div_4="15" value={payload?.SBR_015} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_016" subTitle="Base amount" input2="yes" div_3="=" div_4="16" value={payload?.SBR_016} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_017" subTitle="Line 15 minus line 16 (if negative, enter '0')" input2="yes" div_3="=" div_4="17" value1={payload?.SBR_017} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_018" subTitle="Multiply the amount on line 17 by 15%." input2="yes" div_3="=" div_4="18" value1={payload?.SBR_018} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_019" subTitle="Enter the amount from line 5 or line 18. whichever is less." input2="yes" div_3=" " div_4="19" value1={payload?.SBR_019} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_020" subTitle="Enter the amount from line 7 above (if any)" input2="yes" div_3="+" div_4="20" value1={payload?.SBR_020} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="SBR_021" title="Add lines 19 and 20" subTitle="Enter this amount on lines23500 and 42200 of your return." input2="yes" div_3="=" div_4="21" value1={payload?.SBR_021} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 30000 - Basic Personal Amount">
                                    
                                    <p>If your net income at line 23600 of your return is more than $155625, but less than $221,708, complete thr following calculation to determine how much to claim on line 30000 of your return, Otherwise, follow the instructions above line 30000 of your return</p>
                                    
                                    <Form_UI_a name_a="BPA_001" subTitle="Minimum Amount" input1="" input2="yes" div_3="" div_4="1" div_3_a=" " value1={payload?.BPA_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_002" subTitle="Additional Amount" input1="yes" input2="" div_3=""  div_4="2" div_3_a=" " value1={payload?.BPA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_003" subTitle="Your net income from line 23600 of your return" input="yes" input2="" div_2=" " div_3_a=" " div_3=" " div_4="3" value1={payload?.BPA_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_004" subTitle="Base Amount" input="yes" input2="" div_2="-" div_3=" " div_4="4" div_3_a=" " value1={payload?.BPA_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_005" subTitle="Line 3 minus line 4 (if negative, enter '0')" input="yes" input2="" div_2="=" div_3_a=" " div_3=" " div_4="5" value1={payload?.BPA_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_006" subTitle="Line 5 divided by $66,083" input="yes" input2="" div_2="=" div_3=" " div_4="6" div_3_a=" " value1={payload?.BPA_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_007" subTitle="Multiply line 6 by $1,679" input="yes" input1="yes" div_2="=" div_3=">-" div_3_a=" " div_4="7" value1={payload?.BPA_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_008" subTitle="Line 2 minus line 7" input1="yes" input2="yes" div_3_a=">" div_3="=" div_4="8" value1={payload?.BPA_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_009" subTitle="Add lines 1 and 8" input1="" input2="yes" div_3_a="+" div_3=" " div_4="9" value1={payload?.BPA_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="BPA_010" subTitle="Enter this amount on line 30000 of your return   (max $14,398)" input1="" input2="yes" div_3_a="=" div_3=" " div_4=" " value1={payload?.BPA_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 30100 - Age Amount">
                                    
                                    <p>If your net income at line 23600 of your return is $39,826 or less, enter $7,898 on line 30100 of your return.</p>
                                    <p>If your net income is $92,479 or more, enter "0" on line 30100.</p>
                                    <p>Otherwise, complete the following calculation to determine how much to claim on line 30100.</p>
                                    
                                    <Form_UI_a name_a="AA_001" subTitle="Maximum claim" input1="" input2="yes" div_3_a=" " div_3=" " div_4="1" value1={payload?.AA_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="AA_002" subTitle="Your net income from line 23600 of your return" input1="yes" input2="" div_3_a=" " div_3=" " div_4="2" value1={payload?.AA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="AA_003" subTitle="Base Amount" input1="yes" input2="" div_3_a=" " div_3="-" div_4="3" value1={payload?.AA_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="AA_004" subTitle="Line 2 minus line 3 (if negative, enter '0')" input1="yes" input2="" div_3_a=" " div_3="=" div_4="4" value1={payload?.AA_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="AA_005" subTitle="Multiply the amount on line 4 by 15%" input1="" input2="yes" div_3_a="-" div_3=" " div_4="5" value1={payload?.AA_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="AA_006" subTitle="Line 1 minus line 5 (if negative, enter '0'). Enter this amount on line 30100 of Schedule 1." input1="" input2="yes" div_3_a="=" div_3=" " div_4="6" value1={payload?.AA_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 31400 - Pension income amount">
                                    
                                    <Form_UI_a name_a="PIA_001" subTitle="Amount from line 11500 of your return" input1="" input2="yes" div_3_a=" " div_3=" " div_4="1" value1={payload?.PIA_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_002" subTitle="Foreign pension income included in the amount on line 11500 of your return and deducted on line 25600 of your return" input1="yes" input2="" div_3_a=" " div_3=" " div_4="2" value1={payload?.PIA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_003" subTitle="Income from a U.S. individual retirement account (IRA) included in the amount on line 11500 of your return" input1="yes" input2="" div_3_a=" " div_3="+" div_4="3" value1={payload?.PIA_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_004" subTitle="Amounts from a RRIF included on line 11500 and transferred to an RRSP, another RRIF, or an annuity" input1="yes" input2="" div_3_a=" " div_3="+" div_4="4" value1={payload?.PIA_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_005" subTitle="Add lines 2,3 or 4." input1="yes" input2="yes" div_3_a="-" div_3="=" div_4="5" value1={payload?.PIA_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_006" subTitle="Line 1 minus line 5" input1="" input2="yes" div_3_a="=" div_3=" " div_4="6" value1={payload?.PIA_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_007" subTitle="Annuity payments from line 12900 of your return (box 16 of all your T4RSP slips) only if you were 65 years of age or older on December 31, 2022, or you received the payments because of the death of your spouse or common-law partner" input1="" input2="yes" div_3_a="+" div_3=" " div_4="7" value1={payload?.PIA_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_008" subTitle="Line 6 plus line 7" input1="" input2="yes" div_3_a="=" div_3=" " div_4="A" value1={payload?.PIA_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_009" subTitle="Amount transferred form spouse (Pension Split)" input1="" input2="yes" div_3_a="+" div_3=" " div_4="8" value1={payload?.PIA_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_010" subTitle="Amount transferred to spouse (Pension Split)" input1="" input2="yes" div_3_a="-" div_3=" " div_4="9" value1={payload?.PIA_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_011" subTitle="Income Eligible for pension amount " input1="" input2="yes" div_3_a="" div_3=" " div_4="10" value1={payload?.PIA_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="PIA_012" subTitle="Pension income amount" input1="" input2="yes" div_3_a=" " div_3=" " div_4="11" value1={payload?.PIA_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Line 31600 - Disability amount (for self)">
                                    <Form_UI_a name_a="DA_001" subTitle="Maximum supplement" input1="" input2="yes" div_3_a=" " div_3=" " div_4="1" value1={payload?.DA_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="DA_002" subTitle="Total of child care and attendant care expenses for you claimed by you or another person" input1="yes" input2="" div_3_a=" " div_3=" " div_4="2" value1={payload?.DA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="DA_003" subTitle="Base amount" input1="yes" input2="" div_3_a=" " div_3="-" div_4="3" value1={payload?.DA_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="DA_004" subTitle="Line 2 minus line 3 (if negative, enter '0') " input1="yes" input2="yes" div_3_a="-" div_3="=" div_4="4" value1={payload?.DA_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name_a="DA_005" subTitle="Line 1 minus line 4 (if negative, enter '0') " input1="" input2="yes" div_3_a="=" div_3=" " div_4="5" value1={payload?.DA_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <p>Enter, on line 31600 of Schedule 1, $8,870.00 plus the amount on line 5 (maximum claim $14,044.00) unless this chart is being calculation of line 31800.</p>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_Fed_WS_AddNew;