import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";


const F_T4A_OAS_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_T4A_OAS'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)

    // const  handleSubmit=(e)=>{
    //     e.preventDefault();
        
    //     console.log('payload is',payload)
    //     formsService.addNew({form: 'F_T4A_OAS', form_data: payload})
    //     .then( (res) => {
    //         //---alert after successful submission
    //         alert(res?.data?.message)
    //         //---redirect to details page of current submissions
    //         navigate('/user/return-YR22/forms/F_T4A_OAS/view/'+res?.data?.response?.new_id)
    //         // setEmployeeCodesList(res.data.response.list);
    //     })
    //     .catch((error) => {
    //         console.log('errors ',error?.response?.data)
    //         setErrors(error?.response?.data?.errors)
    //         setSubmitError(error?.response?.data?.message)
    //     });
    // }



    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_T4A_OAS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_T4A_OAS/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_T4A_OAS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_T4A_OAS/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="T4A(OAS)" subtitle="Statement of Old Age Security" />
                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>
            
                                    <Input error={errors?.box_18} box="18" lable="Taxable pension paid" type='text' name='box_18' value={payload?.box_18} hint='' required handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_19} box="19" lable="Gross pension paid" type='text' name='box_19' value={payload?.box_19} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_20} box="20" lable="Overpayment recovered" type='text' name='box_20' value={payload?.box_20} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>
            
                                    <Input error={errors?.box_21} box="21" lable="Net supplements paid" type='text' name='box_21' value={payload?.box_21} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_22} box="22" lable="Income Tax deducted" type='text' name='box_22' value={payload?.box_22} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_23} box="23" lable="Quebec income tax deducted" type='text' name='box_23' value={payload?.box_23} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T4A_OAS_AddNew;