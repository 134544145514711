import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'







export const PersonalInfoLayout = () => {
   
    

    return (
        <>
            <div className='personal-info-layout flex-centered w-100'>
                <div className='body flex-column flex-centered w-100'>

                    <div className='body flex flex-column'>
                        <div className='nav-list flex-column'>
                            <ul className='pil-nav-items flex-centered'>
                                <li><NavLink to='tax-payer'>Tax Payer</NavLink></li>
                                <li><NavLink to='spouse'>Spouse</NavLink></li>
                                <li><NavLink to='status'>Status</NavLink></li>
                            </ul>
                            <div className='form-container'>
                                <Outlet />
                            </div>
                            
                        </div>
                        
                    </div>

                    
                </div>
            </div>
            

        </>
    )
}
