import React from 'react'
import './about.css'
import ME from '../../../assets/images/banner3.jpg'
// import DownloadBtn from '../../common_comps/buttons/DownloadBtn'
import Button from '../../commonComponent/button/Button'

const ImgCard = (props)=> {
    
    const aboutImg1 = {
        background: props.img,
    }

    return(
        <>
            <div className='about-svg-container flex gap-1'>
                <div style={aboutImg1} className='flex-column flex-centered'>
                    <p className='p-txt'>Secure Cipher</p>
                </div>
                <div className='flex-column flex-centered'>
                    <div className='icon-div'>
                    </div>
                    <p className='p-txt'>Google Security</p>
                </div>
            </div>
        </>
    )
}

function about() {
  return (
    <>
        
        <div className='about-section container-80 flex-centered'>
            <div className='about-img-div flex flex-column gap-1'>
                <div className='img-div'>
                    <div className='about-img-abs-div flex-centered'>
                        <img src={ME} alt='ME' />
                    </div>
                </div>
                {/* <ImgCard 
                    img="url('../../../assets/images/banner3.jpg')"
                /> */}
                {/* <div className='about-svg-container flex gap-1'>
                        <div className='about-img-div flex-column flex-centered'>
                            <p className='p-txt'>Secure Cipher</p>
                        </div>
                        <div className='flex-column flex-centered'>
                            <div className='icon-div'>
                            </div>
                            <p className='p-txt'>Google Security</p>
                        </div>
                </div>
                <div className='about-svg-container flex gap-1'>
                    <div className='flex-column flex-centered'>
                        <div className='icon-div'>
                        </div>
                        <p className='p-txt'>Facial Recognition</p>
                    </div>
                    <div className='flex-column flex-centered'>
                        <div className='icon-div'>
                        </div>
                        <p className='p-txt'> Touch Authentication</p>
                    </div>
                </div> */}
            </div>
            <div className='about-txt-div flex flex-column '>
                <p className='p-txt'>Who We Are</p>
                <div className='txt-heading'>
                    <h2 className='h2-txt'>We Deal With All Aspects Of The Tax Consulting Service</h2>
                </div>
                <p className='p-txt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore expedita quae possimus nisi natus ratione sunt sapiente reiciendis maiores. Libero illum, adipisci voluptatum, eum doloremque laboriosam mollitia non autem pariatur beatae molestiae, expedita sapiente eaque dolor deserunt! Distinctio ipsa exercitationem suscipit, libero corrupti vel possimus similique, enim et soluta voluptates?</p>
                <div className=''>
                    <Button varient="contained-home" title="Learn more about us"/>
                </div>
                
            </div>
        </div>
    </>
  )
}

export default about