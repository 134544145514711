import React, { useState } from 'react'

import './CheckboxAdvance.css'

function CheckboxAdvance(props) {

    

  return (
    <div className='checkboxAdvanceSection flex-between'>
        <label>{props.title}</label>
        <div className='checkboxes flex '>
            <div className='checkboxDiv flex'>

                <label className='check-label'
                    onClick={()=> props.handleChange(true)}
                    style={{
                        background: props.value ? 'var(--sec-color)': 'transparent',
                        color: props.value ? 'white' : 'black'
                    }}
                >Yes
                    {/* <input 
                        type='checkbox' 
                        name={props.name} 
                        // value={props.value}
                        checked={isChecked} 
                        onChange={(e) => props.handleChange(props.name, e?.target?.checked)}          
                    /> */}
                </label>
                
            </div>

            <div className='checkboxDiv flex'>
                <label className='check-label'
                onClick={()=> props.handleChange(false)}
                style={{
                    background: !props.value ? 'var(--sec-color)': 'transparent',
                    color: !props.value ? 'white' : 'black'
                }}
                >No
                    {/* <input 
                        type='checkbox' 
                        name={props.name} 
                        // value="false" 
                        checked={isChecked2} 
                        onChange={(e) => props.handleChange(props.name, e?.target?.checked)}          
                    /> */}
                </label>
            </div>

        </div>
        
    </div>
  )
}

export default CheckboxAdvance