import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import formsService from '../../../services/formsService'
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";



const F_T4E_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();


        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);
        const {action}=useParams();
        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_T4E'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])
        console.log('paylooad is',payload)
        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            console.log('payload is',payload)
            formsService.update({form: 'F_T4E', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                alert(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4E/view')
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
        }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="T4E" subtitle="Statement of Employment Insurance and Other Benefits" />
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_7} box="7" lable="Repayment rate" type='text' name='box_7' value={payload?.box_7} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_14} box="14" lable="Total benefits paid" type='text' name='box_14' value={payload?.box_14} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_15} box="15" lable="Regular and other benefits paid" type='text' name='box_15' value={payload?.box_15} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_17} box="17" lable="Employment benefits and support measures paid" type='text' name='box_17' value={payload?.box_17} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_20} box="20" lable="Taxable tuition assistance" type='text' name='box_20' value={payload?.box_20} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_21} box="21" lable="Non-taxable tuition assistance" type='text' name='box_21' value={payload?.box_21} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_22} box="22" lable="Income tax deducted" type='text' name='box_22' value={payload?.box_22} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div> 
                                <div className='input-div'>
            
                                    <Input error={errors?.box_23} box="23" lable="Quebec income tax deducted" type='text' name='box_23' value={payload?.box_23} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_12} box="12" lable="Social insurance number" type='text' name='box_12' value={payload?.box_12} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T4E_AddNew;