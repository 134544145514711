import { FaTrashAlt,FaUserAlt } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md'
import Alert from '../Alerts';

export const Trash = (props) => {
  return <span style={{ color: props.color }} onClick={()=>props.onClick}><FaTrashAlt /></span>
}
Trash.defaultProps = {
  color: 'var(--sec-color)'
}


export const Edit = (props) => {
  return <span style={{ color: props.color, fontSize: '1.1em' }}><MdEdit /></span>
}
Edit.defaultProps = {
  color: 'var(--sec-color)'
}  



export const User = (props) => {
  return <span style={{ color: props.color, fontSize: '1.1em' }}><FaUserAlt /></span>
}
Edit.defaultProps = {
  color: 'var(--sec-color)'
}