import { useSelector } from "react-redux"
import Button from "../../../pages/commonComponent/button/Button"
import { useNavigate } from "react-router-dom"

const Start =()=>{
    const navigate =useNavigate()
    const step=useSelector((state)=>state.step)
    return <div>
        <Button title='Start' onClick={()=>navigate('/user/return-YR22/step/tax-payer')} />
    </div>
}

export default Start