import React from 'react'
import { NavLink } from 'react-router-dom'
import './headerBfLog.css'


function HeaderBfLog() {
  return (
    <>
        <div className='' 
        style={{
          background: 'yellow',
          textAlign: 'center',
          color: 'red',
          padding: '1em'
        }}>
            <h1>WEBSITE UNDER CONSTRUCTION</h1>
        </div>
        <div className="header flex-centered">
        
            <div className="inner-div flex-between">
                <div className="logo-div ">
                    <h1>TallyTax</h1>
                </div>
                {/* <div className='nav-menu'>
                  <ul className='flex'>
                    <li><NavLink to="">Home</NavLink></li>
                    <li><NavLink to="/about">About</NavLink></li>
                    <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                  </ul>
                </div> */}

                <div className='nav-menu'>
                  <ul className='flex'>
                    {/* <li><NavLink to="/user/return-YR22/forms">Start Trial</NavLink></li> */}
                    <li><NavLink to="/login">Login</NavLink></li>
                  </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default HeaderBfLog