import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import Dropdown from "../../../commonComponent/dropdown/Dropdown";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    const boxStyle = {
        background: 'black',
        color: 'white',
        padding: '0.2em 0.5em',
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.div_1 ? 
                <div className="w-20 flex-centered">
                    <p>{props.div_1}</p>
                </div>
                :""
                }
                {props.div_2 ? 
                <div style={{minWidth: '5%'}} className=" flex-centered">
                    <p>{props.div_2}</p>
                </div>
                :""
                }
                {props.div_3 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3}</p>
                </div>
                :""
                }
                {props.box && <p style={boxStyle}>{props.box}</p>}
                {props.input1 == 'yes' ? 
                <div  className="flex"  style={inputStyle}>
                    <Input input={props.input1} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.box2 && <p style={boxStyle}>{props.box2}</p>}
                {props.div_3_a ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3_a}</p>
                </div>
                :""
                }
                
                <div  className="flex"  style={inputStyle}>
                    {props.input2 == 'yes' ?
                        <Input input={props.input2} type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        :""
                    }
                </div>
                
                {props.div_4 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_4}</p>
                </div>
                :""
                }
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}
const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        justifyContent: window.innerWidth < 500 ? 'flex-start' : 'flex-end',

    }

    const boxStyle = {
        background: 'black',
        color: 'white',
        padding: '0.2em 0.5em',
    }

    const inputResponsive = {
        minWidth: '20%'
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.box ? <p style={boxStyle}>{props.box}</p> : ''}
                {props?.name &&
                <div style={checkBoxStyle} className="flex">
                
                    <Checkbox name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                
                </div>}
                
                {props.subTitle2 ? <p style={subTitleStyle}>{props.subTitle2}</p> : ''}
                {props.box2 ? <p style={boxStyle}>{props.box2}</p> : ''}
                {props.input == 'yes' ?
                    <div style={inputResponsive}>
                        <Input input={props.input} type={props.type} name={props.Iname} value={props?.Ivalue} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        
                        </div>
                        :""
                        
                    }
            </div>
            <hr />
        </div>
    )
    
}
const Form_UI_c=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        maxWidth: '20%',
    }

    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.input == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <Input input={props.input} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.input1 == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <p>{props.txt1}</p>
                    <Input input={props.input1} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.input2 == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <p>{props.txt2}</p>
                    <Input input={props.input2} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                
                <div style={checkBoxStyle} className="flex-centered flex-column gap-2">
                    <p>{props.txt3}</p>
                    <div className="flex gap-2">
                        <Checkbox name={props.name} value={props?.value_1} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        <Checkbox name={props.name} value={props?.value_2} hint='' lable={props.lable_2} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const F_Sch_2_AddNew=()=>{

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_RRSP'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)


    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_RRSP_WS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_RRSP_WS/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_RRSP_WS', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_RRSP_WS/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="Schedule 5" subtitle="Amounts for Spouse or Common-Law Partner and Dependants" />
                            
                            <br />
                                                        
                            
                            <div className='form-group-div flex-column'>
                                {/* <MaterialContainer heading="Line 30300 - Spouse or common-law partner amount"> */}
                                    <p>Complete this schedule to claim a transfer, from your spouse or common-law partner, the unused part of his or her age amount, amount for children born in 2005 or later, pension income amount, disability amount, and tution, education, and textbook amounts. If your spouse or common-law partner is not filing a return, use the amounts that he or she would enter on his or her retrun, Schedules, and worksheet if filling a return. Attach his or her information slips but do not attach the return or schedules. Attach a copy of this schedule to your return. See line 32600 in the guide for more information.</p>
                                    <br/>
                                    <br/>
                                    <Form_UI_a Iname="sch_2_001" title="Age amount: (if your spouse or common-law partner was 65 years of age or older in 2022)" subTitle="If his or her net income is $39,826.00 or less, enter $7,898.00 otherwise enter his or her amount for line 30100" box2="35200" input2="yes" div_3_a="+" div_4="1" value={payload?.sch_2_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_002" title="" subTitle="Canada caregiver amount for infirm children under 18 years of age Enter the amount from line 30500 of his or her schedule 1" box2="35300" input2="yes" div_3_a="+" div_4="2" value={payload?.sch_2_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_003" title="Pension income amount: " subTitle="Enter his or her amount for line 31400 (maximum $2,000.00)" box2="35500" input2="yes" div_3_a="+" div_4="3" value={payload?.sch_2_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_004" title="Disability Amount:" subTitle="Enter his or her claim for the disability amount (see line 31600 of his or her Schedule 1)" box2="35700" input2="yes" div_3_a="+" div_4="4" value={payload?.sch_2_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_005" title="Tuition amount:" subTitle="Enter the federal amount designated to you as shown on his or her From T2202, TL11A or TL11C" box2="36000" input2="yes" div_3_a="+" div_4="5" value={payload?.sch_2_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_006" title="" subTitle="Add line 1 to 5" input2="yes" div_3_a="=" div_4="6" value={payload?.sch_2_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <br />
                                    <Form_UI_a Iname="sch_2_007" title="" subTitle="Enter the amount from line 26000 of his or her return if it is $50,197.00 or less. Otherwise, complete Part A of Step 5 of their return and enter the result of the following calculation: amount from line 71 in Part A of their return divide by 15%" input1="yes" div_3_a=" " div_4="7" value={payload?.sch_2_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_008" title="Non refundable credits" subTitle="His or her basic personal amount (line 30000)" input1="yes" div_3_a=" " div_4=" " value={payload?.sch_2_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_009" title="" subTitle="His or her CPP-QPP contributions (lines 30800 or 31000)" input1="yes" div_3_a="+" div_4=" " value={payload?.sch_2_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_010" title="" subTitle="His or her EI premiums (line 31200 and line 31217)" input1="yes" div_3_a="+" div_4=" " value={payload?.sch_2_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_011" title="" subTitle="Enter the of lines 31220, 31240, 31260, 31285, 31270, 31300 and 31350 of Schedule 1." input1="yes" div_3_a="+" div_4=" " value={payload?.sch_2_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_012" title="" subTitle="His or her tuition/education amts (line 16 of Schedule 11)" input1="yes" div_3_a="+" div_4=" " value={payload?.sch_2_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_013" title="" subTitle="Total of lines 31205, 31210 and 31215 of Schedule 1" input1="yes" div_3_a="+" div_4=" " value={payload?.sch_2_013} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_014" title="" subTitle="Sub Total" input1="yes" div_3_a=" " div_4="8" value={payload?.sch_2_014} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_015" title="" subTitle="His or her adjusted taxable income: line 7 minus line 8 (if negative, enter '0')" input1="yes" input2="yes" box="36100" div_3_a="-" div_4="9" value={payload?.sch_2_015} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a Iname="sch_2_016" title="" subTitle="Line 6 minus line 9 (if negative, enter '0') Enter this amount on line 32600 of your Schedule 1." box2=" Amounts transferred from your spouse or common-law parter" input2="yes" div_3_a="=" div_4="10" value={payload?.sch_2_016} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                {/* </MaterialContainer> */}
                                <br />
                                <br />
                                
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_Sch_2_AddNew;