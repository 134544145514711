import { getRequest,postRequest } from '../../services/axios/';

/**
 * Api call
 */
class returnService {

    startReturn = async (payload={}) =>{
       return await postRequest('user/return-YR22/create-return',payload);
        }

}

const instance = new returnService();

export default instance;