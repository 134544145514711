import React from 'react'
import './button.css'
function Button(props) {
  let myClass='btn'
  if(props.myClass!==''){

    myClass=myClass+' '+props.myClass;
  }
if(props)

  return (
    <>
        <button btn_size={props.btn_size} type="button" onClick={props.onClick} varient={props.varient} style={props.sty} name={props.name} className={myClass}>{props.title}</button>
    </>
  )
}
Button.defaultProps = {
  varient: "contained",
  title: "Title",
  name: "",
  noHoverEffect:false,
  sty:{},
  myClass:'',
  btn_size:'medium'
  
}
export default Button