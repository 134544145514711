import React, { useState } from 'react'
import './input.css'

function MaterialInput(props) {

  const [isFocused, setIsFocused] = useState(false);
  
  
  
  return (
    <>
      <div className='input-div'>
        <label className='input-lable'>{props.lable}</label>
        <div className='input-container flex'>
          <span className='material-box-span flex-centered' 
            style={
              {
                display: !isFocused && (props?.value == "" || props?.value == null )? 'flex' :'flex',
                color: isFocused ? 'var(--sec-color)':'grey'
              }
            }>{props.box}</span>
          
          <input 
            style={{padding: props.type == 'date' ? '1.2em 1em':'1em 1em'}}
            onFocus={() => setIsFocused(true)}
            onBlur={()=> setIsFocused(false)} 
            className="material-input" 
            type={props.type} 
            
            // placeholder={props.hint} 
            name={props.name} 
            value={props.value} 
            onChange={(e) => props.handleChange(props.name, e?.target?.value)}/>            
        </div>

        <span className='error-txt' style={{color:'red', fontSize: '0.8em' }}>{props.error}</span>
        
      </div>
    </>
  )
}

MaterialInput.defaultProps = {
  type:"text",
  required: false,
}

export default MaterialInput