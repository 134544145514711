import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import AdditionalField from "../../../commonComponent/AdditionalFields";

const F_T4A_AddNew=()=>{


    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const [defaultOptions, setDefaultOptions] = useState([])
    const [adFields, setAdFields] = useState([])

    const navigate=useNavigate();

        const [payload,setPayload]=useState({})
        const {id}=useParams();
        const handleChange=(name,value)=>{
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }

        useEffect(() => {

            formsService.getAdditionalFields({ form: 'F_T4A' })
                .then(async (res) => {
                    if (res?.data?.response?.list) {
                        setDefaultOptions(Object.keys(res?.data?.response?.list).map((itm) => { return { name: itm } }));
                    }
                })
                .catch((error) => {
                    console.log('errors ', error)
                });
        }, [])


        useEffect(() => {
            if(id!==undefined){
                formsService.getDetails({form: 'F_T4A', id: id})
            .then( async(res) => {
                let savedData = res?.data?.response?.form_data

                    const exAdfields = []
                    const payloadRaw = {}
                    const defaultOptionsArray = defaultOptions.map((itm) => itm.name)
                    Object.keys(savedData).forEach((itm, index) => {
                        if (defaultOptionsArray.includes(itm)) {
                            exAdfields.push({ name: itm, value: savedData[itm] })
                        } else {
                            payloadRaw[itm] = savedData[itm];
                        }
                    })
                    setAdFields(exAdfields)
                    setPayload(payloadRaw)
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
        },[defaultOptions])

        const  handleSubmit=(e)=>{
            e.preventDefault();

            const additionalFieldsFinal = {}
        adFields.map((itm) => {
            additionalFieldsFinal[itm.name] = itm.value
        })
            
            if(id===undefined){
                formsService.addNew({
                    form: 'F_T4A', form_data: {
                        ...payload,
                        ...additionalFieldsFinal
                    }
                })
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4A/view/'+res?.data?.response?.new_id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }else{
                formsService.update({form: 'F_T4A', form_data: {
                    ...payload,
                    ...additionalFieldsFinal
                }},id)
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4A/view/'+id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }
            
        }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="T4A" subtitle="Statement of Pension, Retirement Annuity and Other Income" />
                            
                            <div className="form-div-name flex flex-column w-100">
                                <div className='input-div'>
            
                                    <Input error={errors?.payer_name} lable="Payer's Name" type='text' name='payer_name' value={payload?.payer_name} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>
                            {/* <div className="form-group-outer flex flex-column w-100"> */}
                                <div className='form-group-div flex gap-2 w-100'>
                                    <div className='input-div'>
            
                                        <Input error={errors?.box_16} box="16" lable="Pension or superannuation – line 11500" type='text' name='box_16' value={payload?.box_16} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    </div>
                                    <div className='input-div'>
            
                                        <Input error={errors?.box_18} box="18" lable="Lump-sum payments – line 13000" type='text' name='box_18' value={payload?.box_18} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    </div>
                                    <div className='input-div'>
            
                                        <Input error={errors?.box_20} box="20" lable="Self-employed commissions" type='text' name='box_20' value={payload?.box_20} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    </div>
                                </div>
                                <div className='form-group-div flex gap-1 w-100'>
                                    <div className='input-div'>
            
                                        <Input error={errors?.box_22} box="22" lable="Income tax deducted – line 43700" type='text' name='box_22' value={payload?.box_22} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    </div>
                                    <div className='input-div'>
            
                                        <Input error={errors?.box_24} box="24" lable="Annuities" type='text' name='box_24' value={payload?.box_24} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    </div>
                                    
                                    <div className='input-div'>
            
                                        <Input error={errors?.box_48} box="48" lable="Fees for services" type='text' name='box_48' value={payload?.box_48} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    </div>
                                </div>
                            <AdditionalField defaultOptions={defaultOptions} adFields={adFields} selectedOptions={[{ name: 'box_80', value: '80' }, { name: 'box_81', value: '81' }]} setAdFields={setAdFields} />

                            {/* </div> */}

                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>

                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T4A_AddNew;