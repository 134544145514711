import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import formsService from '../../../services/formsService'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import RadioInput from "../../../commonComponent/RadioInput/RadioInput";
import Alert from "../../../commonComponent/Alerts";



const F_T4PS_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {id}=useParams();

        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(id!==undefined){
                formsService.getDetails({form: 'F_T4PS', id: id})
            .then( async(res) => {
                console.log('rrees',res)
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
        }, [])


        const handleChange=(name,value)=>{
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            if(id===undefined){
                formsService.addNew({form: 'F_T4PS', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4PS/view/'+res?.data?.response?.new_id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }else{
                formsService.update({form: 'F_T4PS', form_data: payload},id)
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4PS/view/'+id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }
            
        }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="T4PS" subtitle="Statement of Employees Profit Sharing Plan Allocations and Payments" />
                            <div className='form-div-name flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.emp_name_profit_sharing_plan} lable="Name of employee profit-sharing plan" type='text' name='emp_name_profit_sharing_plan' value={payload?.emp_name_profit_sharing_plan} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_30} box="30" lable="Actual amount of eligible dividends" type='text' hint='' name='box_30' value={payload?.box_30}  handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_31} box="31" lable="Taxable amount of eligible dividends" type='text' hint='' name='box_31' value={payload?.box_31} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_24 } box="24" lable="Actual amount of dividends other than eligible dividends" type='text' hint='' name='box_24' value={payload?.box_24} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>
            
                                    <Input error={errors?.box_25} box="25" value={payload?.box_25} lable="Taxable amount of dividends other than eligible dividends" type='text' hint="" name='box_25' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_32} box="32" value={payload?.box_32} lable="Dividend tax credit for eligible dividends" type='text' hint="" name='box_32' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_26} box="26" value={payload?.box_26} lable="Dividend tax credit for dividends other than eligible dividends" type='text' hint="" name='box_26' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_34} box="34" value={payload?.box_34} lable="Capital gains or losses" type='text' hint="" name='box_34' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_35} box="35" value={payload?.box_35} lable="Other employment income" type='text' hint='' name='box_35' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_36} box="36" value={payload?.box_36} lable="Total amount forfeited due to withdrawal from plan" type='text' hint='' name='box_36' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_37} box="37" value={payload?.box_37} lable="Foreign non-business income" type='text' hint="" name='box_37' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_38} box="38" value={payload?.box_38} lable="Foreign capital gains or losses" type='text' hint='' name='box_38' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_39} box="39" value={payload?.box_39} lable="Foreign non-business income tax" type='text' hint='' name='box_39' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    <p>40 <span>Specified employee</span></p>
                                    <div className='form-group-radio flex gap-2'>
                                        <div className="flex gap-1 ml-2">
                                            <p>Yes</p>
                                            <Input error={errors?.box_40} type='radio' name='box_40'  value='yes' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                        </div>
                                        <div className="flex gap-1 ml-2">
                                            <p>No</p>
                                            <Input error={errors?.box_40} type='radio' name='box_40'  value='no' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                        </div>
                                        <RadioInput name="box_40" lable="cancel" value="cancel" handleChange={(name, value) => { handleChange(name, value)}}/>
                                        <RadioInput name="box_40" lable="Done" value="Done" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    </div>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_41} box="41" value={payload?.box_41} lable="Employees profit sharing plan contributions" type='text' hint="" name='box_41' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                            </div>

                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T4PS_AddNew;