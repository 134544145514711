import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import Dropdown from "../../../commonComponent/dropdown/Dropdown";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div className="flex " style={inputStyle}>
                    <Input type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}
const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        justifyContent: window.innerWidth < 500 ? 'flex-start' : 'flex-end',

    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div style={checkBoxStyle} className="flex">
                    <Checkbox name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}
const Form_UI_c=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        maxWidth: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div style={checkBoxStyle} className="flex gap-2">
                    <Checkbox name={props.name} value={props?.value_1} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    <Checkbox name={props.name} value={props?.value_2} hint='' lable={props.lable_2} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const Form_UI_d=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div style={inputStyle} className="flex ">
                    <Dropdown name={props.name} selected={props?.selected} options={{list:props.list, value:props.value, name:props.name}} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            
        </div>
    )
    
}

const F_EFW_AddNew=()=>{

    const province_residence = [
        {val:"AB | Alberta"},
        {val:"BC | British Columbia"},
        {val:"MB | Manitoba"},
    ]

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_EFW'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)

    // const  handleSubmit=(e)=>{
    //     e.preventDefault();
        
    //     console.log('payload is',payload)
    //     formsService.addNew({form: 'F_T4A_OAS', form_data: payload})
    //     .then( (res) => {
    //         //---alert after successful submission
    //         alert(res?.data?.message)
    //         //---redirect to details page of current submissions
    //         navigate('/user/return-YR22/forms/F_T4A_OAS/view/'+res?.data?.response?.new_id)
    //         // setEmployeeCodesList(res.data.response.list);
    //     })
    //     .catch((error) => {
    //         console.log('errors ',error?.response?.data)
    //         setErrors(error?.response?.data?.errors)
    //         setSubmitError(error?.response?.data?.message)
    //     });
    // }



    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_EFW', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_EFW/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_EFW', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_EFW/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="Electronic Filing Worksheet" subtitle="" />
                            <br />
                            <div className='form-group-div flex-column'>
                                <MaterialContainer heading="Electronic filing confirmation">
                                    {window.innerWidth <= 400 ? <> <br /></> : ""}
                                    
                                    <Form_UI_b name="EFC_001" title="Nil income confirmation" subTitle="If your income is nil, check to confirm (required, if so, for netfile purposes)" value="yes" hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="EFC_002" title="Revenue Canada Volunteer program" subTitle="Return has been completed under Revenue Canada's volunteer program" value="yes" hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="EFC_003" title="" subTitle="You are reporting RDSP Income at field 12500. Check if you had approved T2201. 'Disability form' on CRA's record for 2021 or 2020"  value="yes" hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="EFC_004" title="Transfer to instalment account" subTitle="Taxpayer requests transfer of refund to next year's instalment account" value="yes" hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="EFC_005" title="Communal organization membership" subTitle="Taxpayer is a member of a communal organization" value="yes" hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="EFC_006" title="Old Age Pension" subTitle="Check if OAS is not and has never been received" value={payload?.EFC_006} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="EFC_007" title="" subTitle="Quebec Only, if OAS not received, and a recent immigrant, enter the year of immigration (4 digit) " value={payload?.EFC_007} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_b name="EFC_008" title="T5007: Social benefits" subTitle="Check if T5007 social benefits were received while living apart" value="yes" hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a type="date" name="EFC_009" title="Information about your residence" subTitle="Enter your provience or territory of residence on December 31, 2022" value={payload?.EFC_009} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="EFC_010" title="" subTitle="If your province or territory of residence changed in 2022, enter the date of your move" value={payload?.EFC_010} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_c name="EFC_011" title="" subTitle="Is your home address the same as your mailing address?" value_1="yes" value_2="no" hint='' lable_1="Yes" lable_2="No" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_d name="EFC_012" title="" subTitle="Provience or territory of residence where you currently reside if it is not the same as that of your mailing address" options={{list: province_residence, value: 'val', name: 'val'}} selected={payload?.EFC_012} handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_c name="EFC_013" title="" subTitle="I am filing an income tax return with the CRA for the first time" value_1="yes" value_2="no" hint='' lable_1="Yes" lable_2="No" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="T778/T929 - Calculation of earned income for child care and disability support deductions">
                                    {window.innerWidth <= 400 ? <> <br/><br/></> : ""}
                                    {window.innerWidth <= 388 ? <> <br/></> : ""}
                                    {window.innerWidth <= 300 ? <> <br/></> : ""}
                                    {window.innerWidth <= 290 ? <> <br/></> : ""}
                                    <Form_UI_a name="CCDSD_001" title="" subTitle="Employment income (including tips and gratuities)" value={payload?.CCDSD_001} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_002" title="" subTitle="Non taxable portion of emergency workers allowance" value={payload?.CCDSD_002} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_003" title="" subTitle="Net self-employment income (excluding losses)" value={payload?.CCDSD_003} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_004" title="" subTitle="Taxable part of scholarships, bursaries, fellowships and similar awards" value={payload?.CCDSD_004} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_005" title="" subTitle="Disability benefits included on line 11400" value={payload?.CCDSD_005} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_006" title="" subTitle="Net research grants reported on T4A slips" value={payload?.CCDSD_006} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_007" title="" subTitle="Canada Emergency Response Benefits (Box 197)" value={payload?.CCDSD_007} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_008" title="" subTitle="Canada Emergency Response Benefits (Box 198)" value={payload?.CCDSD_008} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_009" title="" subTitle="Canada Emergency Students Benefits (CESB) for eligible students with disabilities or those with children or other dependents (Box 199)" value={payload?.CCDSD_009} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_010" title="" subTitle="Provincial/Territorial COVID-19 financial assistance payments (Box 200)" value={payload?.CCDSD_010} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_011" title="" subTitle="Canada Recovery Benefits (CRB)" value={payload?.CCDSD_011} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_012" title="" subTitle="Canada Recovery Sickness Benefits (CRSB)" value={payload?.CCDSD_012} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_013" title="" subTitle="Canada Recovery Caregiving Benefits (CRCB)" value={payload?.CCDSD_013} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_014" title="" subTitle="Canada Worker Lockdown Benefits (CWLB)" value={payload?.CCDSD_014} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_015" title="" subTitle="Employment Insurance benefits (T4E)" value={payload?.CCDSD_015} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_016" title="" subTitle="Other net research grants" value={payload?.CCDSD_016} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_017" title="" subTitle="Any earnings supplement recieved under a project sponsored by the Government of Canada" value={payload?.CCDSD_017} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_018" title="" subTitle="Disability benefits received from CPP or QPP (not included for T929 purposes)" value={payload?.CCDSD_018} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_019" title="" subTitle="Adjustment to earned income" value={payload?.CCDSD_019} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CCDSD_020" title="" subTitle="Earned Income" value={payload?.CCDSD_020} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Additional Information">
                                    {window.innerWidth <= 302 ? <> <br /></> : ""}
                                    
                                    <Form_UI_b name="AINFO_001" title="" subTitle="Check if you were in prison on December 31st and had been for 6 months or more" value={payload?.AINFO_001} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="AINFO_002" title="" subTitle="Check, if you were confirmed to prison for period of 90 days or more during the year" value={payload?.AINFO_002} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="AINFO_003" title="" subTitle="Check if you were conformed to a prison or similar institution for a period that includes December 31st of the previous taxation year and the first 179 days in the taxation year" value={payload?.AINFO_003} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_c name="AINFO_004" title="" subTitle="Are you an officer or a servant of another country, such as a diplomat, or a family member or employee of such a person and don't have to pay tax at any time in the taxation year?" value_1={payload?.AINFO_004} value_2={payload?.AINFO_004_a} hint='' lable_1="Yes" lable_2="No" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_c name="AINFO_005" title="" subTitle="Were you enrolled as a full-time student at a designed educational institution (as per the meaning assigned by federal ITA subsection 118.6(1)) for a total of more than 13 weeks and you did not have an eligible dependant in the taxation year?" value_1={payload?.AINFO_005} value_2={payload?.AINFO_005_a} hint='' lable_1="Yes" lable_2="No" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Exempt income">
                                    
                                    <Form_UI_a name="EI_001" title="" subTitle="Non taxable portion of emergency workers allowance" value={payload?.EI_001} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="EI_002" title="" subTitle="Non taxable portion of emergency workers allowance reported on other returns of deceased person" value={payload?.EI_002} hint='' lable="Yes" handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_EFW_AddNew;