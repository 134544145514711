import React, { useEffect, useState } from 'react'
import './style.css'

function SelectWithInput(props) {

  const [isFocused, setIsFocused] = useState(false);
  const [data, setData] = useState({
    name: props.value.name || '',
    value: props.value.value || '',
  })
  // console.log('SelectWithInput',props)  
  const handleKeyChange = (value) => {
    setData({
      ...data,
      name: value
    })
  }
  const handleValueChange = ( value) => {
    setData({
      ...data,
      value: value
    })

  }


  useEffect(() => {
    // console.log('data is here',data)
    // console.log(data.name, data.value, 'test 2222222222')
    props.handleChange(data)
  }, [data])


  return (
    <>
      <div className="dropdown-div" style={{ maxWidth: '300px' }}>
        <label className='dropdown-lable flex' >{props.lable}</label>
        <div className='dropdown-box-div flex'>

          <select className='dropdown' id={props.id}
            onChange={(e) => handleKeyChange( e?.target?.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={data.name}
          >

            <option className='option' value=''>-- Select --</option>
            {props.options?.list?.map((item, i) => {
              return <option
              key={item[props?.options?.value]}
                disabled={(item.disabled) ? true : false}
                className='option'
                id={i}
                value={item[props?.options?.value]}>
                {item[props?.options?.name]}
              </option>
            })}
          </select>
          <input className="input " type="text" value={data.value} onChange={(e) => handleValueChange(e?.target?.value)} />
          <span className='error-txt' style={{ color: 'red', fontSize: '0.8em' }}>{props.error}</span>
        </div>


      </div>

    </>
  )
}

export default SelectWithInput