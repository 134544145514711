import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import formsService from '../../../services/formsService'
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate,useParams } from "react-router-dom";



const F_T5007_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();


        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);
        const {action}=useParams();
        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_T5007'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])
        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            console.log('payload is',payload)
            formsService.update({form: 'F_T5007', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                alert(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T5007/view')
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
        }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group flex flex-column '>
                            <HeadingA title="T5007 " subtitle='Statement of Benefits' />
                            
                            <div className='form-div-name flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.payer_name} box="" lable="Payer's name" type='text' name='payer_name' value={payload?.payer_name} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <div className="flex">
                                        <Input error={errors?.box_10} box="10" lable="Workers' compensation benefits" type='text' name='box_10' value={payload?.box_10} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                    </div>
                                    
                                </div>
                                <div className='input-div'>

                                    <Input error={errors?.box_11} box="11" lable="Social assistance payments or provincial or territorial supplements" type='text' name='box_11' value={payload?.box_11} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>
                            
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T5007_AddNew;