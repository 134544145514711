import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import formsService from '../../../services/formsService'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";


const F_T4RSP_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {id}=useParams();

        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(id!==undefined){
                formsService.getDetails({form: 'F_T4RSP', id: id})
            .then( async(res) => {
                console.log('rrees',res)
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            if(id===undefined){
                formsService.addNew({form: 'F_T4RSP', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4RSP/view/'+res?.data?.response?.new_id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }else{
                formsService.update({form: 'F_T4RSP', form_data: payload},id)
            .then( (res) => {
                //---alert after successful submission
                Alert.success(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4RSP/view/'+id)
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
            }
            
        }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="T4RSP" subtitle='Statement of RRSP Income' />
                            
                            <div className='form-div-name flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.issuer} box="" lable="Issuer" type='text' name='issuer' value={payload?.issuer} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_16} box="16" lable="Annuity payments" type='text' name='box_16' value={payload?.box_16} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_18} box="18" lable="Refund of premiums" type='text' name='box_18' value={payload?.box_18} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                <div className='input-div'>
            
                                    <Input error={errors?.box_20} box="20" lable="Refund of unused contributions" type='text' name='box_20' value={payload?.box_20} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_22} box="22" lable="Withdrawal and commutation payments" type='text' name='box_22' value={payload?.box_22} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_25} box="25" lable="LLP withdrawal" type='text' name='box_25' value={payload?.box_25} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_26} box="26" lable="Amounts deemed received on deregistration" type='text' name='box_26' value={payload?.box_26} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_27} box="27" lable="HBP withdrawal" type='text' name='box_27' value={payload?.box_27} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_28} box="28" lable="Other income or deductions" type='text' name='box_28' value={payload?.box_28} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_30} box="30" lable="Income tax deducted" type='text' name='box_30' value={payload?.box_30} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_34} box="34" lable="Amounts deemed received on death" type='text' name='box_34' value={payload?.box_34} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_35} box="35" lable="Transfers on breakdown of marriage or common-law part" type='text' name='box_35' value={payload?.box_35} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_37} box="37" lable="Advanced Life Deferred Annuity purchase" type='text' name='box_37' value={payload?.box_37} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            
                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>
            
                                    <Input error={errors?.box_40} box="40" value={payload?.box_40} lable="Tax-paid amount" type='text' name='box_40' hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T4RSP_AddNew;