import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import formsService from '../../../services/formsService'
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate,useParams } from "react-router-dom";



const F_T5008_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();


        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);
        const {action}=useParams();
        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_T5008'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])
        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            console.log('payload is',payload)
            formsService.update({form: 'F_T5008', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                alert(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T5008/view')
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
        }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group flex flex-column '>
                            <HeadingA title="T5008" subtitle='Statement of Securities Transactions' />
                            <div className='form-div-name flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.dealer_name} box="" lable="Trader or dealer's name" type='text' name='dealer_name' value={payload?.dealer_name} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_11} box="11" lable="Recipient type" type='text' name='box_11' value={payload?.box_11} hint=''  handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_15} box="15" lable="Type code of securities" type='text' name='box_15' value={payload?.box_15} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_16} box="16" lable="Quantity of securities" type='text' name='box_16' value={payload?.box_16} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>

                                    <Input error={errors?.box_17} box="17" lable="Identification of securities" type='text' name='box_17' value={payload?.box_17} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div '>
                                    
                                    <Input error={errors?.box_18} box="18" lable="ISIN/CUSIP number" type='text' name='box_18' value={payload?.box_18} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_19} box="19" lable="Face amount" type='text' name='box_19' value={payload?.box_19} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_20} box="20" lable="Cost or book value" type='text' name='box_20' value={payload?.box_20} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_21} box="21" value={payload?.box_21} lable="Proceeds of disposition or settlement amount" type='text' name='box_21' hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_22} box="22" lable="Type code of securities received on settlement" type='text' name='box_22' value={payload?.box_22} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_23} box="23" lable="Quantity of securities received on settlement" type='text' name='box_23' value={payload?.box_23} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_24} box="24" lable="Identification of securities received on settlement" type='text' name='box_24' value={payload?.box_24} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            
                            
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T5008_AddNew;