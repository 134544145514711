import React, { useState } from 'react'
import './input.css'

function Input(props) {

  const [isFocused, setIsFocused] = useState(false);
  const [inputData, setInputData] = useState();
  
  return (
    <>
      <div className='input-div'>
        <label className='input-lable'>{props.lable}</label>
        <div className='input-container flex'>
          <span className='input-box-span flex-centered' 
            style={
              {
                background : isFocused ? 'var(--sec-color)': 'var(--border)',
                color: isFocused ? 'var(--txt-color-light)': 'var(--txt-color-dark)',
                display: !props.box ? 'none' :'flex'
              }
            }>{props.box}</span>
          
          <input 
            style={props.style}
            onFocus={() => setIsFocused(true)} 
            onBlur={()=> setIsFocused(false)} 
            className="input " 
            type={props.type}
            pattern={props.type == 'date' ? "\d{4}-\d{2}-\d{2}" : ""}
            placeholder={props.hint} 
            name={props.name} 
            value={props.value || ''}
            disabled={props.disabled} 
            onChange={(e) => props.handleChange(props.name, e?.target?.value)}/>            
        </div>

        <span className='error-txt' style={{color:'red', fontSize: '0.8em' }}>{props.error}</span>
        
      </div>
    </>
  )
}

Input.defaultProps = {
  type:"text",
  required: false,
}

export default Input