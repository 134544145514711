import { Outlet } from "react-router-dom"
import HeaderAfLog from "../../YR22/pages/components/header/HeaderAfLog"

const UserLayout=()=>{
    return <>
        <HeaderAfLog/>
        <Outlet/>
    </>
}

export default UserLayout