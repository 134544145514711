import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import formsService from '../../../services/formsService'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate,useParams } from "react-router-dom";



const F_T5013_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();


        const [payload,setPayload]=useState({})
        const dispatch=useDispatch();
        const auth=useSelector((state)=>state.auth);
        const {action}=useParams();
        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_T5013'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])
        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            console.log('payload is',payload)
            formsService.update({form: 'F_T5013', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                alert(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T5013/view')
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
        }


    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group flex flex-column '>
                            <HeadingA title="T5013 "  subtitle='Statement of Partnership Income' />
                            
                            <div className='form-div-name flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.partnership_name} lable="Partnership name" type='text' name='partnership_name' value={payload?.partnership_name} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                            </div>
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_001} box="001" lable="Partnership account number (15 characters)" type='text' name='box_001' value={payload?.box_001} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_002} box="002" lable="Partner code" type='text' name='box_002' value={payload?.box_002} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_003} box="003" lable="Country code" type='text' name='box_003' value={payload?.box_003} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                
                                    <Input error={errors?.box_004} box="004" lable="Recipient type" type='text' name='box_004' value={payload?.box_004} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_005} box="005" lable="Partner's share (%) of partnership" type='text' name='box_005' value={payload?.box_005} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div date-time-div'>
                                    
                                    <Input error={errors?.fiscal_period_end} box="" lable="Fiscal Period End" type='date' name='fiscal_period_end' value={payload?.fiscal_period_end} hint='' handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_010} box="010" lable="Total limited partner's business income (loss)" type='text' name='box_010' value={payload?.box_010} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                    
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_020} box="020" lable="Total business income (loss)" type='text' name='box_020' value={payload?.box_020} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div '>
                                    
                                    <Input error={errors?.box_030} box="030" lable="Total capital gains (losses)" type='text' name='box_030' value={payload?.box_030} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.box_040} box="040" lable="Capital cost allowance" type='text' name='box_040' value={payload?.box_040} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
                                    
                                    <Input error={errors?.shelter_num} box="TS" lable="Shelter Number" type='text' name='shelter_num' value={payload?.shelter_num} hint="" handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            
                            
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T5013_AddNew;