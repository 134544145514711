import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import { useEffect, useState } from "react";
import formsService from '../../../services/formsService'
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";



const F_T4A_RCA_AddNew=()=>{


    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();


        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);
        const {action}=useParams();
        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_T4A_RCA'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])
        console.log('paylo',payload)
        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
            
        }
    

        const  handleSubmit=(e)=>{
            e.preventDefault();
            
            console.log('payload is',payload)
            formsService.update({form: 'F_T4A_RCA', form_data: payload})
            .then( (res) => {
                //---alert after successful submission
                alert(res?.data?.message)
                //---redirect to details page of current submissions
                navigate('/user/return-YR22/forms/F_T4A_RCA/view')
                // setEmployeeCodesList(res.data.response.list);
            })
            .catch((error) => {
                console.log('errors ',error?.response?.data)
                setErrors(error?.response?.data?.errors)
                setSubmitError(error?.response?.data?.message)
            });
        }




    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <HeadingA title="T4A(RCA)" subtitle="Statement of Distributions from a Retirement Compensation Arrangement (RCA)" />
                            
                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_12} box="12" lable="Refund to employer" type='text' hint='' name="box_12" value={payload?.box_12} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_14} box="14" lable="Refund of employee contributions" type='text' hint='' name="box_14" value={payload?.box_14} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_16} box="16" lable="Distributions" type='text' hint='' name="box_16" value={payload?.box_16} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_17} box="17" lable="Distributions eligible for pension income splitting" type='text' hint='' name="box_17" value={payload?.box_17} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_18} box="18" lable="Selling price of an interest in the RCA" type='text' hint='' name="box_18" value={payload?.box_18} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_20} box="20" lable="Other amounts" type='text' hint='' name="box_20" value={payload?.box_20} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div>
                            </div>

                            <div className='form-group-div flex gap-1'>
                                <div className='input-div'>
            
                                    <Input error={errors?.box_22} box="22" lable="Income tax deducted" type='text' hint='' name="box_22" value={payload?.box_22} handleChange={(name, value) => { handleChange(name, value)}}/>
                                </div> 
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>

                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_T4A_RCA_AddNew;