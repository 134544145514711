import React, { useState } from 'react'
import './dropdown.css'

function Dropdown(props) {

  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <div className="dropdown-div"
      style={
        {
          minWidth: !props.box ? '100%' : 'auto',
      }
      }>
        <label className='dropdown-lable flex' >{props.lable}</label>
        <div className='dropdown-box-div flex'
        style={
          {
            minWidth: !props.box ? '100%' : 'auto',
        }
        }>
          <span className='dropdown-box-span flex-centered' 
          style={
            {
              background : isFocused ? 'var(--sec-color)': 'var(--border)',
              color: isFocused ? 'var(--txt-color-light)': 'var(--txt-color-dark)',
              display: !props.box && (props?.selected == "" || props?.selected == null ) ? 'none' :'flex',
              
            }
          }
          >{props.box}</span>
          
          <select className='dropdown' id={props.id}
            onChange={(e) => props.handleChange(props.name, e?.target?.value)}
            onFocus={() => setIsFocused(true)} 
            onBlur={()=> setIsFocused(false)}
            value={props.selected}
            disabled={props.disabled}
            style={
              {
                minWidth: !props.box ? '100%' : 'auto',
            }
            }
            >
              
              <option className='option' value=''>-- Select --</option>
              {props?.options?.list?.map((item,i) =>
                <option className='option' id={i} value={item[props?.options?.value]} key={i}>{item[props?.options?.name]}</option>
              )}
          </select>
          <span className='error-txt' style={{color:'red', fontSize: '0.8em' }}>{props.error}</span>
        </div>
        

      </div>
        
    </>
  )
}

export default Dropdown