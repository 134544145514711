import React, { useState } from 'react'
import './radioInput.css'

function RadioInput(props) {

  return (
    <>
        <div className='radio-btn-div flex-centered gap-2'>
            
        {props?.RadioButtons.map((item,i)=> {
            return <label className='radio-label flex gap-1' key={i}>
                
                <input type='radio' 
                    className='radio-input' 
                    name={item.name} 
                    value={item.value}
                    
                    onChange={(e) => {
                      item.handleChange(item.name, e?.target?.value)
                    }}
                />
            {item.label}</label>
          })}
        </div>
        
    </>
  )
}


export default RadioInput