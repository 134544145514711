import React from 'react';
import {
    Navigate,
    Outlet,
    BrowserRouter as Router,
    // useNavigate,
    useRoutes,
    // Navigate
} from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage'
import { Layout as LayoutYR22 } from '../YR22/pages/Layout';

import TaxPayer from '../YR22/pages/PersonalInfo/TaxPayer'
import Spouse from '../YR22/pages/PersonalInfo/Spouse';
import Status from '../YR22/pages/PersonalInfo/Status';
import Forms from '../YR22/pages/forms/Forms';
import Preview from '../YR22/pages/preview/Preview';
import { PersonalInfoLayout } from '../YR22/pages/PersonalInfo/PersonalInfoLayout';
import FormsAddNewLayout from '../YR22/pages/forms/FormsAddNewLayout';
import FormsListBlank from '../YR22/pages/forms/FormsListBlank';

import F_T4_AddNew from '../YR22/pages/forms/F_T4/AddNew.js';
import F_T4_View from '../YR22/pages/forms/F_T4/View.js';
import F_T4 from '../YR22/pages/forms/F_T4'
import F_T5 from '../YR22/pages/forms/F_T5'
import F_T3_AddNew from '../YR22/pages/forms/F_T3/AddNew.js';
import F_T3_View from '../YR22/pages/forms/F_T3/View';

import FormsOutlet from '../YR22/pages/forms/FormsOutlet';

import F_T4A_OAS_AddNew from '../YR22/pages/forms/F_T4A(OAS)/AddNew';
import F_T4A_OAS_View from '../YR22/pages/forms/F_T4A(OAS)/View';

import F_T2202_AddNew from '../YR22/pages/forms/F_T2202/AddNew';
import F_T2202_View from '../YR22/pages/forms/F_T2202/View';

import F_T4A_P_AddNew from '../YR22/pages/forms/F_T4A(P)/AddNew';
import F_T4A_P_View from '../YR22/pages/forms/F_T4A(P)/View';

import F_T4A_RCA_AddNew from '../YR22/pages/forms/F_T4A_RCA/AddNew';
import F_T4A_RCA_View from '../YR22/pages/forms/F_T4A_RCA/View';

import F_T4E_AddNew from '../YR22/pages/forms/F_T4E/AddNew';
import F_T4E_View from '../YR22/pages/forms/F_T4E/View';

import F_T4RIF_AddNew from '../YR22/pages/forms/F_T4RIF/AddNew';
import F_T4RIF_View from '../YR22/pages/forms/F_T4RIF/View';

import F_T4RSP_AddNew from '../YR22/pages/forms/F_T4RSP/AddNew';
import F_T4RSP_View from '../YR22/pages/forms/F_T4RSP/View';

import F_T4PS_AddNew from '../YR22/pages/forms/F_T4PS/AddNew';
import F_T4PS_View from '../YR22/pages/forms/F_T4PS/View';

import F_T5_AddNew from '../YR22/pages/forms/F_T5/AddNew';
import F_T5_View from '../YR22/pages/forms/F_T5/View';

import F_T5013_AddNew from '../YR22/pages/forms/F_T5013/AddNew';
import F_T5013_View from '../YR22/pages/forms/F_T5013/View';

import F_T5003_AddNew from '../YR22/pages/forms/F_T5003/AddNew';
import F_T5003_View from '../YR22/pages/forms/F_T5003/View';

import F_T5008_AddNew from '../YR22/pages/forms/F_T5008/AddNew';
import F_T5008_View from '../YR22/pages/forms/F_T5008/View';

import F_T5007_AddNew from '../YR22/pages/forms/F_T5007/AddNew';
import F_T5007_View from '../YR22/pages/forms/F_T5007/View';

import F_T4A_AddNew from '../YR22/pages/forms/F_T4A/AddNew';
import F_T4A_View from '../YR22/pages/forms/F_T4A/View';

import F_IWS_AddNew from '../YR22/pages/forms/F_IWS/AddNew';
import F_IWS_View from '../YR22/pages/forms/F_IWS/View';

import F_EFW_AddNew from '../YR22/pages/forms/F_EFW/AddNew';
import F_EFW_View from '../YR22/pages/forms/F_EFW/View';

import F_FWC_AddNew from '../YR22/pages/forms/F_FWC/AddNew';
import F_FWC_View from '../YR22/pages/forms/F_FWC/View';

import F_Fed_WS_AddNew from '../YR22/pages/forms/F_Fed_WS/AddNew';
import F_Fed_WS_View from '../YR22/pages/forms/F_Fed_WS/View';

import F_Sch_5_AddNew from '../YR22/pages/forms/F_Sch_5/AddNew';
import F_Sch_5_View from '../YR22/pages/forms/F_Sch_5/View';

import F_Sch_2_AddNew from '../YR22/pages/forms/F_Sch_2/AddNew';
import F_Sch_2_View from '../YR22/pages/forms/F_Sch_2/View';

import F_TSI_AddNew from '../YR22/pages/forms/F_TSI/AddNew';
import F_TSI_View from '../YR22/pages/forms/F_TSI/View';

import F_T5006_AddNew from '../YR22/pages/forms/F_T5006/AddNew';
import F_T5006_View from '../YR22/pages/forms/F_T5006/View';



import F_RRSP_WS_AddNew from '../YR22/pages/forms/F_RRSP_WS/AddNew';
import F_RRSP_WS_View from '../YR22/pages/forms/F_RRSP_WS/View';
import LoginLayout from '../pages/login/LoginLayout';
import Login from '../pages/login';
import SignUp from '../pages/login/SignUp.js';
import UserLayout from '../pages/User/UserLayout'
import HomeLayout from '../pages/HomeLayout'
import Home from '../pages/home'
import UserHome from '../pages/User/UserHome';
import TestingLayout from '../YR22/pages/testing/TestingLayout';
import TestFederalTax from '../YR22/pages/testing/TestFederalTax';
import Start from '../YR22/pages/start/Start';
import LayoutSteps from '../YR22/pages/LayoutSteps';
import Qna from '../YR22/pages/PersonalInfo/Qna';
import ConfirmBlank from '../YR22/pages/PersonalInfo/ConfirmBlank';
import ToCraMessage from '../YR22/pages/PersonalInfo/ToCraMessage';






const AllRoutes = () => {
    const isLoggedIn = localStorage.getItem('token')
    const routes = [
        { path:'/', element: <HomeLayout/> ,
            children:[
                { index:true, element:<Home /> },
                { path:'about', element:<Home /> },
                { path:'contact-us', element:<Home /> },
            ]
        },
        {
            path: '/login', element: <LoginLayout />,
            children: [
                { index: true, element: <Login /> },
                { path: 'sign-up', element: <SignUp /> },
            ]
        },
        {
            path: '/user',
            element: (isLoggedIn !== null) ? <UserLayout/> : <Navigate to="/login" />,
            children: [
                {index:true, element: <UserHome/>},
                {
                    
                    path: 'return-YR22', element: <LayoutYR22 />,
                    children: [
                        { path: 'start', element: <Start /> },
                        {
                            path: 'step', element: <Outlet />,
                            children: [
                                { index: true, element: <Navigate to="tax-payer" /> },
                                { path: 'tax-payer', element: <TaxPayer /> },
                                { path: 'spouse', element: <Spouse /> },
                                { path: 'status', element: <Status /> },
                                { path: 'qna', element: <Qna /> },
                                { path: 'confirm-blank', element: <ConfirmBlank /> },
                                { path: 'F_T4', element: <F_T4/> },
                                { path: 'F_T5', element: <F_T5 /> },
                                { path: 'F_T1', element: <F_T5006_AddNew /> },
                                { path: 'to-cra', element: <ToCraMessage /> }
                            ]
                        },
                        {
                            path: 'personal-info', element: <PersonalInfoLayout />,
                            children: [
                                { index: true, element: <Navigate to="tax-payer" /> },
                                { path: 'tax-payer', element: <TaxPayer /> },
                                { path: 'spouse', element: <Spouse /> },
                                { path: 'status', element: <Status /> }
                            ]
                        },
                        {
                            path: 'forms', element: <FormsOutlet />,
                            children: [
                                { index: true, element: <Forms /> },
                                { path: 'blank-list', element: <FormsListBlank /> },
                                { path: 'F_T4/add-new', element: <F_T4_AddNew /> },
                                { path: 'F_T4/edit/:id', element: <F_T4_AddNew /> },
                                { path: 'F_T4/view/:id', element: <F_T4_View /> },

                                { path: 'F_T3/add-new', element: <F_T3_AddNew /> },
                                { path: 'F_T3/edit/:id', element: <F_T3_AddNew /> },
                                { path: 'F_T3/view/:id', element: <F_T3_View /> },

                                { path: 'F_T4A_OAS/add-new', element: <F_T4A_OAS_AddNew /> },
                                { path: 'F_T4A_OAS/edit/:action', element: <F_T4A_OAS_AddNew /> },
                                { path: 'F_T4A_OAS/view', element: <F_T4A_OAS_View /> },

                                { path: 'F_T2202/add-new', element: <F_T2202_AddNew /> },
                                { path: 'F_T2202/view/:id', element: <F_T2202_View /> },

                                { path: 'F_T4A_P/add-new', element: <F_T4A_P_AddNew /> },
                                { path: 'F_T4A_P/edit/:id', element: <F_T4A_P_AddNew /> },
                                { path: 'F_T4A_P/view', element: <F_T4A_P_View /> },

                                { path: 'F_T4A_RCA/add-new', element: <F_T4A_RCA_AddNew /> },
                                { path: 'F_T4A_RCA/edit/:action', element: <F_T4A_RCA_AddNew /> },
                                { path: 'F_T4A_RCA/view', element: <F_T4A_RCA_View /> },

                                { path: 'F_T4E/add-new', element: <F_T4E_AddNew /> },
                                { path: 'F_T4E/edit/:action', element: <F_T4E_AddNew /> },
                                { path: 'F_T4E/view', element: <F_T4E_View /> },

                                { path: 'F_T4RIF/add-new', element: <F_T4RIF_AddNew /> },
                                { path: 'F_T4RIF/edit/:id', element: <F_T4RIF_AddNew /> },
                                { path: 'F_T4RIF/view/:id', element: <F_T4RIF_View /> },

                                { path: 'F_T4RSP/add-new', element: <F_T4RSP_AddNew /> },
                                { path: 'F_T4RSP/edit/:id', element: <F_T4RSP_AddNew /> },
                                { path: 'F_T4RSP/view/:id', element: <F_T4RSP_View /> },

                                { path: 'F_T4PS/add-new', element: <F_T4PS_AddNew /> },
                                { path: 'F_T4PS/edit/:id', element: <F_T4PS_AddNew /> },
                                { path: 'F_T4PS/view/:id', element: <F_T4PS_View /> },

                                { path: 'F_T5/add-new', element: <F_T5_AddNew /> },
                                { path: 'F_T5/edit/:id', element: <F_T5_AddNew /> },
                                { path: 'F_T5/view/:id', element: <F_T5_View /> },

                                { path: 'F_T5003/add-new', element: <F_T5003_AddNew /> },
                                { path: 'F_T5003/edit/:action', element: <F_T5003_AddNew /> },
                                { path: 'F_T5003/view', element: <F_T5003_View /> },

                                { path: 'F_T5007/add-new', element: <F_T5007_AddNew /> },
                                { path: 'F_T5007/edit/:action', element: <F_T5007_AddNew /> },
                                { path: 'F_T5007/view', element: <F_T5007_View /> },

                                { path: 'F_T5008/add-new', element: <F_T5008_AddNew /> },
                                { path: 'F_T5008/edit/:action', element: <F_T5008_AddNew /> },
                                { path: 'F_T5008/view', element: <F_T5008_View /> },

                                { path: 'F_T5013/add-new', element: <F_T5013_AddNew /> },
                                { path: 'F_T5013/edit/:action', element: <F_T5013_AddNew /> },
                                { path: 'F_T5013/view', element: <F_T5013_View /> },

                                { path: 'F_T4A/add-new', element: <F_T4A_AddNew /> },
                                { path: 'F_T4A/edit/:id', element: <F_T4A_AddNew /> },
                                { path: 'F_T4A/view/:id', element: <F_T4A_View /> },
                            
                                { path: 'F_IWS/add-new', element: <F_IWS_AddNew /> },
                                { path: 'F_IWS/edit/:action', element: <F_IWS_AddNew /> },
                                // { path: 'F_IWS/view', element: <F_IWS_View /> },
                                
                                { path: 'F_EFW/add-new', element: <F_EFW_AddNew /> },
                                { path: 'F_EFW/edit/:action', element: <F_EFW_AddNew /> },
                                { path: 'F_EFW/view', element: <F_EFW_View /> },

                                { path: 'F_FWC/add-new', element: <F_FWC_AddNew /> },
                                { path: 'F_FWC/edit/:id', element: <F_FWC_AddNew /> },
                                { path: 'F_FWC/view', element: <F_FWC_View /> },

                                { path: 'F_RRSP_WS/add-new', element: <F_RRSP_WS_AddNew /> },
                                { path: 'F_RRSP_WS/edit/:action', element: <F_RRSP_WS_AddNew /> },
                                { path: 'F_RRSP_WS/view', element: <F_RRSP_WS_View /> },
                                
                                { path: 'F_Fed_WS/add-new', element: <F_Fed_WS_AddNew /> },
                                { path: 'F_Fed_WS/edit/:action', element: <F_Fed_WS_AddNew /> },
                                { path: 'F_Fed_WS/view', element: <F_Fed_WS_View /> },

                                { path: 'F_Sch_5/add-new', element: <F_Sch_5_AddNew /> },
                                { path: 'F_Sch_5/edit/:action', element: <F_Sch_5_AddNew /> },
                                { path: 'F_Sch_5/view', element: <F_Sch_5_View /> },

                                { path: 'F_Sch_2/add-new', element: <F_Sch_2_AddNew /> },
                                { path: 'F_Sch_2/edit/:action', element: <F_Sch_2_AddNew /> },
                                { path: 'F_Sch_2/view', element: <F_Sch_2_View /> },

                                { path: 'F_TSI/add-new', element: <F_TSI_AddNew /> },
                                { path: 'F_TSI/edit/:action', element: <F_TSI_AddNew /> },
                                { path: 'F_TSI/view', element: <F_TSI_View /> },
                                
                                { path: 'F_T5006/add-new', element: <F_T5006_AddNew /> },
                                { path: 'F_T5006/edit/:action', element: <F_T5006_AddNew /> },
                                { path: 'F_T5006/view', element: <F_T5006_View /> },

                            ]
                        },
                        {
                            path: 'testing', element: <TestingLayout/>,
                            children: [
                                { index: true, element: <Navigate to="federal-tax" /> },
                                { path: 'federal-tax', element: <TestFederalTax /> },
                                { path: 'spouse', element: <Spouse /> },
                                { path: 'status', element: <Status /> }
                            ]
                        },
                        { path: 'preview', element: <Preview /> },
                        { path: 't1', element: <F_T5006_AddNew /> },
                    ]
                }
            ]
        },


        { path: '*', element: <ErrorPage /> }
    ]
    return useRoutes(routes);
}

const BtRouter = () => {
    return (
        // <Router basename='/dev/react'>
        <Router basename='/'>
            <AllRoutes />
        </Router>

    )
}

export default BtRouter