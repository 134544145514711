import './App.css';
import BtRouter from './router';

function App() {
  return (
    <BtRouter/>
  );
}

export default App;
