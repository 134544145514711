import "./login.css";

import './login.css'
import Logo from "../../assets/images/logo.jpg";
import { Outlet } from "react-router";
// import GlobalLoader from "../common/GlobalLoader";


const LoginLayout = () => {

    return <>
    {/* <GlobalLoader/> */}
        <div className="lgn">
            <div className="lgn-left">
                <div className="img-cover">
                    <img src={Logo} />
                </div>
            </div>
            <div className="lgn-left"><Outlet/></div>
        </div>

    </>

}
export default LoginLayout;

