import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import Dropdown from "../../../commonComponent/dropdown/Dropdown";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div className="flex " style={inputStyle}>
                    <Input type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}


const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const checkBoxStyle = {
        minWidth: '10%',
        maxWidth: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div className="">
                    <Checkbox name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const Form_UI_c=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const dropDownStyle = {
        minWidth: '20%',
        width: '20%',
        
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div style={dropDownStyle} className="flex ">
                    <Dropdown name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            <hr/>
        </div>
    )
    
}

const Form_UI_res=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'50%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div className="flex " style={inputStyle}>
                    <Input type={props.type} name={props.name} value={props?.value} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
            </div>
            <hr />
        </div>
    )

}

const Form_UI_res_c=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "" : "1",
        width: window.innerWidth < 800 ? "100%" : "auto",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        // width: '20%',
    }

    

    return (
        <div className="form-input-ui-div flex flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle ">{props.subTitle}</p>
                <div className="flex gap-1" style={divResponsive}>
                    <div style={inputStyle}>
                        <p>Federal</p>
                        <Input type={props.type} name={props.name_1} value={props?.value_1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    <div style={inputStyle}>
                        <p>Provincial</p>
                        <Input type={props.type} name={props.name_2} value={props?.value_2} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                </div>
            </div>
            <hr />
        </div>
    )

}


const Form_UI_res_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "0" : "1",
    }

    const dropDownStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'50%',
        width: '20%',
        
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                <div style={dropDownStyle} className="flex ">
                    <Dropdown name={props.name} value={props?.value} hint='' lable={props.lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                
            </div>
            <hr/>
        </div>
    )
    
}

const F_FWC_AddNew=()=>{

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})
        
        const auth=useSelector((state)=>state.auth);

        useEffect(() => {
            if(action!==undefined){
                formsService.getDetails({form: 'F_FWC'})
            .then( async(res) => {
                setPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            }
            
        }, [])

        const handleChange=(name,value)=>{
            console.log('handle change',name,value)
            setSubmitError('')
            setPayload({
                ...payload,
                [name]:value,
            })
            setErrors({
                ...errors,
                [name]:''
            })
        }
    
        console.log('payload is',payload)

    // const  handleSubmit=(e)=>{
    //     e.preventDefault();
        
    //     console.log('payload is',payload)
    //     formsService.addNew({form: 'F_T4A_OAS', form_data: payload})
    //     .then( (res) => {
    //         //---alert after successful submission
    //         alert(res?.data?.message)
    //         //---redirect to details page of current submissions
    //         navigate('/user/return-YR22/forms/F_T4A_OAS/view/'+res?.data?.response?.new_id)
    //         // setEmployeeCodesList(res.data.response.list);
    //     })
    //     .catch((error) => {
    //         console.log('errors ',error?.response?.data)
    //         setErrors(error?.response?.data?.errors)
    //         setSubmitError(error?.response?.data?.message)
    //     });
    // }



    const  handleSubmit=(e)=>{
        e.preventDefault();
        
        if(action===undefined){
            formsService.addNew({form: 'F_FWC', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_FWC/view/'+res?.data?.response?.new_id)
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }else{
            formsService.update({form: 'F_FWC', form_data: payload})
        .then( (res) => {
            //---alert after successful submission
            Alert.success(res?.data?.message)
            //---redirect to details page of current submissions
            navigate('/user/return-YR22/forms/F_FWC/view')
            // setEmployeeCodesList(res.data.response.list);
        })
        .catch((error) => {
            console.log('errors ',error?.response?.data)
            setErrors(error?.response?.data?.errors)
            setSubmitError(error?.response?.data?.message)
        });
        }
        
    }

    const idDivResponsive = {
        flexDirection : window.innerWidth < 800 ? 'column' : 'row',
    }

    return <>
        <>
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' onSubmit={handleSubmit}>
                        <div className='form-group'>
                        <HeadingA title="Family Profile" subtitle="" />
                            <br />
                            <div className='form-group-div flex-column'>
                                <MaterialContainer heading="Identification">
                                    
                                    <div style={idDivResponsive} className="flex gap-1 w-100">
                                        <Form_UI_res name="ID_001" subTitle="First Name" value={payload?.ID_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_res name="ID_002" subTitle="Last Name of dependant" value={payload?.ID_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    </div>
                                    <div style={idDivResponsive} className="flex gap-1 w-100">
                                        <Form_UI_res name="ID_003" subTitle="Social Insurance number" value={payload?.ID_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_res name="ID_004" subTitle="Date of Birth" value={payload?.ID_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    </div>
                                    <div style={idDivResponsive} className="flex gap-1 w-100">
                                        <Form_UI_res_a name="ID_005" title="" subTitle="Relationship to you" value={payload?.ID_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_res name="ID_006" subTitle="Date of death" value={payload?.ID_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    </div>
                                    <div style={idDivResponsive} className="flex gap-1 w-100">
                                        <Form_UI_b name="ID_007" subTitle="Lived with taxpayer in 2022" value="checked" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <div className="w-100"></div>
                                    </div>
                                    
                                    
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Income Information">
                                    
                                    <Form_UI_a name="IInfo_001" subTitle="UCCB benfits (RC62 Slip, if you are desginating UCCB amount to a dependant) " value={payload?.IInfo_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="IInfo_002" subTitle="Net income not including UCCB benefits" value={payload?.IInfo_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="IInfo_003" subTitle="Dependant's net income: Add lines 1 and 2 (Line 23600)" value={payload?.IInfo_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="IInfo_004" subTitle="Dependant's split income from T1206 line 4 (68360) (if applicable)" value={payload?.IInfo_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="IInfo_005" subTitle="Check this box, if your dependant's net income is nil or negative" value={payload?.IInfo_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="IInfo_006" subTitle="Dependant's taxable income (Line 26000)" value={payload?.IInfo_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_res_c name_1="IInfo_federal" name_2="IInfo_provincial" subTitle="Federal and provincial non refundable credits from T1 General Step 5 line 30 and Provincial 428 line 29 of the dependant's tax return" value_1={payload?.IInfo_federal} value_2={payload?.IInfo_provincial} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Dependant deductions">
                                    {window.innerWidth <= 400 ? <> <br /></> : ""}
                                    
                                    <Form_UI_b name="DD_001" subTitle="Check, if you are claiming eligible dependant amount (Line 30400) " value={payload?.DD_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="DD_002" subTitle="Check, is disable (CRA has valid T2201 form on record)" value={payload?.DD_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="DD_003" subTitle="Check, has mental or physical impairment but not eligible for disability amount" value={payload?.DD_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="DD_004" subTitle="Canada caregiver amount for infirm children under 18 year of age" value={payload?.DD_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Infirm/Caregiver amount">
                                    {window.innerWidth <= 400 ? <> <br /></> : ""}
                                    
                                    <Form_UI_a name="ICA_001" subTitle="Canada caregiver amount for other infirm dependants age 18 or older" value={payload?.ICA_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="ICA_002" subTitle="Federal caregiver claimed" value={payload?.ICA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="ICA_003" subTitle="Provincial infirm/caregiver claimed" value={payload?.ICA_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Disability transfer">
                                    
                                    <Form_UI_b name="DT_001" subTitle="Don't claim disability because attendant care expenses are claimed for this dependant" value={payload?.DT_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="DT_002" subTitle="Disability transfer" value={payload?.DT_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="DT_003" subTitle="Federal disability claimed" value={payload?.DT_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="DT_004" subTitle="Provincial disability claimed" value={payload?.DT_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="DT_005" subTitle="Child care expenses claimed" value={payload?.DT_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="DT_006" subTitle="Child care expenses claimed for Quebec return" value={payload?.DT_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Tuition, education, and textbook amount">
                                    {window.innerWidth <= 479 ? <> <br /></> : ""}
                                    {window.innerWidth <= 293 ? <> <br /></> : ""}
                                    
                                    <Form_UI_a name="TETA_001" subTitle="To claim tuition transfer, click here" value={payload?.TETA_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="TETA_002" subTitle="Federal tuition transfer claimed" value={payload?.TETA_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="TETA_003" subTitle="Provincial tuition transfer claimed" value={payload?.TETA_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Adoption Expenses">
                                    
                                    <Form_UI_a name="AE_001" subTitle="To claim adoption expenses, click here" value={payload?.AE_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="AE_002" subTitle="Federal adoption expenses claimed" value={payload?.AE_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="AE_003" subTitle="Provincial adoption expenses claimed" value={payload?.AE_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Fitness, Wellness credit">
                                    {window.innerWidth <= 303 ? <> <br /></> : ""}
                                    
                                    <Form_UI_a name="FWC_001" subTitle="To claim fitness expenses, click here" value={payload?.FWC_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="FWC_002" subTitle="Fitness expenses" value={payload?.FWC_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Children's arts and fitness expenses (MB and YT only)">
                                    {window.innerWidth <= 588 ? <> <br /></> : ""}
                                    {window.innerWidth <= 337 ? <> <br /></> : ""}
                                    
                                    <Form_UI_a name="CAFE_001" subTitle="To claim arts expenses, click here" value={payload?.CAFE_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="CAFE_002" subTitle="Fitness expenses" value={payload?.CAFE_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Medical expenses for dependants 18 or over">
                                    {window.innerWidth <= 507 ? <> <br /></> : ""}
                                    {window.innerWidth <= 298 ? <> <br /></> : ""}
                                    
                                    <Form_UI_a name="MED_001" subTitle="Medical expenses" value={payload?.MED_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="MED_002" subTitle="Medical expenses for dependant 18 or older" value={payload?.MED_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="MED_003" subTitle="Reduction" value={payload?.MED_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="MED_004" subTitle="Eligible medical exoenses after reduction" value={payload?.MED_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="MED_005" subTitle="Medical expenses claimed by spouse" value={payload?.MED_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="MED_006" subTitle="Medical expenses claimed by taxpayer" value={payload?.MED_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Prince Edward Island. Nunavut & Nova Scotia Residents Only">
                                    {window.innerWidth <= 656 ? <> <br /></> : ""}
                                    {window.innerWidth <= 367 ? <> <br /></> : ""}
                                    {window.innerWidth <= 291 ? <> <br /></> : ""}
                                    
                                    <Form_UI_b name="PEI_001" title="Amount for Young Children" subTitle="Check if some one received a special allowance for this dependant under the Children's Special Allowances Act" value={payload?.PEI_001} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_b name="PEI_002" subTitle="Check if this dependant is claimed as an eligible dependant by you or anyone else as an eligible dependant" value={payload?.PEI_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="PEI_003" subTitle="Maximum number of months child was under 6 and lived with you" value={payload?.PEI_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="PEI_004" subTitle="Claimed" value={payload?.PEI_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                </MaterialContainer>
                                <br />
                                <br />
                            </div>
                            
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    </>
}

export default F_FWC_AddNew;