import React, { useState } from 'react'
import './dropdown.css'

function MaterialDropdown(props) {
    const [isFocused, setIsFocused] = useState(false);
    

    return (
        <>
            <div className="material-dropdown-div">
            <label className='material-dropdown-lable flex' style={{
                display: isFocused || (props.value !== null || props.value !== "0" ) ? 'flex': 'none',
                color: isFocused ? 'var(--sec-color)':'grey'

            }}>{props.lable}</label>
            {props.visible && (
                <label className='material-option-lable flex' style={{
                    display: isFocused ? 'none': 'none'
                }}>{props.lable}</label>
            )}

            <div className='material-dropdown-box-div flex'>
                
                <select className='material-dropdown'
                onChange={(e) => props.handleChange(props.name, e?.target?.value)}
                onFocus={() => setIsFocused(true)} 
                onBlur={()=> setIsFocused(false)}
                value={props.value}
                disabled={props.disabled}
            >
                    
                    <option className='option hint' value='0'>--Select here--</option>
                    {props?.options?.list?.map((item,i) =>
                        <option className='option' id={i} value={item[props?.options?.value]} key={i} >{item[props?.options?.name]}</option>
                        
                    )}
                    
                </select>
                <span className='error-txt' style={{color:'red', fontSize: '0.8em' }}>{props.error}</span>
            </div>
            
    
            </div>
            
        </>
    )
}

export default MaterialDropdown