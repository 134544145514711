import { deleteRequest, getRequest, postRequest, putRequest } from '../../services/axios/';

/**
 * Api call
 */
class formsService {

    addNew = async (payload) => {
        return await postRequest('user/return-YR22/form', payload);
    }

    update = async (payload,id=null) => {
        return await putRequest('user/return-YR22/form?id='+id, payload);
    }

    getDetails = async (urlParam = {}, payload) => {
        return await getRequest('user/return-YR22/form', urlParam);
    }

    getAdditionalFields = async (urlParam = {}, payload) => {
        return await getRequest('user/return-YR22/form/fileds?type=other-information', urlParam);
    }

    deleteForm = async (urlParam = {}, payload) => {
        return await deleteRequest('user/return-YR22/form', urlParam);
    }

    getProvinces = async (urlParam = {}, payload) => {
        return await getRequest('user/return-YR22/masters/provinces', urlParam);
    }

    getEmployeeCodes = async (urlParam = {}, payload) => {
        return await getRequest('user/return-YR22/masters/employment-codes', urlParam);
    }
    getFilledForms = async (urlParam = {}, payload) => {
        return await getRequest('user/return-YR22/filled-forms', urlParam);
    }

    
    getPreview = async (urlParam = {}, payload) => {
        return await getRequest('user/return-YR22/form/preview', urlParam);
    }

}

const instance = new formsService();

export default instance;