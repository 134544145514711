import { NavLink, Outlet } from "react-router-dom";
import HeaderAfLog from "./components/header/HeaderAfLog";
import './layout.css'



export function Layout(){

    const winSize = window.innerWidth;
    // console.log("Size of Window : ",winSize);

    return (
        <>
        <br/>
        <div className="layout-container flex flex-column gap-1">
            <div className="body flex-centered flex-column w-100"> 
                <ul className="flex-centered w-100">
                    <li><NavLink to='/user/return-YR22/personal-info'>{ winSize >= 600 ? "Personal Information" : "Personal Info"}</NavLink></li>
                    <li><NavLink to='forms'>Forms</NavLink></li>
                    <li><NavLink to='preview'>Preview</NavLink></li>
                    <li><NavLink to='testing'>Testing</NavLink></li>
                    <li><NavLink to='T1'>T1 General</NavLink></li>
                </ul>
                <div className="inner-layout w-100 flex-centered">
                    <Outlet/>
                </div>
                
            </div>
        </div>
        
        
        </>
    )
}
