import axios from 'axios';

const axiosClient = axios.create();
const access_token=localStorage.getItem('token')


// axiosClient.defaults.baseURL = process.env.REACT_APP_API;
axiosClient.defaults.baseURL = 'https://ezytaxapi.bawatech.com/';
// axiosClient.defaults.baseURL = 'http://localhost:8080/ezytax-api/';

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin':' *',
  'Authorization': `Bearer ${access_token}`
};

//All request will wait 2 seconds before timeout
axiosClient.defaults.timeout = 10000;

axiosClient.defaults.withCredentials = false;


export async function getRequest(URL,urlParam) {
  return axiosClient.get(`/${URL}`,
  {
    params:urlParam
  }
  )
}

export async function  postRequest(URL, payload) {
  return await axiosClient.post(`/${URL}`, payload).then(response => response);
}

export async function putRequest(URL, payload) {
  return axiosClient.put(`/${URL}`, payload).then(response => response);
}

export async function deleteRequest(URL,urlParam) {
  return axiosClient.delete(`/${URL}`,{data:urlParam}).then(response => response);
}